import {
  HubtypeProvider,
  HubtypeProviderAccount,
} from '@hubtype/data-access-models'
import { Button, Tooltip } from '@hubtype/ui-react-web'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { useBotConfig } from '../../../custom-hooks/use-bot-config'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { PreviewContainer } from './preview-styles'
import {
  arraySortAlphabetically,
  generateUrlsForWebchatIntegration,
  openURLInNewWindowWithDimensions,
  WEBCHAT_PREVIEW_FEATURES,
} from './utils'

export const PreviewButton = (): JSX.Element => {
  const {
    organizationContents: { currentBot },
    currentLocale,
    locales,
  } = useFlowBuilderSelector(ctx => ctx.state)

  const analytics = useAnalytics()
  const { isPreviewEnabled } = useBotConfig()

  const openWebchatPreview = (
    selectedProvider: HubtypeProviderAccount
  ): void => {
    const { previewUrl } = generateUrlsForWebchatIntegration(
      currentBot,
      selectedProvider,
      currentLocale.code,
      locales
    )
    openURLInNewWindowWithDimensions(previewUrl, WEBCHAT_PREVIEW_FEATURES)
    analytics.trackEvent(TrackEventName.PreviewOpen, {
      integration_type: selectedProvider.provider,
    })
  }

  const openPreview = () => {
    const integrationsData = arraySortAlphabetically(
      currentBot.providerAccounts || []
    ).filter(item => item.isTest)

    const webchatIntegration = integrationsData.find(
      integration => integration.provider === HubtypeProvider.Webchat
    )
    if (webchatIntegration) {
      openWebchatPreview(webchatIntegration)
    }
  }

  return (
    <PreviewContainer>
      <Tooltip
        content='Update bot code to the latest version to activate'
        isDisabled={isPreviewEnabled}
      >
        <Button
          intent='secondary'
          size='small'
          appearance='outline'
          onPress={openPreview}
          isDisabled={!isPreviewEnabled}
        >
          Preview
        </Button>
      </Tooltip>
    </PreviewContainer>
  )
}
