import { Banner, Button, Slider, Switch } from '@hubtype/ui-react-web'
import { memo } from 'react'

import {
  KNOWLEDGE_INSTRUCTIONS,
  KNOWLEDGE_MEMORY,
  KNOWLEDGE_MEMORY_LENGTH,
} from '../../../UI/components/node-editor-panel/constants'
import { ContentTextField } from '../../../UI/components/node-editor-panel/editor-widgets/text-input-widgets'
import { EditorProps } from '../../../UI/components/node-editor-panel/node-editor-panel'
import { useBotConfig } from '../../../UI/custom-hooks/use-bot-config'
import { useFlowBuilderSelector } from '../../../UI/reducer/hooks'
import { KnowledgeBaseFields } from '../model'

export interface TabSettingsProps extends EditorProps {
  nodeContent: KnowledgeBaseFields
}

export const TabSettings = memo(
  ({ nodeContent, ...props }: TabSettingsProps): JSX.Element => {
    const { isKnowledgeBaseSettingsEnabled } = useBotConfig()
    const { knowledgeBaseDefaultInstructions } = useFlowBuilderSelector(
      ctx => ctx.state.organizationContents
    )
    return (
      <>
        {!isKnowledgeBaseSettingsEnabled && (
          <Banner
            intent='warning'
            title='Bot code is out of date'
            description="To edit the settings of this Knowledge Base, please update the bot's code."
          />
        )}
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={KNOWLEDGE_INSTRUCTIONS}
          value={nodeContent.instructions}
          isOptional={true}
          multiline={true}
          isReadOnly={props.isReadOnly || !isKnowledgeBaseSettingsEnabled}
          footerRightSlot={
            <Button
              intent='secondary'
              appearance='outline'
              size='small'
              isDisabled={
                props.isReadOnly ||
                nodeContent.instructions === knowledgeBaseDefaultInstructions
              }
              onPress={() => {
                props.onChange({
                  type: KNOWLEDGE_INSTRUCTIONS.actionType,
                  fieldKey: KNOWLEDGE_INSTRUCTIONS.key,
                  value: knowledgeBaseDefaultInstructions,
                })
              }}
            >
              Restore to default
            </Button>
          }
        />
        <Switch
          {...props}
          isSelected={nodeContent.hasMemory}
          isReadOnly={props.isReadOnly || !isKnowledgeBaseSettingsEnabled}
          description={KNOWLEDGE_MEMORY.helperText}
          onChange={value => {
            props.onChange({
              type: KNOWLEDGE_MEMORY.actionType,
              fieldKey: KNOWLEDGE_MEMORY.key,
              value: value,
            })
          }}
        >
          {KNOWLEDGE_MEMORY.label}
        </Switch>
        {nodeContent.hasMemory && (
          <Slider
            {...props}
            defaultValue={nodeContent.memoryLength}
            label={KNOWLEDGE_MEMORY_LENGTH.label}
            description={KNOWLEDGE_MEMORY_LENGTH.helperText}
            isDisabled={props.isReadOnly || !isKnowledgeBaseSettingsEnabled}
            onChange={value => {
              props.onChange({
                type: KNOWLEDGE_MEMORY_LENGTH.actionType,
                fieldKey: KNOWLEDGE_MEMORY_LENGTH.key,
                value: value,
              })
            }}
            minValue={2}
            maxValue={20}
          />
        )}
      </>
    )
  }
)
