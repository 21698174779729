import { NodeTypes, NonMessageContents, State } from '../types'
import { Action, ActionType } from './action-types'
import { ConnectNodesAction } from './actions/edge-actions'
import {
  AddFlowAction,
  RemoveFlowAction,
  SelectFlowAction,
  SetFlowsAction,
} from './actions/flow-actions'
import {
  CopyAction,
  CutAction,
  DropNodeAction,
  NodeDragStartAction,
  PasteAction,
  RemoveNodesAction,
  SelectNodeAction,
  SetSelectedNodesAction,
  UpdateAllNodesAction,
  UpdateNodeAction,
  UpdateNodesPositionAction,
} from './actions/node-actions'
import { NodeAction } from './actions/node-actions/node-action'
import { RedoAction } from './actions/redo'
import { UndoAction } from './actions/undo'

// eslint-disable-next-line complexity
export function nodeReducer(draftState: State, action: Action): void {
  switch (action.type) {
    case ActionType.ConnectNodes:
      ConnectNodesAction.apply(draftState, action.connection)
      break

    case ActionType.NodeDragStart:
      NodeDragStartAction.apply(draftState, action)
      break

    case ActionType.NodeDragStop:
      UpdateNodesPositionAction.apply(draftState, action.nodesToReposition)
      break

    case ActionType.NodeDrop:
      DropNodeAction.apply(draftState, action.event)
      break

    case ActionType.RemoveNodes: {
      RemoveNodesAction.apply(draftState, action.nodesToRemove)
      break
    }

    case ActionType.SelectFlow: {
      SelectFlowAction.apply(draftState, action)
      break
    }

    case ActionType.SetFlows: {
      SetFlowsAction.apply(draftState, action)
      break
    }

    case ActionType.AddFlow:
      AddFlowAction.apply(draftState, action)
      break

    case ActionType.RemoveFlow: {
      RemoveFlowAction.apply(draftState, action)
      break
    }

    case ActionType.SelectNode:
      SelectNodeAction.apply(draftState, action)
      break

    case ActionType.SetModalContent:
      draftState.modalContent = action.content
      break

    case ActionType.SetSelectedNodes:
      SetSelectedNodesAction.apply(draftState, action)
      break

    case ActionType.UpdateAllContents:
      draftState = updateAllContents(
        draftState,
        action.nodes,
        action.nonMessageContents
      )
      break

    case ActionType.UpdateNode:
      UpdateNodeAction.apply(draftState, action.data)
      break

    case ActionType.CopyElements:
      CopyAction.apply(draftState, action)
      break

    case ActionType.CutElements:
      CutAction.apply(draftState, action)
      break

    case ActionType.PasteElements:
      PasteAction.apply(draftState, action)
      break

    case ActionType.Undo:
      UndoAction.apply(draftState)
      break

    case ActionType.Redo:
      RedoAction.apply(draftState)
      break

    case ActionType.UpdateAllNodes:
      UpdateAllNodesAction.apply(draftState, action)
      break
  }
}

const updateAllContents = (
  state: State,
  nodes?: NodeTypes[],
  nonMessageContents?: NonMessageContents
): State => {
  if (nodes) {
    state.nodes = nodes
    state.currentNode = nodes?.find(n => n.id === state.currentNode?.id)
    NodeAction.reportDuplicatedIdentifier(state)
  }
  if (nonMessageContents) state.nonMessageContents = nonMessageContents
  return state
}
