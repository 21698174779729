import { createContext, useContext } from 'react'

// TODO: move to utils-hooks library
export function createContextAndHook<T>() {
  const Context = createContext<T | null>(null)

  const useContextHook = () => {
    const result = useContext(Context)
    if (!result) {
      throw new Error('Context used outside of its Provider')
    }
    return result
  }

  return [Context, useContextHook] as const
}
