import { JsonObject, JsonProperty } from 'json2typescript'

import { HubtypeFlowBuilderSettings } from './flow-builder-settings'
import { HubtypeProviderAccount } from './provider-account'

@JsonObject('HubtypeBot')
export class HubtypeBot {
  @JsonProperty('id', String)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('provider_accounts', [HubtypeProviderAccount], true)
  public providerAccounts: HubtypeProviderAccount[] = []

  @JsonProperty('flow_builder_settings', HubtypeFlowBuilderSettings, true)
  public flowBuilderSettings?: HubtypeFlowBuilderSettings = undefined

  @JsonProperty('is_test', Boolean, true)
  public isTest = false
}
