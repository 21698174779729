import { memo } from 'react'

import { FallbackFields } from '../../../../../domain/models/content-fields'
import { TextBodyBold } from '../../../base'
import { HtInfoPanel } from '../../../base/ht-info-panel/ht-info-panel'
import { EditorProps } from '../../node-editor-panel'
import { UniqueIdentifierField } from '../../unique-identifier/unique-identifier-widget'

interface FallbackEditorProps extends EditorProps {
  nodeContent: FallbackFields
}

export const FallbackEditor = memo(
  ({ nodeContent, ...props }: FallbackEditorProps): JSX.Element => {
    return (
      <>
        <TextBodyBold>Fallback messages</TextBodyBold>
        <UniqueIdentifierField
          {...props}
          {...nodeContent}
          value={nodeContent.code}
        />
        <HtInfoPanel
          title='About fallback messages'
          text={
            'Fallback messages will be sent every time the conversational app can’t understand what the user is texting. At least one fallback message is required, but you can also define a second fallback message.\n\nIf there are two fallback messages defined they will be sent alternately each time the conversational app can’t understand something.'
          }
          recommendedUseText={
            'Redirect users in the first fallback message by asking them to choose an option or rephrase the question; and transfer them to an agent in the second fallback message to avoid further frustration.'
          }
        ></HtInfoPanel>
      </>
    )
  }
)
