import styled from 'styled-components'

import { COLORS } from '../base'

export const Text = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const StyledNote = styled.div<{ $positionRight?: string }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -150px;
  right: ${props => props.$positionRight || '300px'};
  width: 280px;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  background: ${COLORS.WHITE};
  white-space: pre-wrap;
  cursor: default;

  > :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    svg {
      color: ${COLORS.N900};
    }

    button {
      margin-left: auto;
    }
  }

  > :last-child {
    margin-top: 8px;
  }
`
