import { useToastFeedback } from '@hubtype/ui-react-web'
import { useEffect } from 'react'

import { Locale } from '../../domain/models/locales/locale'
import { KnowledgeBaseFields } from '../../nodes/knowledge-base'
import { HubtypeService } from '../../repository/hubtype/hubtype-service'
import { useRepository } from '../../repository/repository-utils'
import { setFBAppStoragePreviewState } from '../../test-webchat-app/utils'
import { useAnalytics } from '../analytics'
import { usePublish } from '../components/header/publish/use-publish'
import { DRAFT_VERSION, LATEST_VERSION } from '../constants'
import { useFlowBuilderSelector } from '../reducer/hooks'
import { LoadingMessage, OrganizationContents } from '../types'
import { stringArrayToLocaleArray } from '../utils/locales'

const RELOAD_BOT_CONFIG_INTERVAL = import.meta.env
  .VITE_FLOW_BUILDER_SELF_SERVE_DEPLOYMENT_ETA_IN_MS

export const useLoadFlow = () => {
  const analytics = useAnalytics()
  const feedback = useToastFeedback()
  const repository = useRepository()
  const { getVersionHistoryData } = usePublish()
  const {
    state,
    selectLocale,
    setBotConfig,
    setBotVariables,
    setFlows,
    setHash,
    setHashPublished,
    setKnowledgeBaseActive,
    setLoadingMessage,
    setLocales,
    setOrganizationContents,
    toggleFlowSaved,
    updateAllContents,
    setModalContent,
  } = useFlowBuilderSelector(ctx => ctx)

  const loadBotConfig = async (botId: string): Promise<void> => {
    if (!state.hubtypeUser) return
    const botConfig = await HubtypeService.getBotConfig(state.authToken, botId)
    if (botConfig) {
      setBotConfig(botConfig)
    }
  }

  const loadInitialFlow = async (botId: string): Promise<void> => {
    if (!state.hubtypeUser) return
    setModalContent()
    setLoadingMessage(LoadingMessage.LoadingContent)
    await getVersionHistoryData(botId)
    analytics.identify(state.hubtypeUser) // TODO: Investigate why app is not working with await here
    const orgContents = await HubtypeService.getOrganizationContents(
      state.authToken,
      botId
    )
    setOrganizationContents(orgContents)

    await Promise.all([
      loadFlow(undefined, DRAFT_VERSION, orgContents),
      loadPublishedHash(orgContents),
    ])

    loadBotConfig(botId)
    toggleFlowSaved(true)
  }

  useEffect(() => {
    // Temporary solution to reload bot config after self-serve deployment has finished
    setTimeout(loadBotConfig, RELOAD_BOT_CONFIG_INTERVAL)
  }, [])

  const loadFlow = async (
    locale: Locale | undefined,
    version: string,
    organizationContents: OrganizationContents
  ): Promise<void> => {
    const newFlowContent = await repository.cmsReader.readFlowContent(
      state.authToken,
      locale,
      organizationContents,
      version
    )
    if (!newFlowContent) {
      feedback.addError('Failed to load version')
      setLoadingMessage(undefined)
      return
    }
    if (newFlowContent.locales.length) {
      const locales = stringArrayToLocaleArray(newFlowContent.locales)
      setLocales(locales)
      if (!locale) selectLocale(locales[0], false)
      if (version === DRAFT_VERSION) {
        const botId = organizationContents.currentBot.id
        setFBAppStoragePreviewState(botId, {
          hasFlowErrors: false,
          current: locales[0],
          locales,
        })
      }
    }
    setKnowledgeBaseActive(newFlowContent.isKnowledgeBaseActive, false)
    setFlows(newFlowContent.flows)
    setBotVariables(newFlowContent.botVariables)
    setLoadingMessage(undefined)
    setHash(newFlowContent.hash)
    if (version === DRAFT_VERSION) {
      KnowledgeBaseFields.trackInvalidSources(newFlowContent.nodes, analytics)
    }
    updateAllContents(newFlowContent.nodes, newFlowContent.nonMessageContents)
  }

  const loadPublishedHash = async (
    orgContents: OrganizationContents
  ): Promise<void> => {
    const publishedFlow = await repository.cmsReader.readFlowContent(
      state.authToken,
      undefined,
      orgContents,
      LATEST_VERSION
    )

    setHashPublished(publishedFlow?.hash || '')
    return
  }

  return { loadInitialFlow, loadFlow }
}
