import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { ChannelNodeProps } from '../../types'

const ChannelNode = ({ data, selected, id }: ChannelNodeProps) => {
  return (
    <Node type='condition' isSelected={selected} nodeContent={data}>
      {({ NodeCondition, NodeGroup }) => (
        <NodeGroup>
          {data.channels.map((channel, i) => (
            <NodeCondition
              key={i}
              id={`${id}-${channel.id}`}
              icon={channel.icon}
            >
              {channel.name}
            </NodeCondition>
          ))}
          <NodeCondition id={`${id}-default`} isConnectionRequired={true}>
            Any other channels
          </NodeCondition>
        </NodeGroup>
      )}
    </Node>
  )
}

export default memo(ChannelNode)
