import { Button, Icon, PopoverTrigger } from '@hubtype/ui-react-web'

import { useFlowBuilderSelector } from '../../../reducer/hooks'
import styles from './alert-center.module.css'
import { AiContentIdAlert } from './alerts/ai-content-id-alert'
import { LocaleAlert } from './alerts/locale-alert'
import { NodeAlert } from './alerts/node-alert'
import { FlowErrors } from './use-alert-center'

interface AlertCenterProps {
  errors?: FlowErrors
}

export const AlertCenter = ({ errors }: AlertCenterProps): React.ReactNode => {
  const { state } = useFlowBuilderSelector(ctx => ctx)

  if (!errors?.nodeErrors.length || state.isReadOnly) return null

  return (
    <PopoverTrigger
      trigger={
        <Button
          intent='secondary'
          size='small'
          appearance='outline'
          className={styles.alertButton}
        >
          <Icon icon='exclamation-triangle' prefix='fad' /> (
          {errors?.nodeErrors.length})
        </Button>
      }
      placement='bottom right'
      showArrow={true}
      offset={26}
      className={styles.alertCenter}
      shouldCloseOnInteractOutside={(element: Element): boolean =>
        Boolean(element.closest('[role="banner"]')) ||
        element.className === 'react-flow__pane'
      }
    >
      {errors ? (
        <ul>
          {errors.isContentIdMissing && <AiContentIdAlert />}
          {errors.nodeErrors.map(nodeWithErrors => (
            <NodeAlert
              key={nodeWithErrors.id}
              nodeWithErrors={nodeWithErrors}
            />
          ))}
          {errors.localeErrors.map(localeError => (
            <LocaleAlert key={localeError.locale.code} error={localeError} />
          ))}
        </ul>
      ) : (
        <div className={styles.noErrors}>No errors</div>
      )}
    </PopoverTrigger>
  )
}
