import { ActionType } from '../../action-types'
import {
  SetKnowledgeBaseActiveAction,
  SetKnowledgeBaseActiveHistoryChange,
  SetKnowledgeBaseActiveInterface,
} from './set-knowledge-base-active'

export { SetKnowledgeBaseActiveAction }

export type AiActionInterface = SetKnowledgeBaseActiveInterface

export type AiChangesHistory = SetKnowledgeBaseActiveHistoryChange

export const aiActionMap = {
  [ActionType.SetKnowledgeBaseActive]: SetKnowledgeBaseActiveAction,
}
