import { memo } from 'react'

import { HtInfoPanel, TextBodyBold } from '../../UI/components/base'
import {
  SMART_INTENT_DESCRIPTION,
  SMART_INTENT_TITLE,
} from '../../UI/components/node-editor-panel/constants'
import { ContentTextField } from '../../UI/components/node-editor-panel/editor-widgets/text-input-widgets'
import { EditorProps } from '../../UI/components/node-editor-panel/node-editor-panel'
import { UniqueIdentifierField } from '../../UI/components/node-editor-panel/unique-identifier/unique-identifier-widget'
import { SmartIntentFields } from './model'

export interface SmartIntentEditorProps extends EditorProps {
  nodeContent: SmartIntentFields
}

export const SmartIntentEditor = memo(
  ({ nodeContent, ...props }: SmartIntentEditorProps): JSX.Element => {
    return (
      <>
        <TextBodyBold>Smart intent</TextBodyBold>
        <UniqueIdentifierField
          {...props}
          {...nodeContent}
          field={SMART_INTENT_TITLE}
          value={nodeContent.title}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={SMART_INTENT_DESCRIPTION}
          value={nodeContent.description}
          multiline={true}
        />
        <HtInfoPanel
          title='About smart intent'
          text={
            <>
              <div>
                Please provide a concise description of the user's intention
                that you wish to detect, and the AI will automatically recognize
                it whenever the user types any related content.
              </div>
              <div>
                Description examples:
                <ul>
                  <li>User wants to check the status of their order.</li>
                  <li>Customer inquiries about the status of their order.</li>
                  <li>User wishes to learn about store locations.</li>
                </ul>
              </div>
            </>
          }
        ></HtInfoPanel>
      </>
    )
  }
)
