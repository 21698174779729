import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('HubtypeQueue')
export class HubtypeQueue {
  @JsonProperty('id', String)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('project_name', String, true)
  public projectName = ''

  @JsonProperty('project_id', String, true)
  public projectId = ''
}
