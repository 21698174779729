import { useMachine } from '@xstate/react'
import { ConnectionStateChange, PresenceMessage, RealtimeChannel } from 'ably'
import { fromPromise } from 'xstate'

import { useSave } from '../components/header/save/use-save'
import { ModalType } from '../components/modal/modal-types'
import { useLoadFlow } from '../custom-hooks/use-load-flow'
import {
  PRESENCE_FLOW_BUILDER_CHANNEL_NAME,
  useRealtimeContext,
} from '../realtime'
import { useRealtimeChannel } from '../realtime/use-realtime-channel'
import { useFlowBuilderSelector } from '../reducer/hooks'
import { InteractionMode, LoadingMessage } from '../types'
import { oneUserMachine } from './one-user-machine'

export function useOneUser(botId: string) {
  const realtimeClient = useRealtimeContext()
  const { state } = useFlowBuilderSelector(ctx => ctx)
  const { setModalContent, toggleIsOffline, setLoadingMessage } =
    useFlowBuilderSelector(ctx => ctx)
  const { loadInitialFlow } = useLoadFlow()
  const { saveFlow } = useSave()

  const channelName = `${PRESENCE_FLOW_BUILDER_CHANNEL_NAME}-${state.hubtypeUser?.organizationId}-${botId}`

  const [machineState, send] = useMachine(
    oneUserMachine.provide({
      actors: {
        initializeAbly: fromPromise(async () => await initializeAbly()),
        enterChannel: fromPromise(async () => await enterChannel()),
        openLobbyModal: fromPromise(async () => await openLobbyModal()),
        endMySession: fromPromise(async () => await endMySession()),
        expireSession: fromPromise(async () => await expireSession()),
        loadFlow: fromPromise(async () => await loadInitialFlow(botId)),
        saveFlowBeforeExit: fromPromise(async () => await saveFlowBeforeExit()),
      },
    }),
    {
      input: {
        isViewOnly: state.interactionMode === InteractionMode.View,
      },
    }
  )

  const getMembers = async (realtimeChannel?: RealtimeChannel) => {
    if (!realtimeChannel) return []
    return await realtimeChannel.presence.get()
  }

  const initializeAbly = async () => {
    const realtimeChannel = realtimeClient.channels.get(channelName)
    realtimeChannel.attach()

    if (realtimeClient.connection.id) {
      onConnect(realtimeChannel)
    }

    realtimeClient.connection.on(({ current }: ConnectionStateChange) => {
      toggleIsOffline(current !== 'connected')
      send({ type: 'CONNECTION_STATE_CHANGE', connectionState: current })
    })

    realtimeClient.connection.on('disconnected', async () => {
      send({ type: 'CONNECTION_LOST' })
    })

    realtimeClient.connection.on('connected', async () => {
      onConnect(realtimeChannel)
    })

    return realtimeChannel
  }

  const onConnect = async (realtimeChannel: RealtimeChannel) => {
    toggleIsOffline(false)
    const myConnectionId = realtimeClient.connection.id
    const members = await getMembers(realtimeChannel)
    send({ type: 'ON_CONNECT', members, myConnectionId })
  }

  const enterChannel = async () => {
    await machineState.context.realtimeChannel?.presence.enter({
      userName: state.hubtypeUser?.getUserName(),
    })
  }

  const onUserEnter = async (member: PresenceMessage): Promise<void> => {
    const members = await getMembers(machineState.context.realtimeChannel)
    if (machineState.context.myConnectionId === member.connectionId) {
      send({ type: 'HANDLE_ENTER', members })
    } else {
      send({ type: 'HANDLE_USER_ENTER', members })
    }
  }

  const onUserLeave = async (member: PresenceMessage): Promise<void> => {
    const members = await getMembers(machineState.context.realtimeChannel)
    send({ type: 'HANDLE_USER_LEAVE', members })
  }

  const openLobbyModal = async () => {
    if (!machineState.context.realtimeChannel) return
    const activeUsers = await getMembers(machineState.context.realtimeChannel)
    setModalContent({
      type: ModalType.SessionConflict,
      activeUser: activeUsers[0],
      onConfirm: () => send({ type: 'END_USER_SESSION' }),
      onDiscard: () => send({ type: 'CANCEL' }),
    })
  }

  const endMySession = async () => {
    const activeUsers = await getMembers(machineState.context.realtimeChannel)
    setModalContent({
      type: ModalType.SessionEnded,
      activeUser: activeUsers?.[activeUsers.length - 1],
    })
    await saveFlow()
    machineState.context.realtimeChannel?.detach()
    realtimeClient?.close()
  }

  const saveFlowBeforeExit = async () => {
    setLoadingMessage(LoadingMessage.Save)
    await saveFlow()
    setLoadingMessage(undefined)
  }

  const expireSession = async () => {
    setModalContent({ type: ModalType.ConnectionLost })
    machineState.context.realtimeChannel?.detach()
    realtimeClient?.close()
  }

  useRealtimeChannel(machineState.context, onUserEnter, onUserLeave)

  return { isFlowBuilderVisible: machineState.hasTag('visible') }
}
