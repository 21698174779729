import { Button, Icon } from '@hubtype/ui-react-web'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { ComputedPreviousFlow, Flow } from '../../../types'
import { TextBodySmall, TextBodySmallBold } from '../../base'
import {
  StyledGoToFlowItemContainer,
  StyledGoToPreviousFlowsHeader,
  StyledPreviousFlowsContainer,
} from './start-node-styles'

interface StartNodeGoToFlowsDropdownProps {
  setShowPreviousFlows: (show: boolean) => void
  flowWithComputedPreviousFlows: ComputedPreviousFlow
  onFlowSelected: (flow: Flow) => void
}
export const StartNodeGoToFlowsDropdown = ({
  flowWithComputedPreviousFlows,
  setShowPreviousFlows,
  onFlowSelected,
}: StartNodeGoToFlowsDropdownProps) => {
  const analytics = useAnalytics()

  const { previousFlows, showPreviousFlows } = flowWithComputedPreviousFlows

  const onPreviousFlowsOpened = () => {
    const fbPreviousFlowstrackEventArgs = {
      action: showPreviousFlows ? 'close' : 'open',
      number_of_flows_listed: previousFlows.length,
    }
    analytics.trackEvent(
      TrackEventName.FbPreviousFlows,
      fbPreviousFlowstrackEventArgs
    )
    setShowPreviousFlows(!showPreviousFlows)
  }

  if (previousFlows.length === 0) return null

  return (
    <StyledPreviousFlowsContainer>
      <StyledGoToPreviousFlowsHeader
        onClick={evt => {
          evt.stopPropagation()
          onPreviousFlowsOpened()
        }}
      >
        <Icon
          icon={showPreviousFlows ? 'caret-up' : 'caret-down'}
          size='tiny'
        />
        <TextBodySmallBold>
          Go to previous flows ({previousFlows.length})
        </TextBodySmallBold>
      </StyledGoToPreviousFlowsHeader>

      {showPreviousFlows &&
        previousFlows.map((flow, index) => {
          const isLast = index === previousFlows.length - 1
          return (
            <StyledGoToFlowItemContainer key={index} $isLast={isLast}>
              <TextBodySmall>{flow.name}</TextBodySmall>
              <Button
                size='small'
                appearance='outline'
                onPress={() => {
                  onFlowSelected(flow)
                }}
              >
                Open
              </Button>
            </StyledGoToFlowItemContainer>
          )
        })}
    </StyledPreviousFlowsContainer>
  )
}
