import { cva } from 'class-variance-authority'

import styles from './badge.module.css'

const badgeClasses = cva(styles.base, {
  variants: {
    type: {
      primary: styles.primary,
      secondary: styles.secondary,
      neutral: styles.neutral,
      system: styles.system,
    },
  },
})

export interface BadgeProps {
  /** The badge's children. */
  children: string
  /** The type of the badge. */
  type?: 'primary' | 'secondary' | 'neutral' | 'system'
}

/** Badges are used in different context, next to a title. They don't have interaction. */
export function Badge({ type = 'primary', children }: BadgeProps) {
  return <div className={badgeClasses({ type })}>{children}</div>
}

export default Badge
