import { Node } from '@hubtype/ui-react-web/flow-builder'
import { cx } from 'class-variance-authority'
import { memo } from 'react'

import {
  QUEUE_CLOSED,
  QUEUE_OPEN,
  QUEUE_OPEN_WITHOUT_AGENTS,
} from '../../constants'
import { QueueStatusNodeProps } from '../../types'
import { TextSmall } from '../base'
import styles from './node.module.css'

const QueueStatusNode = ({ data, selected, id }: QueueStatusNodeProps) => {
  return (
    <Node
      type='condition'
      isSelected={selected}
      nodeContent={data}
      hasHeaderDivider={true}
    >
      {({ NodeSection, NodeCondition, NodeGroup }) => (
        <>
          <NodeSection title='Project' name={data.queue?.projectName} />
          <NodeSection title='Queue' name={data.queue?.name} />
          <NodeGroup>
            <NodeCondition
              id={`${id}-${QUEUE_OPEN}`}
              isConnectionRequired={true}
            >
              <div className={styles.conditionWithSubsection}>
                If queue is open
                {data.checkAvailableAgents && (
                  <div>
                    <div className={cx(styles.circle, styles.green)} />
                    <TextSmall>Agents available</TextSmall>
                  </div>
                )}
              </div>
            </NodeCondition>
            {data.checkAvailableAgents && (
              <NodeCondition
                id={`${id}-${QUEUE_OPEN_WITHOUT_AGENTS}`}
                isConnectionRequired={true}
              >
                <div className={styles.conditionWithSubsection}>
                  If queue is open
                  <div>
                    <div className={cx(styles.circle, styles.red)} />
                    <TextSmall>No agents available</TextSmall>
                  </div>
                </div>
              </NodeCondition>
            )}
            <NodeCondition
              id={`${id}-${QUEUE_CLOSED}`}
              isConnectionRequired={true}
            >
              If queue is closed
            </NodeCondition>
          </NodeGroup>
        </>
      )}
    </Node>
  )
}

export default memo(QueueStatusNode)
