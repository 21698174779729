import { JsonObject, JsonProperty } from 'json2typescript'

export enum HubtypeUserStatus {
  Offline = 'status_offline',
  Available = 'status_available',
  Away = 'status_away',
  Busy = 'status_busy',
}

export enum HubtypeUserRole {
  Admin = 'role_admin',
  Manager = 'role_manager',
  Agent = 'role_agent',
}

@JsonObject('HubtypeUser')
export class HubtypeUser {
  @JsonProperty('id', String)
  public id = ''

  @JsonProperty('status', String, true)
  public status: HubtypeUserStatus = HubtypeUserStatus.Offline

  @JsonProperty('organization_id', String, true)
  public organizationId = ''

  @JsonProperty('role', String, true)
  public role: HubtypeUserRole = HubtypeUserRole.Agent

  @JsonProperty('email', String, true)
  public email = ''

  @JsonProperty('username', String, true)
  public username = ''

  @JsonProperty('first_name', String, true)
  public firstName = ''

  @JsonProperty('last_name', String, true)
  public lastName = ''

  @JsonProperty('pic', String, true)
  public pic = ''

  @JsonProperty('is_active', Boolean, true)
  public isActive = false

  public getUserName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }
    return this.username || this.email
  }
}
