import './markdown-styles.css'

import ReactMarkdown from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

import { Text } from '../node-styles'

export interface MarkdownProps {
  children: string
  isSelected?: boolean
}

export const Markdown = ({
  children,
  isSelected,
}: MarkdownProps): JSX.Element => {
  let className = 'react-markdown'
  if (isSelected) className += ' selected'
  return (
    <ReactMarkdown
      className={className}
      children={children}
      rehypePlugins={[remarkGfm, [rehypeExternalLinks, { target: '_blank' }]]}
      remarkPlugins={[remarkBreaks]}
    />
  )
}

export const MarkdownText = (props: MarkdownProps): JSX.Element => {
  return (
    <Text>
      <Markdown {...props} />
    </Text>
  )
}
