import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { WebviewAction } from './webview-action'

export interface SelectWebviewInterface {
  type: ActionType.SelectWebview
  webviewId: string
}

export interface SelectWebviewHistoryChange {
  type: ActionType.SelectWebview
  selectedWebviewId: string
  previousWebviewId?: string
  currentNode?: NodeTypes
}

export class SelectWebviewAction extends ReversibleAction {
  static apply = (
    state: State,
    { webviewId }: SelectWebviewInterface
  ): void => {
    const previousWebviewId = state.currentWebviewId
    WebviewAction.selectWebview(state, webviewId)
    this.trackHistoryChange(state, webviewId, previousWebviewId)
  }

  static undo = (state: State, change: SelectWebviewHistoryChange) => {
    WebviewAction.selectWebview(state, change.previousWebviewId)
  }

  static redo = (state: State, change: SelectWebviewHistoryChange) => {
    WebviewAction.selectWebview(state, change.selectedWebviewId)
  }

  private static trackHistoryChange = (
    state: State,
    selectedWebviewId: string,
    previousWebviewId?: string
  ) => {
    const newChange: SelectWebviewHistoryChange = {
      type: ActionType.SelectWebview,
      selectedWebviewId,
      previousWebviewId,
    }
    this.updateChangesHistory(state, newChange)
  }
}
