import { Node } from '@hubtype/ui-react-web/flow-builder'
import {
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
} from 'reactflow'

import { InputContentType } from '../../domain/models/content-fields'
import AiBrainIcon from '../../UI/assets/ai-brain.svg'
import { SmartIntentFields } from './model'

export interface SmartIntentNodeInterface
  extends ReactFlowNode<SmartIntentFields> {
  data: SmartIntentFields
  type: InputContentType.SMART_INTENT
}

export interface SmartIntentNodeProps
  extends ReactFlowNodeProps<SmartIntentFields> {
  data: SmartIntentFields
  type: InputContentType.SMART_INTENT
}

export const SmartIntentNode = ({ data, selected }: SmartIntentNodeProps) => {
  return (
    <Node
      type='input'
      isAi={true}
      isSelected={selected}
      iconComponent={<AiBrainIcon />}
      nodeContent={data}
    >
      {({ NodeSection, NodeGroup }) => (
        <NodeGroup>
          <NodeSection title='Intent title' name={data.title} />
        </NodeGroup>
      )}
    </Node>
  )
}
