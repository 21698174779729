import { memo } from 'react'

import whatsappCtaUrlButtonAsset from '../../UI/assets/whatsapp-cta-url-button.png'
import { HtInfoPanel, TextBodyBold } from '../../UI/components/base'
import { ContentTextField } from '../../UI/components/node-editor-panel/editor-widgets/text-input-widgets'
import { UrlDropdown } from '../../UI/components/node-editor-panel/editor-widgets/url-widget/url-dropdown'
import { EditorProps } from '../../UI/components/node-editor-panel/node-editor-panel'
import { UniqueIdentifierField } from '../../UI/components/node-editor-panel/unique-identifier/unique-identifier-widget'
import {
  MAX_WHATSAPP_BUTTON_TEXT_LENGTH,
  MAX_WHATSAPP_FOOTER_TEXT_LENGTH,
  MAX_WHATSAPP_HEADER_TEXT_LENGTH,
  MAX_WHATSAPP_MAIN_TEXT_LENGTH,
} from '../../UI/constants'
import {
  BUTTON_TEXT,
  BUTTON_URL,
  FOOTER,
  HEADER,
  MAIN_TEXT,
} from './edit-panel-fields'
import { WhatsappCTAUrlButtonFields } from './model'

export interface WhatsappCTAUrlButtonEditorProps extends EditorProps {
  nodeContent: WhatsappCTAUrlButtonFields
}

export const WhatsappCTAUrlButtonEditor = memo(
  ({ nodeContent, ...props }: WhatsappCTAUrlButtonEditorProps): JSX.Element => {
    return (
      <>
        <TextBodyBold>URL Button</TextBodyBold>
        <UniqueIdentifierField
          {...props}
          {...nodeContent}
          value={nodeContent.code}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={HEADER}
          value={nodeContent.header}
          multiline={true}
          hasEmojiPicker={true}
          defaultRows={1}
          maxLength={MAX_WHATSAPP_HEADER_TEXT_LENGTH}
          isOptional={true}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={MAIN_TEXT}
          value={nodeContent.text}
          markdown={true}
          hasLists={true}
          // TODO: add support for variables
          // hasVariables={true}
          hasEmojiPicker={true}
          maxLength={MAX_WHATSAPP_MAIN_TEXT_LENGTH}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={FOOTER}
          value={nodeContent.footer}
          markdown={true}
          hasEmojiPicker={true}
          defaultRows={1}
          maxLength={MAX_WHATSAPP_FOOTER_TEXT_LENGTH}
          isOptional={true}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={BUTTON_TEXT}
          value={nodeContent.button.text}
          hasEmojiPicker={false}
          maxLength={MAX_WHATSAPP_BUTTON_TEXT_LENGTH}
        />
        <UrlDropdown
          {...props}
          field={BUTTON_URL}
          selectedUrl={nodeContent.button.url}
        />
        <HtInfoPanel
          title='About URL button'
          text={
            <>
              <div>
                Use this message to send a text containing a button that
                redirects users to a website or webview. Each of these messages
                can only contain a single button and is the only WhatsApp button
                capable of directing users to a URL.
              </div>
              <div>
                Message example:
                <img
                  alt='whatsapp-url-button-example'
                  src={whatsappCtaUrlButtonAsset}
                />
              </div>
            </>
          }
        ></HtInfoPanel>
      </>
    )
  }
)
