import { JsonObject, JsonProperty } from 'json2typescript'

import { HubtypeQueue } from './hubtype-queue'

@JsonObject('HubtypeProject')
export class HubtypeProject {
  @JsonProperty('id', String, true)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('queues', [HubtypeQueue], true)
  public queues: HubtypeQueue[] = []
}
