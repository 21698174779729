import { Dialog, TextInput } from '@hubtype/ui-react-web'
import { useEffect, useRef, useState } from 'react'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { MAX_FLOW_NAME_LENGTH } from '../../../constants'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { Flow } from '../../../types'
import { ModalType } from '../../modal/modal-types'

export interface EditFlowModalProps {
  type: ModalType.EditFlow
  flowToEdit: Flow
}

export const EditFlowModal = ({
  flowToEdit,
}: EditFlowModalProps): JSX.Element => {
  const analytics = useAnalytics()
  const inputRef = useRef<HTMLInputElement>(null)
  const { state, setModalContent, setFlows } = useFlowBuilderSelector(
    ctx => ctx
  )
  const [flowName, setFlowName] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => inputRef.current?.focus(), [])

  const renameFlow = async (): Promise<void> => {
    analytics.trackEvent(TrackEventName.FlowRename, {
      has_renamed: true,
      flow_name: flowName,
      previous_name: flowToEdit.name,
    })
    setModalContent()
    const newFlows = state.flows.map(flow => {
      if (flow.id !== flowToEdit.id) return flow
      return { ...flow, name: flowName }
    })
    setFlows(newFlows)
  }

  const cancel = () => {
    analytics.trackEvent(TrackEventName.FlowRename, { has_renamed: false })
  }

  const onNameChanges = (value: string) => {
    setFlowName(value)
    if (value.length > 30) {
      setError('Flow title is too long')
    } else if (state.flows.some(flow => flow.name === value)) {
      setError('Flow title already exists')
    } else {
      setError(undefined)
    }
  }

  return (
    <Dialog
      title='Rename flow'
      confirmButton={{
        children: 'Save',
        isDisabled: !flowName || !!error,
        onPress: renameFlow,
      }}
      onClose={cancel}
    >
      <TextInput
        ref={inputRef}
        label='Flow title'
        placeholder={flowToEdit.name}
        onChange={onNameChanges}
        value={flowName}
        maxLength={MAX_FLOW_NAME_LENGTH}
        isInvalid={!!error}
        errorMessage={error}
      />
    </Dialog>
  )
}
