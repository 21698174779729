import { useEffect, useRef, useState } from 'react'
import { useNumberFormatter, useSlider } from 'react-aria'
import { useSliderState } from 'react-stately'

import Field from '../field/field'
import { SliderStateContext } from './context'
import styles from './slider.module.css'
import { SliderInput } from './widgets/slider-input'
import { Thumb } from './widgets/thumb'

// TODO: move into util-hooks library
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)
    return () => clearTimeout(timer)
  }, [value, delay])

  return debouncedValue
}

const DEBOUNCE_DELAY = 500

export interface SliderProps {
  /** The value of the slider (controlled) */
  value?: number
  /** The default value of the slider (uncontrolled) */
  defaultValue?: number
  /** The minimum value of the slider */
  minValue?: number
  /** The maximum value of the slider */
  maxValue?: number
  /** The step of the slider */
  step?: number
  /** The label of the slider */
  label?: string
  /** The description of the slider */
  description?: string
  /** Whether the slider is disabled */
  isDisabled?: boolean
  /** Options to format the displayed numbers */
  formatOptions?: Intl.NumberFormatOptions
  /** Callback fired when the value changes */
  onChange?: (value: number) => void
}

/** A slider allows users to quickly select a value within a range. */
export const Slider = ({ formatOptions, onChange, ...props }: SliderProps) => {
  const trackRef = useRef<HTMLDivElement>(null)
  const isFirstRender = useRef(true)
  const numberFormatter = useNumberFormatter(formatOptions)
  const state = useSliderState({ ...props, numberFormatter })
  const { trackProps } = useSlider(
    { ...props, 'aria-label': props.label },
    state,
    trackRef
  )
  const debouncedValue = useDebounce(state.getThumbValue(0), DEBOUNCE_DELAY)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    onChange?.(state.getThumbValue(0))
  }, [debouncedValue])

  useEffect(() => {
    if (props.defaultValue !== state.getThumbValue(0)) {
      state.setThumbValue(0, props.defaultValue ?? 0)
    }
  }, [props.defaultValue])

  const sliderValue =
    ((state.getThumbValue(0) - (props.minValue ?? 0)) /
      ((props.maxValue ?? 100) - (props.minValue ?? 0))) *
    100

  return (
    <SliderStateContext.Provider value={state}>
      <Field {...props}>
        <div className={styles.trackContainer}>
          <div
            {...trackProps}
            ref={trackRef}
            className={styles.track}
            aria-disabled={props.isDisabled}
            style={
              { '--slider-value': `${sliderValue}%` } as React.CSSProperties
            }
          >
            <Thumb index={0} trackRef={trackRef} label={props.label} />
          </div>
          <SliderInput {...props} />
        </div>
      </Field>
    </SliderStateContext.Provider>
  )
}
