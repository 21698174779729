import { cva, cx } from 'class-variance-authority'
import { Handle, HandleProps, Position } from 'reactflow'

import { NodeContent } from '../node/node'
import styles from './handle.module.css'

const handleClasses = cva(styles.base, {
  variants: {
    type: {
      button: styles.buttonHandle,
      condition: styles.defaultHandle,
      element: styles.defaultHandle,
      followup: styles.followupHandle,
      input: styles.inputHandle,
      section: styles.sectionHandle,
      'start-flow': styles.startFlow,
      target: styles.targetHandle,
    },
    isHidden: {
      true: styles.hidden,
    },
    isInvalid: {
      true: styles.invalid,
    },
  },
})

export type HandleType =
  | 'button'
  | 'condition'
  | 'element'
  | 'followup'
  | 'input'
  | 'section'
  | 'start-flow'
  | 'target'

export interface TriangleHandleProps
  extends Omit<HandleProps, 'position' | 'type'>,
    NodeHandleProps {
  /** The type of the handle */
  handleType: HandleType
  /** Whether the handle is hidden */
  isHidden?: boolean
  /** Whether the handle is required */
  isRequired?: boolean
}

export interface NodeHandleProps {
  /** The node content */
  nodeContent: NodeContent
  /** Whether the handle is selected */
  isNodeSelected?: boolean
}

export const TriangleHandle = ({
  handleType,
  isHidden,
  isRequired,
  isNodeSelected,
  nodeContent,
  ...props
}: TriangleHandleProps): JSX.Element => {
  const isInvalid =
    isRequired &&
    isNodeSelected &&
    nodeContent?.errors.showErrors &&
    !nodeContent.edges.some(edge => edge.sourceHandle === props.id)

  return (
    <>
      {isInvalid && (
        <div
          className={cx(
            styles.warningCircle,
            handleClasses({ type: handleType })
          )}
        />
      )}
      <Handle
        {...props}
        type={handleType === 'target' ? 'target' : 'source'}
        position={handleType === 'target' ? Position.Left : Position.Right}
        className={cx(
          styles.handle,
          handleClasses({ type: handleType, isHidden, isInvalid })
        )}
      >
        <svg
          width='16'
          height='18'
          viewBox='0 0 16 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.4723 8.17881L3.43899 2.88855C2.90826 2.57774 2.12549 2.92564 2.12549 3.70974L2.12549 14.2903C2.12549 15.0739 2.90743 15.4219 3.43814 15.1119L12.4723 9.82119L12.4734 9.82056C13.0825 9.46273 13.082 8.53614 12.4723 8.17881ZM14.9522 10.8239C16.3493 10.0057 16.3493 7.99425 14.9522 7.17606L3.20315 0.295372C1.78623 -0.534431 9.32575e-07 0.482687 8.61036e-07 2.11931L2.59507e-07 15.8807C1.87968e-07 17.5173 1.78623 18.5344 3.20315 17.7046L14.9522 10.8239Z'
            fill='white'
          />
          <path
            d='M12.4723 8.17881L3.43899 2.88855C2.90826 2.57774 2.12549 2.92564 2.12549 3.70974L2.12549 14.2903C2.12549 15.0739 2.90743 15.4219 3.43814 15.1119L12.4723 9.82119L12.4734 9.82056C13.0825 9.46273 13.082 8.53614 12.4723 8.17881Z'
            fill='current'
          />
        </svg>
      </Handle>
    </>
  )
}
