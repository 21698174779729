import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { NodeAction } from './node-action'

export interface UpdateAllNodesInterface {
  type: ActionType.UpdateAllNodes
  newNodes: NodeTypes[]
  isReversible?: boolean
}

export interface UpdateAllNodesHistoryChange {
  type: ActionType.UpdateAllNodes
  newNodes: NodeTypes[]
  oldNodes: NodeTypes[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class UpdateAllNodesAction extends ReversibleAction {
  static apply = (
    state: State,
    { newNodes, isReversible = true }: UpdateAllNodesInterface
  ): void => {
    if (isReversible) this.trackHistoryChange(state, newNodes)
    this.updateAllNodes(state, newNodes)
  }

  static undo = (state: State, change: UpdateAllNodesHistoryChange) => {
    state.nodes = change.oldNodes
  }

  static redo = (state: State, change: UpdateAllNodesHistoryChange) => {
    this.updateAllNodes(state, change.newNodes)
  }

  static updateAllNodes = (state: State, newNodes: NodeTypes[]): void => {
    state.nodes = newNodes
    NodeAction.reportDuplicatedIdentifier(state, node => {
      node.data.setErrors()
      node.data.errors.showErrors =
        node.data.errors.showErrors || node.id !== state.currentNode?.id
    })
    if (state.currentNode) {
      state.currentNode = newNodes.find(
        node => node.id === state.currentNode?.id
      )
    }
  }

  private static trackHistoryChange = (state: State, newNodes: NodeTypes[]) => {
    const newChange: UpdateAllNodesHistoryChange = {
      type: ActionType.UpdateAllNodes,
      newNodes,
      oldNodes: state.nodes,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }
}
