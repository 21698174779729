import { HandleProps } from 'reactflow'

import { NodeHandleProps, TriangleHandle } from './handle'

export interface ContentId {
  id: string
  type: string
}

export const getTargetHandleId = (contentId: ContentId): string =>
  contentId.type + '_' + contentId.id

export const createFollowUpId = (id: string): string => {
  return 'follow_up_' + id
}

export interface HandlesProps extends NodeHandleProps, Partial<HandleProps> {}

export const Handles = (props: HandlesProps): JSX.Element => {
  return (
    <>
      <TargetHandle {...props} />
      <FollowupHandle {...props} />
    </>
  )
}

export const FollowupHandle = (props: HandlesProps): JSX.Element => {
  return (
    <TriangleHandle
      {...props}
      handleType='followup'
      id={createFollowUpId(props.nodeContent.getContentId().id)}
    />
  )
}

export const TargetHandle = (props: HandlesProps): JSX.Element => {
  return (
    <TriangleHandle
      {...props}
      handleType='target'
      id={getTargetHandleId(props.nodeContent.getContentId())}
    />
  )
}

export const InputHandle = (props: HandlesProps): JSX.Element => {
  return (
    <TriangleHandle
      {...props}
      isRequired={true}
      handleType='input'
      id={createFollowUpId(props.nodeContent.getContentId().id)}
    />
  )
}
