import { Button } from '@hubtype/ui-react-web'

import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { ModalType } from '../../modal/modal-types'

export const KnowledgeBaseButton = () => {
  const setModalContent = useFlowBuilderSelector(ctx => ctx.setModalContent)

  const openKnowledgeBaseModal = () => {
    setModalContent({ type: ModalType.KnowledgeBase })
  }

  return (
    <Button
      intent='secondary'
      appearance='minimal'
      onPress={openKnowledgeBaseModal}
    >
      Knowledge base
    </Button>
  )
}
