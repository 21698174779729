import { UrlFields } from '../../../../domain/models/content-fields'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { NonMessageAction } from './non-message-action'

export interface AddUrlInterface {
  type: ActionType.AddUrl
  newUrl: UrlFields
}

export interface AddUrlHistoryChange {
  type: ActionType.AddUrl
  newUrl: UrlFields
  currentNode?: NodeTypes
  currentFlowId: string
}

export class AddUrlAction extends ReversibleAction {
  static apply = (state: State, newUrl: UrlFields): void => {
    this.trackHistoryChange(state, newUrl)
    NonMessageAction.addUrl(state, newUrl)
  }

  static undo = (state: State, change: AddUrlHistoryChange): void => {
    NonMessageAction.removeUrl(state, change.newUrl)
  }

  static redo = (state: State, change: AddUrlHistoryChange): void => {
    NonMessageAction.addUrl(state, change.newUrl)
  }

  private static trackHistoryChange = (state: State, newUrl: UrlFields) => {
    const newChange: AddUrlHistoryChange = {
      type: ActionType.AddUrl,
      newUrl,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }
}
