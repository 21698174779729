import { Dialog } from '@hubtype/ui-react-web'
import { PresenceMessage } from 'ably'
import { cx } from 'class-variance-authority'
import { useState } from 'react'

import { TrackEventName, useAnalytics } from '../../analytics'
import { ModalType } from '../../components/modal/modal-types'
import { postCloseFromFlowBuilderMessage } from '../../hubtype-events'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import styles from './session-modal.module.css'

export interface SessionConflictModalProps {
  type: ModalType.SessionConflict
  onConfirm: () => void
  onDiscard: () => void
  activeUser?: PresenceMessage
}

export const SessionConflictModal = ({
  activeUser,
  onConfirm,
  onDiscard,
}: SessionConflictModalProps): JSX.Element => {
  const analytics = useAnalytics()
  const { state } = useFlowBuilderSelector(ctx => ctx)
  const [hasEndedSession, setHasEndedSession] = useState(false)

  const isSameUser = () => {
    return activeUser?.clientId === state.hubtypeUser?.id
  }

  const logUserOut = (): void => {
    analytics.trackEvent(TrackEventName.UserEndsSession, {
      action: 'end_session',
      other_user_session: !isSameUser(),
    })
    setHasEndedSession(true)
    onConfirm()
  }

  const enterToEmptyFlow = (): void => {
    setHasEndedSession(true)
    onConfirm()
  }

  const discard = (): void => {
    analytics.trackEvent(TrackEventName.UserEndsSession, {
      action: 'cancel',
      other_user_session: !isSameUser(),
    })
    onDiscard()
    postCloseFromFlowBuilderMessage(null)
  }

  if (!activeUser) {
    return (
      <Dialog
        title='Flow Now Available'
        confirmButton={{ children: 'Start Editing', onPress: enterToEmptyFlow }}
        onClose={discard}
      >
        The user who was editing this flow has exited. You can now start
        editing.
      </Dialog>
    )
  }

  return (
    <Dialog
      title='Flow currently being edited'
      confirmButton={{
        children: 'End their session',
        isDisabled: hasEndedSession,
        onPress: logUserOut,
      }}
      discardButton={{ isDisabled: hasEndedSession }}
      onClose={discard}
    >
      Another user is currently working on this flow. To start editing, end
      their session and their changes will be saved.
      <div
        className={cx(styles.sessionBox, {
          [styles.ended]: hasEndedSession,
        })}
      >
        <div className={styles.activeUser}>
          <strong>Currently active user</strong>
          {activeUser.data.userName}
        </div>
        {hasEndedSession && <div>Saving changes...</div>}
      </div>
    </Dialog>
  )
}
