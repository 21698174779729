import cloneDeep from 'lodash/cloneDeep'
import { Connection, Edge } from 'reactflow'

import { hasFollowUpHandle } from '../../../components/edges/edge-utils'
import { EdgeType, State } from '../../../types'

export abstract class EdgeAction {
  static addEdge = (state: State, edge: Edge): void => {
    const newEdge = cloneDeep(edge)
    this.addEdgeToNode(state, newEdge)
  }

  static addEdges = (state: State, edges: Edge[]): void => {
    const newEdges = cloneDeep(edges)
    newEdges.forEach(edge => {
      this.addEdgeToNode(state, edge)
    })
  }

  static removeEdge = (state: State, edgeToRemove: Edge): void => {
    this.removeEdgeFromNode(state, edgeToRemove)
  }

  static removeEdges = (state: State, edgesToRemove: Edge[]): void => {
    edgesToRemove.forEach(edgeToRemove => {
      this.removeEdgeFromNode(state, edgeToRemove)
    })
  }

  static getEdgeBySourceHandle = (
    state: State,
    connection: Connection
  ): Edge | null => {
    const node = state.nodes.find(node => node.id === connection.source)
    return (
      node?.data.edges.find(
        edge => edge.sourceHandle === connection.sourceHandle
      ) || null
    )
  }

  static getEdgeById = (state: State, id: string): Edge | null => {
    return (
      state.nodes
        .flatMap(node => node.data.edges)
        .find(edge => edge.id === id) || null
    )
  }

  static connectionToEdge = (connection: Connection): Edge | null => {
    const { source, sourceHandle, target, targetHandle } = connection
    if (!source || !target || !sourceHandle || !targetHandle) return null
    const isFollowUp = hasFollowUpHandle(connection)
    return {
      source,
      sourceHandle,
      target,
      targetHandle,
      id: `reactflow__edge-${source}${sourceHandle}-${target}${targetHandle}`,
      type: EdgeType.Bezier,
      data: { isFollowUp },
      selected: false,
    }
  }

  private static addEdgeToNode = (state: State, edge: Edge): void => {
    const newNodes = cloneDeep(state.nodes)
    const node = newNodes.find(node => node.id === edge.source)
    if (node) {
      node.data.edges.push(edge)
      node.data.setErrors()
      if (state.currentNode?.id === node.id) {
        state.currentNode = node
      }
    }
    state.nodes = newNodes
  }

  private static removeEdgeFromNode = (state: State, edge: Edge): void => {
    const newNodes = cloneDeep(state.nodes)
    const node = newNodes.find(node => node.id === edge.source)
    if (node) {
      node.data.edges = node.data.edges.filter(e => e.id !== edge.id)
      node.data.setErrors()
      if (state.currentNode?.id === node.id) {
        state.currentNode = node
      }
    }
    state.nodes = newNodes
  }
}
