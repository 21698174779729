import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { FALLBACK_MESSAGE_1, FALLBACK_MESSAGE_2 } from '../../constants'
import { FallbackNodeProps } from '../../types'

const FallbackNode = ({
  data,
  selected,
  id,
}: FallbackNodeProps): JSX.Element => {
  return (
    <Node type='fallback' isSelected={selected} nodeContent={data}>
      {({ NodeCondition, NodeGroup }) => (
        <NodeGroup>
          <NodeCondition
            id={`${id}-${FALLBACK_MESSAGE_1}`}
            isConnectionRequired={true}
          >
            1st message
          </NodeCondition>
          <NodeCondition id={`${id}-${FALLBACK_MESSAGE_2}`}>
            2nd message
          </NodeCondition>
        </NodeGroup>
      )}
    </Node>
  )
}

export default memo(FallbackNode)
