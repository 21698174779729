import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { HTTP_URL_REGEX } from '../../../UI/constants'
import { HtContentType } from '../cms/hubtype/node-types'
import { HtVideoNode } from '../cms/hubtype/video'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import { ContentType, MessageContentType, TopContentFieldsBase } from '.'
import { VideoLocale } from './common'

export class VideoFields extends TopContentFieldsBase {
  public videoUrl = ''
  public videoLocales: VideoLocale[] = []

  contentType(): MessageContentType {
    return ContentType.VIDEO
  }

  static getDisplayInfo() {
    return { name: 'Video', icon: 'film' }
  }

  getDisplayInfo() {
    return VideoFields.getDisplayInfo()
  }

  setValuesByLocale(locale: string): void {
    this.videoUrl = LocaleManager.getVideoUrlByLocale(this.videoLocales, locale)
  }

  setContentByLocale(locale: LocaleCode): void {
    this.videoLocales = LocaleManager.setVideoUrlByLocale(
      this.videoLocales,
      this.videoUrl,
      locale
    )
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.videoLocales, newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.videoLocales, localesToRemove)
  }

  static fromHubtypeCMS(component: HtVideoNode, locale: string): VideoFields {
    const newVideo = new VideoFields()
    this.setHubtypeCmsCommonData(newVideo, component)
    newVideo.videoLocales = component.content.video
    newVideo.setValuesByLocale(locale)
    return newVideo
  }

  toHubtypeCMS(position: XYPosition, locale: LocaleCode): HtVideoNode {
    this.setContentByLocale(locale)
    const node: HtVideoNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.VIDEO,
      content: {
        video: this.videoLocales,
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('Content ID')
    if (!this.videoUrl) requiredFields.push('Video embed')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    if (this.videoUrl && !HTTP_URL_REGEX.test(this.videoUrl)) {
      fieldErrors.push('Video embed is invalid')
    }
    this.errors.fieldErrors = fieldErrors
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors = LocaleManager.getLocalesWithEmptyVideo(
      this.videoLocales,
      locales
    )
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.code], value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(content: VideoFields): VideoFields {
    const newVideo = new VideoFields()
    TopContentFieldsBase.copyContent(content, newVideo)
    return newVideo
  }
}
