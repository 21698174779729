import { JsonObject, JsonProperty } from 'json2typescript'

import { HubtypeOrganizationFeatureFlags } from './organization-feature-flags'

@JsonObject('HubtypeOrganization')
export class HubtypeOrganization {
  @JsonProperty('id', String)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('feature_flags', HubtypeOrganizationFeatureFlags, true)
  public featureFlags: HubtypeOrganizationFeatureFlags =
    new HubtypeOrganizationFeatureFlags()
}
