import { Key } from '@react-types/shared'
import { cva } from 'class-variance-authority'
import { Children, isValidElement, ReactElement } from 'react'
import { Item, useTabListState } from 'react-stately'

import { TabListStateContext } from './context'
import styles from './tabs.module.css'

const tabClasses = cva(styles.tabs, {
  variants: {
    stretch: {
      true: styles.stretch,
    },
  },
})

export interface TabsProps {
  /** The children to render. */
  children: ReactElement[]
  /** The currently selected key in the collection (controlled). */
  selectedKey?: Key
  /** The initial selected key in the collection (uncontrolled). */
  defaultSelectedKey?: Key
  /** Whether the TabList is disabled. Shows that a selection exists, but is not available in that circumstance. */
  isDisabled?: boolean
  /** The item keys that are disabled. These items cannot be selected, focused, or otherwise interacted with. */
  disabledKeys?: Iterable<Key>
  /** Whether the TabList should be displayed vertically. */
  stretch?: boolean
  /** The class name of the TabList. */
  className?: string
  /** Handler that is called when the selection changes. */
  onSelectionChange?: (key: Key) => void
}

/**
 * Tabs organize content into multiple sections and allow users to navigate between them.
 * The content under the set of tabs should be related and form a coherent unit.
 * */
export function Tabs({ stretch, children, className, ...props }: TabsProps) {
  const processChildren = () => {
    if (!children[0]) return []
    const tabs = Children.toArray(children[0].props.children)
    return tabs.filter(isValidElement).map((tab: ReactElement) => (
      <Item key={tab.props.id} {...tab.props}>
        {tab.props.children}
      </Item>
    ))
  }
  const state = useTabListState({ ...props, children: processChildren() })

  return (
    <div className={tabClasses({ stretch, className })}>
      <TabListStateContext.Provider value={state}>
        {children}
      </TabListStateContext.Provider>
    </div>
  )
}

export default Tabs
