import {
  Icon,
  Switch,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@hubtype/ui-react-web'
import { memo } from 'react'

import { TextBodyBold } from '../../../UI/components/base'
import { KNOWLEDGE_FEEDBACK } from '../../../UI/components/node-editor-panel/constants'
import styles from '../../../UI/components/node-editor-panel/edit-panel.module.css'
import { EditorProps } from '../../../UI/components/node-editor-panel/node-editor-panel'
import { UniqueIdentifierField } from '../../../UI/components/node-editor-panel/unique-identifier/unique-identifier-widget'
import { KnowledgeBaseFields } from '../model'
import { TabSettings } from './tab-settings'
import { TabSources } from './tab-sources'

export interface KnowledgeBaseEditorProps extends EditorProps {
  nodeContent: KnowledgeBaseFields
}

export const KnowledgeBaseEditor = memo(
  ({ nodeContent, ...props }: KnowledgeBaseEditorProps): JSX.Element => {
    return (
      <>
        <TextBodyBold>Knowledge base</TextBodyBold>
        <UniqueIdentifierField
          {...props}
          {...nodeContent}
          value={nodeContent.code}
        />
        <Tabs className={styles.tabs}>
          <TabList aria-label='Knowledge base'>
            <Tab id='Sources'>Sources</Tab>
            <Tab id='Settings'>Settings</Tab>
          </TabList>
          <TabPanel id='Sources'>
            <TabSources nodeContent={nodeContent} {...props} />
          </TabPanel>
          <TabPanel id='Settings'>
            <TabSettings nodeContent={nodeContent} {...props} />
          </TabPanel>
        </Tabs>
        <div className={styles.footer}>
          <span className={styles.footerTitle}>
            <Icon icon='chart-simple' size='small' />
            Analytics
          </span>
          <Switch
            isReadOnly={props.isReadOnly}
            isSelected={nodeContent.feedbackEnabled}
            onChange={isSelected => {
              props.onChange({
                type: KNOWLEDGE_FEEDBACK.actionType,
                fieldKey: KNOWLEDGE_FEEDBACK.key,
                value: isSelected,
              })
            }}
            description={KNOWLEDGE_FEEDBACK.helperText}
          >
            {KNOWLEDGE_FEEDBACK.label}
          </Switch>
        </div>
      </>
    )
  }
)
