import {
  ButtonFields,
  UrlFields,
} from '../../../../domain/models/content-fields'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { NodeAction } from '../node-actions/node-action'
import { ReversibleAction } from '../reversible-action'
import { NonMessageAction } from './non-message-action'

export interface EditUrlInterface {
  type: ActionType.EditUrl
  urlToEdit: UrlFields
  newName: string
}

export interface EditUrlHistoryChange {
  type: ActionType.EditUrl
  urlToEdit: UrlFields
  newName: string
  oldNodes: NodeTypes[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class EditUrlAction extends ReversibleAction {
  static apply = (
    state: State,
    { urlToEdit, newName }: EditUrlInterface
  ): void => {
    this.trackHistoryChange(state, urlToEdit, newName)
    NonMessageAction.editUrl(state, urlToEdit, newName)
    this.editUrlFromNodes(state, urlToEdit, newName)
  }

  static undo = (state: State, change: EditUrlHistoryChange): void => {
    NonMessageAction.editUrl(state, change.urlToEdit, change.urlToEdit.name)
    state.nodes = change.oldNodes
  }

  static redo = (state: State, change: EditUrlHistoryChange): void => {
    NonMessageAction.editUrl(state, change.urlToEdit, change.newName)
    this.editUrlFromNodes(state, change.urlToEdit, change.newName)
  }

  private static trackHistoryChange = (
    state: State,
    urlToEdit: UrlFields,
    newName: string
  ) => {
    const newChange: EditUrlHistoryChange = {
      type: ActionType.EditUrl,
      urlToEdit,
      newName,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
      oldNodes: state.nodes,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static editUrlFromNodes = (
    state: State,
    urlToEdit: UrlFields,
    newName: string
  ) => {
    NodeAction.updateButtonsInNodes(state, button => {
      this.editUrlIfMatch(button, urlToEdit, newName)
    })
  }

  private static editUrlIfMatch = (
    button: ButtonFields,
    urlToEdit: UrlFields,
    newName: string
  ): void => {
    if (button.url?.id === urlToEdit.id) {
      button.url = urlToEdit.cloneWithUrl(newName)
    }
  }
}
