import { Edge } from 'reactflow'

import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { EdgeAction } from '../edge-actions/edge-action'
import { ReversibleAction } from '../reversible-action'
import { CopyAction } from './copy'
import { NodeAction } from './node-action'
import { RemoveNodesAction } from './remove-nodes'

export interface CutInterface {
  type: ActionType.CutElements
  nodesToCut: NodeTypes[]
  event: ClipboardEvent
}

export interface CutHistoryChange {
  type: ActionType.CutElements
  cutNodes: NodeTypes[]
  cutEdges: Edge[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class CutAction extends ReversibleAction {
  static apply = (state: State, { nodesToCut, event }: CutInterface): void => {
    CopyAction.copy(state, nodesToCut, event)
    if (state.isReadOnly) return
    const removedEdges = RemoveNodesAction.removeNodesAndEdges(
      state,
      nodesToCut
    )
    this.trackHistoryChange(state, nodesToCut, removedEdges)
  }

  static undo = (state: State, change: CutHistoryChange) => {
    NodeAction.addNodes(state, change.cutNodes)
    EdgeAction.addEdges(state, change.cutEdges)
    NodeAction.setSelectedNodes(state, [])
  }

  static redo = (state: State, change: CutHistoryChange) => {
    RemoveNodesAction.removeNodesAndEdges(state, change.cutNodes)
  }

  private static trackHistoryChange = (
    state: State,
    cutNodes: NodeTypes[],
    cutEdges: Edge[]
  ) => {
    const newChange: CutHistoryChange = {
      type: ActionType.CutElements,
      cutNodes,
      cutEdges,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }
}
