import { JsonObject, JsonProperty } from 'json2typescript'

export enum HubtypeProvider {
  Apple = 'apple',
  Facebook = 'facebook',
  Generic = 'generic',
  Instagram = 'instagram',
  Telegram = 'telegram',
  Twitter = 'twitter',
  Webchat = 'webchat',
  Whatsapp = 'whatsapp',
  WhatsappCloud = 'whatsapp_cloud',
  WhatsappPlayground = 'whatsapp_playground',
}

@JsonObject('HubtypeProviderAccount')
export class HubtypeProviderAccount {
  @JsonProperty('id', String)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('provider', String, true)
  public provider: HubtypeProvider = HubtypeProvider.Webchat

  @JsonProperty('is_active', Boolean, true)
  public isActive = false

  @JsonProperty('phone_number', String, true)
  public phoneNumber?: string = undefined

  @JsonProperty('netlify_url', String, true)
  public netlifyUrl?: string = undefined

  @JsonProperty('is_test', Boolean, true)
  public isTest = false

  @JsonProperty('is_preview_webchat', Boolean, true)
  public isPreviewWebchat = false
}
