import { ToastOptions, ToastState } from '@react-stately/toast'

import { createContextAndHook } from '../utils/create-context'
import { ToastContentProps } from './toast'

export interface FeedbackStateContextProps
  extends ToastState<ToastContentProps> {
  addSuccess: (
    title: string,
    description?: string,
    options?: ToastOptions
  ) => string
  addError: (
    title: string,
    description?: string,
    options?: ToastOptions
  ) => string
  addConnectionError: (
    title: string,
    description?: string,
    options?: ToastOptions
  ) => string
}

export const [FeedbackStateContext, useToastFeedback] =
  createContextAndHook<FeedbackStateContextProps>()
