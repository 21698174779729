import { Dialog } from '@hubtype/ui-react-web'
import { useEffect, useState } from 'react'

import {
  ContentType,
  PayloadFields,
} from '../../../../../domain/models/content-fields'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { NodeTypes } from '../../../../types'
import { BoldSpan, TextBodySmall } from '../../../base'
import { ModalType } from '../../../modal/modal-types'

export interface RemovePayloadModalProps {
  type: ModalType.RemovePayload
  payloadToRemove: PayloadFields
}

export const RemovePayloadModal = ({
  payloadToRemove,
}: RemovePayloadModalProps): JSX.Element => {
  const { setModalContent, removePayload } = useFlowBuilderSelector(ctx => ctx)
  const { currentNode, nodes } = useFlowBuilderSelector(ctx => ctx.state)
  const [nodesWithPayload, setNodesWithPayload] = useState<NodeTypes[]>([])

  useEffect(() => {
    const linkedNodes = findLinkedNodes()
    if (shouldPayloadBeAutoDeleted(linkedNodes)) {
      deletePayload()
    } else {
      setNodesWithPayload(linkedNodes)
    }
  }, [])

  const findLinkedNodes = (): NodeTypes[] => {
    return nodes.filter(
      node =>
        node.type === ContentType.BOT_ACTION &&
        node.data.payloadId === payloadToRemove.id
    )
  }

  const shouldPayloadBeAutoDeleted = (linkedNodes: NodeTypes[]): boolean => {
    return (
      (linkedNodes.length === 1 && linkedNodes[0].id === currentNode?.id) ||
      linkedNodes.length === 0
    )
  }

  const deletePayload = (): void => {
    setModalContent()
    removePayload(payloadToRemove)
  }

  if (nodesWithPayload.length === 0) return <></>

  return (
    <Dialog
      title='Delete payload in use'
      confirmButton={{ children: 'Delete', onPress: deletePayload }}
    >
      <TextBodySmall>
        This payload is being used in one or more other bot actions in this
        conversational app. If it is deleted, it will be also removed from all
        the other bot action components.
      </TextBodySmall>
      <TextBodySmall>
        Payload to delete: <BoldSpan>{payloadToRemove.name}</BoldSpan>
      </TextBodySmall>
      <TextBodySmall>
        Bot actions using it:{' '}
        <BoldSpan>
          {nodesWithPayload.map(node => node.data.code).join('; ')}
        </BoldSpan>
      </TextBodySmall>
    </Dialog>
  )
}
