import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { HandoffNodeProps } from '../../types'

const HandoffNode = ({ data, selected }: HandoffNodeProps) => {
  return (
    <Node type='message' isSelected={selected} nodeContent={data}>
      {({ NodeSection, NodeGroup }) => (
        <NodeGroup>
          <NodeSection title='Project' name={data.queue?.projectName} />
          <NodeSection title='Queue' name={data.queue?.name} />
        </NodeGroup>
      )}
    </Node>
  )
}

export default memo(HandoffNode)
