import { Flow, NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { FlowAction } from './flow-action'

export interface AddFlowInterface {
  type: ActionType.AddFlow
  newFlow: Flow
}

export interface AddFlowHistoryChange {
  type: ActionType.AddFlow
  newFlow: Flow
  currentFlowId: string
  currentNode?: NodeTypes
}

export class AddFlowAction extends ReversibleAction {
  static apply = (state: State, { newFlow }: AddFlowInterface): void => {
    this.trackHistoryChange(state, newFlow)
    FlowAction.addFlow(state, newFlow)
    FlowAction.selectFlow(state, newFlow.id)
  }

  static undo = (state: State, change: AddFlowHistoryChange) => {
    FlowAction.removeFlow(state, change.newFlow)
  }

  static redo = (state: State, change: AddFlowHistoryChange) => {
    FlowAction.addFlow(state, change.newFlow)
    FlowAction.selectFlow(state, change.newFlow.id)
  }

  private static trackHistoryChange = (state: State, newFlow: Flow) => {
    const newChange: AddFlowHistoryChange = {
      type: ActionType.AddFlow,
      newFlow,
      currentFlowId: state.currentFlowId,
      currentNode: state.currentNode,
    }
    this.updateChangesHistory(state, newChange)
  }
}
