import { ContentType } from '../../../domain/models/content-fields'
import { TrackEventName, useAnalytics } from '../../analytics'
import { FALLBACK_FLOW, MAIN_FLOW } from '../../constants'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { Flow } from '../../types'
import { HtEditableDropdownItem } from '../base/common/dropdown/editable-dropdown-item'
import { ModalType } from '../modal/modal-types'

interface EditableFlowItemProps {
  flow: Flow
  closeFlowSelector: () => void
}

export const EditableFlowItem = ({
  flow,
  closeFlowSelector,
}: EditableFlowItemProps) => {
  const analytics = useAnalytics()
  const { state, setModalContent, removeFlow } = useFlowBuilderSelector(
    ctx => ctx
  )

  const editFlow = () => {
    closeFlowSelector()
    setModalContent({ type: ModalType.EditFlow, flowToEdit: flow })
  }

  const deleteFlow = () => {
    closeFlowSelector()
    if ([MAIN_FLOW.id, FALLBACK_FLOW.id].includes(flow.id)) return
    const linkedFlows = getConnectedFlows(flow)
    const isFlowLinked = linkedFlows.length > 0
    const hasFlowContent = state.nodes.some(
      node => node.data.flowId === flow.id
    )
    analytics.trackEvent(TrackEventName.FlowDelete, {
      flow_name: flow.name,
      is_flow_linked: isFlowLinked,
    })
    if (isFlowLinked || hasFlowContent) {
      setModalContent({
        type: ModalType.RemoveFlow,
        flowToRemove: flow,
        linkedFlows,
      })
    } else {
      removeFlow(flow)
    }
  }

  const getConnectedFlows = (flowToRemove: Flow) => {
    const nodesConnectedToFlow = getConnectedNodes(flowToRemove)
    const uniqueConnectedFlowIds = Array.from(
      new Set(nodesConnectedToFlow.map(node => node.data.flowId))
    )
    return uniqueConnectedFlowIds.reduce((acc: Flow[], flowId) => {
      const connectedFlow = state.flows.find(flow => flow.id === flowId)
      if (connectedFlow) acc.push(connectedFlow)
      return acc
    }, [])
  }

  const getConnectedNodes = (flowToRemove: Flow) => {
    return state.nodes.filter(
      node =>
        node.type === ContentType.GO_TO_FLOW &&
        node.data.targetFlowId === flowToRemove.id
    )
  }

  return (
    <HtEditableDropdownItem
      item={flow}
      onRemove={deleteFlow}
      onEdit={editFlow}
      canEdit={!state.isReadOnly}
      canRemove={!state.isReadOnly}
    />
  )
}
