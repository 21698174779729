export const getAnalyticsWriteKey = (): string => {
  if (!import.meta.env.VITE_ANALYTICS_WRITE_KEY) {
    throw new Error('You need to set env var VITE_ANALYTICS_WRITE_KEY')
  }
  return import.meta.env.VITE_ANALYTICS_WRITE_KEY
}

export enum TrackEventName {
  AddNewNode = 'fb_add_new_node',
  AddOns = 'fb_add_ons',
  AgentAvailability = 'fb_agent_availability',
  Autoassign = 'fb_autoassign',
  BotactionNewPayload = 'fb_botaction_new_payload',
  BotactionPayloadDelete = 'fb_botaction_payload_delete',
  BotactionPayloadSelect = 'fb_botaction_payload_select',
  ChangeLanguage = 'fb_change_language',
  ChangeStartMessage = 'fb_change_start_message',
  ClickContentPanel = 'fb_click_content_panel',
  ClickDeleteEdge = 'fb_click_delete_edge',
  ClickLocalErrorCta = 'fb_click_local_error_CTA',
  ClickPublishCta = 'fb_click_publish_CTA',
  ClickSaveCta = 'fb_click_save_CTA',
  ClickVersion = 'fb_click_version',
  ClickViewEverythingCta = 'fb_click_view_everything_CTA',
  ClickZoomButtons = 'fb_click_zoom_buttons',
  ConnectionRecovered = 'fb_connection_recovered',
  ContentIdAiGeneration = 'fb_contentid_AI_generation',
  ContentIdEdit = 'fb_contentid_edit',
  ContentIdError = 'fb_contentid_error',
  CopyPaste = 'fb_copy_paste',
  CustomConditionVariable = 'fb_custom_condition_variable',
  CustomConditionVariableFormat = 'fb_custom_condition_variable_format',
  Exit = 'fb_exit',
  FbPreviousFlows = 'fb_previous_flows',
  FbPreviousFlowsOpen = 'fb_previous_flows_open',
  FlowAdd = 'fb_flow_add',
  FlowConnect = 'fb_flow_connect',
  FlowDelete = 'fb_flow_delete',
  FlowOpen = 'fb_flow_open',
  FlowRename = 'fb_flow_rename',
  FlowListSearchBar = 'fb_flow_list_search_bar',
  GoToFlowSearchBar = 'fb_go_to_flow_search_bar',
  PositionInQueue = 'fb_position_in_queue',
  KnowledgeBaseActivation = 'fb_knowledge_base_activation',
  KnowledgeSourceMissingError = 'fb_knowledge_source_missing_error',
  LanguageSearchBar = 'fb_language_search_bar',
  LanguagesAddRemoveCta = 'fb_languages_add_remove_CTA',
  LanguagesOpen = 'fb_languages_open',
  Preview = 'fb_preview',
  PreviewCopyLink = 'fb_preview_copy_link',
  PreviewIntegrationsSection = 'fb_preview_integrations_section',
  PreviewOpen = 'fb_preview_open',
  PreviewWindowCountry = 'fb_preview_window_country',
  PreviewWindowLanguage = 'fb_preview_window_language',
  PreviewWindowRefresh = 'fb_preview_window_refresh',
  PublishConfirmation = 'fb_publish_confirmation',
  Redo = 'fb_redo',
  Save = 'fb_save',
  SearchBar = 'fb_search_bar',
  SearchBarArrowsClick = 'fb_search_bar_arrows_click',
  SearchBarContentTypes = 'fb_search_bar_content_types',
  SearchBarResetFilters = 'fb_search_bar_reset_filters',
  SetCarouselComponent = 'fb_set_carousel_component',
  SetCustomConditionValues = 'fb_set_custom_condition_values',
  SetTextComponentButtons = 'fb_set_text_component_buttons',
  SmartIntentTitleEdit = 'fb_smart_intent_title_edit',
  SmartIntentTitleError = 'fb_smart_intent_title_error',
  Undo = 'fb_undo',
  UserEndsSession = 'fb_user_ends_session',
  UserKickedOut = 'fb_user_kicked_out',
  UserSessionEnded = 'fb_user_session_ended',
  VariablesCreate = 'fb_variables_create',
  VariablesDelete = 'fb_variables_delete',
  VariablesOpen = 'fb_variables_open',
  VariablesSelect = 'fb_variables_select',
  VersionClickCancelCta = 'fb_version_click_cancel_CTA',
  VersionClickClose = 'fb_version_click_close',
  VersionClickRestoreConfirmationCta = 'fb_version_click_restore_confirmation_CTA',
  VersionClickRestoreCta = 'fb_version_click_restore_CTA',
  VersionHistoryOpen = 'fb_version_history_open',
  TransferFlow = 'fb_transfer_flow',
  FbError = 'fb_error',
  TransferFlowListState = 'fb_transfer_flow_list_state',
}

export enum SaveOrigin {
  AUTO_SAVE = 'auto_save',
  ON_EXIT = 'on_exit',
  BEFORE_PUBLISH = 'before_publish',
  KEYBOARD = 'keyboard',
  ON_OPEN_PREVIOUS_VERSION = 'on_open_previous_version',
  ON_SESSION_ENDED = 'on_session_ended',
}
