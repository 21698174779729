import { Connection, Edge, EdgeProps } from 'reactflow'

import { ContentId } from '../../../domain/models/content-fields'
import { EdgeType } from '../../types'
import BezierEdge from './bezier-edge'

export const FOLLOW_UP = 'follow_up_'

export interface NodeEdges {
  buttons?: (Edge | undefined)[]
  followUp?: Edge
}

export const edgeTypes = {
  [EdgeType.Bezier]: BezierEdge,
}

export const hasFollowUpHandle = (edge: Edge | Connection): boolean => {
  return edge.sourceHandle?.includes(FOLLOW_UP) || false
}

export const createFollowUpId = (id: string): string => {
  return FOLLOW_UP + id
}

export const getNewEdge = (
  source: string,
  sourceHandle: string,
  targetContentId: ContentId,
  hidden?: boolean
): Edge => {
  const isFollowUp = source === sourceHandle
  if (isFollowUp) sourceHandle = createFollowUpId(sourceHandle)
  const targetHandle = getTargetHandleId(targetContentId)
  const edge: Edge = {
    source,
    sourceHandle,
    target: targetContentId.id,
    targetHandle,
    id: `reactflow__edge-${source}${sourceHandle}-${targetContentId.id}${targetHandle}`,
    type: EdgeType.Bezier,
    data: { isFollowUp },
    hidden,
    selected: false,
  }
  return edge
}

export const getTargetHandleId = (contentId: ContentId): string =>
  contentId.type + '_' + contentId.id

export const getEdge = (edgeProps: EdgeProps, type?: EdgeType): Edge => {
  return {
    id: edgeProps.id,
    data: edgeProps.data,
    source: edgeProps.source,
    sourceHandle: edgeProps.sourceHandleId,
    target: edgeProps.target,
    type: type || EdgeType.Bezier,
  }
}
