import { Icon, IconName } from '../../../icon/icon'
import { NodeHandleProps, TriangleHandle } from '../../handle/handle'
import styles from './widgets.module.css'

export interface NodeConditionProps {
  id: string
  children: React.ReactNode
  handleType?: 'condition' | 'section'
  icon?: IconName
  isConnectableWithBotAction?: boolean
  isConnectionRequired?: boolean
  onClick?(): void
  handleProps: NodeHandleProps
}

export const NodeCondition = ({
  id,
  children,
  icon,
  handleType = 'condition',
  isConnectionRequired,
  handleProps,
  onClick,
}: NodeConditionProps): JSX.Element => {
  return (
    <div className={styles.condition} onClick={onClick}>
      {children}
      {icon && (
        <Icon
          icon={icon}
          size='large'
          prefix={icon === 'messages' ? 'fas' : 'fab'}
        />
      )}
      <TriangleHandle
        {...handleProps}
        id={id}
        handleType={handleType}
        isRequired={isConnectionRequired}
      />
    </div>
  )
}
