import { useRef } from 'react'
import { Overlay, useModalOverlay } from 'react-aria'
import { useOverlayTriggerState } from 'react-stately'

import { ModalTriggerStateContext, useModalTriggerContext } from './context'
import styles from './modal.module.css'

export interface ModalProps {
  /** The content of the modal. */
  children: React.ReactNode
  /** Whether to close the modal when the user interacts outside it. */
  isDismissable?: boolean
  /** Whether pressing the escape key to close the modal should be disabled. */
  isKeyboardDismissDisabled?: boolean
  /** Whether the overlay is open by default (controlled). */
  isOpen?: boolean
  /** Whether the overlay is open by default (uncontrolled). */
  defaultOpen?: boolean
  /** Handler that is called when the overlay's open state changes. */
  onOpenChange?: (isOpen: boolean) => void
}

/** A modal is an interface element that appears over other content. It requires an interaction from the user before they can return to whatever is underneath. */
export const Modal = (props: ModalProps) => {
  const state = useOverlayTriggerState({ ...props, defaultOpen: true })

  return (
    <ModalTriggerStateContext.Provider value={state}>
      <ModalContent {...props} />
    </ModalTriggerStateContext.Provider>
  )
}

export const ModalContent = (props: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const state = useModalTriggerContext()
  if (!state) {
    throw new Error('ModalTriggerStateContext used outside of its Provider')
  }

  const { modalProps, underlayProps } = useModalOverlay(props, state, ref)

  if (!state.isOpen) {
    return null
  }

  return (
    <Overlay>
      <div className={styles.underlay} {...underlayProps}>
        <div {...modalProps} ref={ref} className={styles.modal}>
          {props.children}
        </div>
      </div>
    </Overlay>
  )
}

export default Modal
