import { HubtypeUser } from '@hubtype/data-access-models'
import { useCallback, useContext } from 'react'

import { AnalyticsContext } from './context'
import { TrackEventName } from './utils'

export interface Analytics {
  trackEvent: (
    event: TrackEventName,
    properties?: Record<string, string | boolean | number | string[]>
  ) => Promise<void>
  identify: (user: HubtypeUser) => Promise<void>
}

const useAnalyticsContext = () => {
  const result = useContext(AnalyticsContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}

export const useAnalytics = (): Analytics => {
  const analytics = useAnalyticsContext()
  const trackEvent = useCallback(
    async (
      event: TrackEventName,
      properties?: Record<string, string | boolean | number | string[]>
    ) => {
      await analytics.track(event, properties)
    },
    [analytics]
  )

  const identify = useCallback(
    async (user: HubtypeUser) => {
      await analytics.identify(user.id, {
        userRole: user.role,
        organization_id: user.organizationId,
      })
    },
    [analytics]
  )

  return {
    trackEvent,
    identify,
  }
}
