import { ToastOptions, useToastState } from '@react-stately/toast'
import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

import { FeedbackStateContext, FeedbackStateContextProps } from './context'
import { ToastContentProps } from './toast'
import { ToastRegion, ToastRegionProps } from './toast-region'

const DEFAULT_TIMEOUT = 3000
const DEFAULT_ERROR_TIMEOUT = 4500

export interface FeedbackProviderProps extends ToastRegionProps {
  children: ((state: FeedbackStateContextProps) => ReactNode) | ReactNode
}

export function ToastFeedbackProvider({
  children,
  ...props
}: FeedbackProviderProps) {
  const state = useToastState<ToastContentProps>({
    maxVisibleToasts: 5,
    hasExitAnimation: true,
  })

  const createToastAdder =
    (type: ToastContentProps['type'], defaultTimeout?: number) =>
    (title: string, description?: string, options?: ToastOptions) => {
      return state.add(
        { title, description, type },
        { ...(options || { timeout: defaultTimeout }) }
      )
    }

  const addSuccess = createToastAdder('success', DEFAULT_TIMEOUT)
  const addError = createToastAdder('error', DEFAULT_ERROR_TIMEOUT)
  const addWarning = createToastAdder('warning', DEFAULT_TIMEOUT)
  const addInfo = createToastAdder('info', DEFAULT_TIMEOUT)
  const addConnectionError = createToastAdder('connection-error')

  const providedState = {
    ...state,
    addSuccess,
    addError,
    addInfo,
    addWarning,
    addConnectionError,
  }

  if (typeof children === 'function') {
    children = children(providedState)
  }

  return (
    <FeedbackStateContext.Provider value={providedState}>
      {children}
      {createPortal(
        state.visibleToasts.length > 0 && <ToastRegion {...props} />,
        document.body
      )}
    </FeedbackStateContext.Provider>
  )
}
