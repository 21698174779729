import orderBy from 'lodash.orderby'

import { State } from '../../../types'
import { ActionType } from '../../action-types'

export interface SetBotVariablesInterface {
  type: ActionType.SetBotVariables
  botVariables: string[]
}

export class SetBotVariablesAction {
  static apply = (
    state: State,
    { botVariables }: SetBotVariablesInterface
  ): void => {
    state.botVariables = orderBy(botVariables, undefined, ['asc'])
    if (state.reactFlowInstance) {
      state.isFlowSaved = false
    }
  }
}
