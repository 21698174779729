import { cx } from 'class-variance-authority'

import styles from './widgets.module.css'

export interface NodeSectionProps {
  title: string
  name?: string
  direction?: 'horizontal' | 'vertical'
}

export const NodeSection = ({
  name,
  title,
  direction = 'vertical',
}: NodeSectionProps): JSX.Element => {
  return (
    <div
      className={cx(
        styles.section,
        direction === 'horizontal' && styles.horizontal
      )}
    >
      <strong>{title}</strong>
      {name}
    </div>
  )
}
