import { DOMAttributes } from '@react-types/shared'

import Icon from '../icon/icon'
import styles from './field.module.css'

export interface FooterProps extends FooterErrorProps, FooterDescriptionProps {
  /** The length of the input */
  length?: number
  /** The maximum number of characters supported by the input. */
  maxLength?: number
  /** Whether the counter is enabled. */
  isCounterEnabled?: boolean
  /** The left slot for the footer. */
  footerLeftSlot?: React.ReactNode
  /** The right slot for the footer. */
  footerRightSlot?: React.ReactNode
}

export interface FooterErrorProps {
  /** An error message for the field. */
  errorMessage?: React.ReactNode
  /** Props for the select's error message element, if any. */
  errorMessageProps?: DOMAttributes
  /** Whether the input value is invalid. */
  isInvalid?: boolean
}

export interface FooterDescriptionProps {
  /** A description of the input. */
  description?: React.ReactNode
  /** Props for the select's description element, if any. */
  descriptionProps?: DOMAttributes
}

export function FieldFooter({
  descriptionProps,
  errorMessageProps,
  description,
  errorMessage,
  length = 0,
  maxLength,
  isInvalid,
  isCounterEnabled,
  footerLeftSlot,
  footerRightSlot,
}: FooterProps) {
  const hasDescription = description && (!isInvalid || !errorMessage)
  const hasErrorMessage = isInvalid && errorMessage
  const hasCounter = Boolean(maxLength) || isCounterEnabled
  const hasFooter =
    hasDescription ||
    hasErrorMessage ||
    hasCounter ||
    footerLeftSlot ||
    footerRightSlot

  if (!hasFooter) return null
  return (
    <div className={styles.footer}>
      {footerLeftSlot}
      {hasDescription && (
        <div className={styles.description} {...descriptionProps}>
          {description}
        </div>
      )}
      {hasErrorMessage && (
        <div className={styles.errorMessage} {...errorMessageProps}>
          <Icon icon='circle-exclamation' size='medium' />
          {errorMessage}
        </div>
      )}
      {hasCounter && (
        <div className={styles.counter}>
          {!maxLength ? length : `${length}/${maxLength}`}
        </div>
      )}
      {footerRightSlot}
    </div>
  )
}
