import { Node } from '@hubtype/ui-react-web/flow-builder'
import {
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
} from 'reactflow'

import { MessageContentType } from '../../domain/models/content-fields'
import { TextSmallBold } from '../../UI/components/base'
import { MarkdownText } from '../../UI/components/nodes/markdown/markdown'
import { WhatsappCTAUrlButtonFields } from './model'

export interface WhatsappCTAUrlButtonNodeInterface
  extends ReactFlowNode<WhatsappCTAUrlButtonFields> {
  data: WhatsappCTAUrlButtonFields
  type: MessageContentType.WHATSAPP_CTA_URL_BUTTON
}

export interface WhatsappCTAUrlButtonNodeProps
  extends ReactFlowNodeProps<WhatsappCTAUrlButtonFields> {
  data: WhatsappCTAUrlButtonFields
  type: MessageContentType.WHATSAPP_CTA_URL_BUTTON
}

export const WhatsappCTAUrlButtonNode = ({
  data,
  selected,
}: WhatsappCTAUrlButtonNodeProps) => {
  return (
    <Node
      type='message'
      isSelected={selected}
      isWhatsapp={true}
      nodeContent={data}
      hasHeaderDivider={true}
    >
      {({ NodeButton }) => (
        <>
          {data.header && <TextSmallBold>{data.header}</TextSmallBold>}
          <MarkdownText>{data.text || 'Main text'}</MarkdownText>
          {data.footer && <MarkdownText>{data.footer}</MarkdownText>}
          <NodeButton button={data.button} hasHandler={false} />
        </>
      )}
    </Node>
  )
}
