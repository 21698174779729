import { Dialog, useToastFeedback } from '@hubtype/ui-react-web'

import { Locale } from '../../../domain/models/locales/locale'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ModalType } from '../modal/modal-types'

export interface RemoveLocalesModalProps {
  type: ModalType.RemoveLocales
  localesToRemove: Locale[]
}
export const RemoveLocalesModal = ({
  localesToRemove,
}: RemoveLocalesModalProps): JSX.Element => {
  const feedback = useToastFeedback()
  const { setModalContent, removeLocales } = useFlowBuilderSelector(ctx => ctx)

  const deleteLocales = (): void => {
    setModalContent()
    removeLocales(localesToRemove)
    feedback.addSuccess('Languages removed')
  }

  return (
    <Dialog
      title='Remove languages'
      confirmButton={{ children: 'Remove languages', onPress: deleteLocales }}
    >
      You're about to remove one or more languages. Please note that all content
      will be deleted permanently. This action can't be undone.
    </Dialog>
  )
}
