export enum Country {
  AD = 'Andorra',
  AG = 'Antigua and Barbuda',
  AL = 'Albania',
  AR = 'Argentina',
  AT = 'Austria',
  AZ = 'Azerbaijan',
  BA = 'Bosnia and Herzegovina',
  BB = 'Barbados',
  BE = 'Belgium',
  BG = 'Bulgaria',
  BH = 'Bahrain',
  BO = 'Bolivia',
  BR = 'Brazil',
  BS = 'Bahamas',
  BY = 'Belarus',
  BZ = 'Belize',
  CA = 'Canada',
  CH = 'Switzerland',
  CL = 'Chile',
  CN = 'China',
  CO = 'Colombia',
  CR = 'Costa Rica',
  CU = 'Cuba',
  CY = 'Cyprus',
  CZ = 'Czech Republic',
  DE = 'Germany',
  DK = 'Denmark',
  DM = 'Dominica',
  DO = 'Dominican Republic',
  EC = 'Ecuador',
  EE = 'Estonia',
  ES = 'Spain',
  FI = 'Finland',
  FO = 'Faroe Islands',
  FR = 'France',
  GB = 'United Kingdom',
  GE = 'Georgia',
  GF = 'French Guiana',
  GR = 'Greece',
  GT = 'Guatemala',
  GY = 'Guyana',
  HN = 'Honduras',
  HR = 'Croatia',
  HU = 'Hungary',
  ID = 'Indonesia',
  IE = 'Ireland',
  IN = 'India',
  IS = 'Iceland',
  IT = 'Italy',
  JM = 'Jamaica',
  JP = 'Japan',
  KR = 'Korea',
  KZ = 'Kazakhstan',
  LB = 'Lebanon',
  LI = 'Liechtenstein',
  LK = 'Sri Lanka',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  LV = 'Latvia',
  MC = 'Monaco',
  MD = 'Moldova',
  ME = 'Montenegro',
  MK = 'North Macedonia',
  MT = 'Malta',
  MX = 'Mexico',
  MY = 'Malaysia',
  NI = 'Nicaragua',
  NL = 'Netherlands',
  NO = 'Norway',
  PA = 'Panama',
  PE = 'Peru',
  PL = 'Poland',
  PT = 'Portugal',
  PY = 'Paraguay',
  RO = 'Romania',
  RS = 'Serbia',
  RU = 'Russia',
  SA = 'Saudi Arabia',
  SE = 'Sweden',
  SI = 'Slovenia',
  SK = 'Slovakia',
  SM = 'San Marino',
  SR = 'Suriname',
  SV = 'El Salvador',
  TH = 'Thailand',
  TR = 'Turkey',
  TT = 'Trinidad and Tobago',
  UA = 'Ukraine',
  US = 'United States',
  UY = 'Uruguay',
  VA = 'Vatican City',
  VE = 'Venezuela',
  VN = 'Vietnam',
  ZA = 'South Africa',
}
