import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { mergeProps, useFocusRing, useTab } from 'react-aria'
import { Link } from 'react-router-dom'

import { useTabListStateContext } from './context'
import styles from './tabs.module.css'

export interface InnerTabProps<T> {
  item: Node<T>
}

export function InnerTab<T>({ item }: InnerTabProps<T>) {
  const state = useTabListStateContext()
  const ref = useRef(null)
  const { tabProps } = useTab({ key: item.key }, state, ref)
  const { focusProps, isFocusVisible } = useFocusRing()

  const Component = item.props.href && item.props.href[0] === '/' ? Link : 'a'

  return (
    <Component
      {...mergeProps(tabProps, focusProps)}
      ref={ref}
      to={item.key.toString()}
      className={styles.tab}
      data-focus-visible={isFocusVisible}
    >
      {item.rendered}
    </Component>
  )
}

export default InnerTab
