import { Icon } from '@hubtype/ui-react-web'
import { cx } from 'class-variance-authority'

import { MAIN_FLOW, START_NODE_ID } from '../../../../constants'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { NodeTypes } from '../../../../types'
import { ViewportAnimator } from '../../../../utils/viewport-animator'
import styles from '../alert-center.module.css'

interface NodeAlertProps {
  nodeWithErrors: NodeTypes
}

export const NodeAlert = ({ nodeWithErrors }: NodeAlertProps) => {
  const { state, selectFlow, selectNode } = useFlowBuilderSelector(ctx => ctx)

  const selectAndCenterNode = (): void => {
    const id = nodeWithErrors.id
    if (!id) return
    if (id === START_NODE_ID) {
      selectFlow(MAIN_FLOW.id)
    }
    ViewportAnimator.centerNode(state, id, selectNode)
  }

  return (
    <li
      className={cx(styles.alertItem, styles.isNodeAlert, {
        [styles.active]: nodeWithErrors.id === state.currentNode?.id,
      })}
      onClick={selectAndCenterNode}
    >
      <div className={styles.alertContent}>
        <div className={styles.alertHeader}>
          <Icon icon={nodeWithErrors.data.getDisplayInfo().icon} prefix='fas' />
          <span>
            {nodeWithErrors.data.code ||
              nodeWithErrors.data.getDisplayInfo().name}
          </span>
        </div>
        <div>
          {nodeWithErrors.data.errors.hasDuplicatedIdentifier && (
            <div>{nodeWithErrors.data.errors.duplicatedIdentifierError}</div>
          )}
          {nodeWithErrors.data.errors.fieldErrors?.map(
            (errorMessage, index) => <div key={index}>{errorMessage}</div>
          )}
        </div>
      </div>
      <Icon icon='arrow-right' className={styles.locateNodeButton} />
    </li>
  )
}
