import { ToastFeedbackProvider } from '@hubtype/ui-react-web'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { useEffect, useState } from 'react'

import styles from './flow-builder-app.module.css'
import { FlowBuilderService } from './repository/hubtype/flow-builder-service'
import { RepositoryProvider } from './repository/repository-utils'
import { AnalyticsProvider, getAnalyticsWriteKey } from './UI/analytics'
import { HtLoadingScreen } from './UI/components/base'
import FlowBuilder from './UI/flow-builder'
import { Container } from './UI/flow-styles'
import {
  getFlowBuilderSettings,
  postFlowBuilderInitializedMessage,
} from './UI/hubtype-events'
import { RealtimeProvider } from './UI/realtime'
import { useFlowBuilder } from './UI/reducer/hooks'
import { FlowBuilderProvider } from './UI/reducer/provider'
import { InteractionMode, LoadingMessage } from './UI/types'

export const FlowBuilderApp = (): JSX.Element => {
  const [botId, setBotId] = useState<string>()

  const store = useFlowBuilder()
  const [analytics] = useState(() =>
    AnalyticsBrowser.load({ writeKey: getAnalyticsWriteKey() })
  )

  useEffect(() => {
    store.setLoadingMessage(LoadingMessage.LoadingApp)
    const hasAuthToken = getSettingsFromURL()
    if (!hasAuthToken) {
      postFlowBuilderInitializedMessage()
      window.addEventListener('message', getSettingsFromPostMessage)
    }
  }, [])

  useEffect(() => {
    const setHubtypeUser = async () => {
      if (!store.state.authToken) return
      const userInfo = await FlowBuilderService.getUserInfo(
        store.state.authToken
      )
      if (!userInfo) return
      setBotId(userInfo.botId)
      store.setHubtypeUser(userInfo.user)
    }
    setHubtypeUser()
  }, [store.state.authToken])

  const getSettingsFromURL = (): boolean => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const settings = Object.fromEntries(urlSearchParams)
    if (!settings.authToken) return false
    setInteractionMode(settings.isViewOnly === 'true')
    store.setAuthToken(settings.authToken)
    return true
  }

  const getSettingsFromPostMessage = (evt: MessageEvent) => {
    const data = getFlowBuilderSettings(evt)
    if (!data?.authToken) return
    store.setAuthToken(data.authToken)
    setInteractionMode(data.isViewOnly)
    window.removeEventListener('message', getSettingsFromPostMessage)
  }

  const setInteractionMode = (isViewOnly: boolean) => {
    const interactionMode = isViewOnly
      ? InteractionMode.View
      : InteractionMode.Edit
    store.setInteractionMode(interactionMode)
    analytics.track('fb_access', { action: interactionMode })
  }

  return (
    <Container height={'100vh'}>
      <RealtimeProvider authToken={store.state.authToken}>
        <ToastFeedbackProvider className={styles.flowBuilderToastRegion}>
          <AnalyticsProvider analytics={analytics}>
            {store.state.loadingMessage && (
              <HtLoadingScreen loadingMessage={store.state.loadingMessage} />
            )}
            {store.state.hubtypeUser && botId && (
              <RepositoryProvider>
                <FlowBuilderProvider store={store}>
                  <FlowBuilder botId={botId} />
                </FlowBuilderProvider>
              </RepositoryProvider>
            )}
          </AnalyticsProvider>
        </ToastFeedbackProvider>
      </RealtimeProvider>
    </Container>
  )
}
