import { ReactElement, useRef } from 'react'
import { AriaTabListOptions, useTabList } from 'react-aria'

import { useTabListStateContext } from './context'
import InnerTab from './inner-tab'
import styles from './tabs.module.css'

interface TabListProps<T extends object> extends AriaTabListOptions<T> {
  children: ReactElement | ReactElement[]
}

export function TabList<T extends object>({ ...props }: TabListProps<T>) {
  const state = useTabListStateContext()
  const ref = useRef<HTMLDivElement>(null)
  const { tabListProps } = useTabList(props, state, ref)

  return (
    <div {...tabListProps} ref={ref} className={styles.tabList}>
      {[...state.collection].map(item => (
        <InnerTab key={item.key} item={item} />
      ))}
    </div>
  )
}

export default TabList
