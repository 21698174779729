import { EdgeSelectionChange } from 'reactflow'

import { State } from '../../../types'
import { ActionType } from '../../action-types'

export interface SelectEdgesInterface {
  type: ActionType.SelectEdges
  changes: EdgeSelectionChange[]
}

export class SelectEdgeAction {
  static apply = (state: State, { changes }: SelectEdgesInterface): void => {
    const changeMap = new Map(changes.map(change => [change.id, change]))
    const isSelecting = changes.some(change => change.selected)
    state.nodes = state.nodes.map(node => {
      if (isSelecting) node.selected = false
      const edge = node.data.edges.find(edge => changeMap.has(edge.id))
      if (edge) {
        const change = changeMap.get(edge.id)
        edge.selected = change?.selected ?? edge.selected
      }
      return node
    })
    state.currentNode = undefined
  }
}
