import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { KeywordNodeProps } from '../../types'

const KeywordNode = ({ data, selected }: KeywordNodeProps) => {
  return (
    <Node type='input' isSelected={selected} nodeContent={data}>
      {({ NodeBadgeList }) =>
        data.keywords.length > 0 && <NodeBadgeList items={data.keywords} />
      }
    </Node>
  )
}

export default memo(KeywordNode)
