import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('HubtypeOrganizationSettingsPusher')
export class HubtypeOrganizationSettingsPusher {
  @JsonProperty('key', String)
  key = ''

  @JsonProperty('cluster', String, true)
  cluster = ''

  @JsonProperty('channel_prefix', String, true)
  channelPrefix = ''

  @JsonProperty('status_channel', String, true)
  statusChannel = ''
}

@JsonObject('HubtypeOrganizationSettings')
export class HubtypeOrganizationSettings {
  @JsonProperty('default_country_phone_code', String, true)
  defaultCountryPhoneCode: string | null = null

  @JsonProperty('translation_client', String, true)
  translationClient = ''

  @JsonProperty('organization_id_to_use_anonimized_analytics', String, true)
  organizationIdToUseAnonimizedAnalytics: string | null = null

  @JsonProperty('pusher', HubtypeOrganizationSettingsPusher)
  pusher: HubtypeOrganizationSettingsPusher =
    new HubtypeOrganizationSettingsPusher()
}
