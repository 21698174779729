import {
  activeEditor$,
  cancelLinkEdit$,
  ClickLinkCallback,
  editorRootElementRef$,
  linkDialogState$,
  onWindowChange$,
  removeLink$,
  switchFromPreviewToLinkEdit$,
  updateLink$,
} from '@mdxeditor/editor'
import { Cell, useCellValues, usePublisher } from '@mdxeditor/gurx'
import React, { useEffect } from 'react'
import { useOverlayTriggerState } from 'react-stately'

import { Popover, PopoverPosition } from '../popover/popover'
import { LinkEditForm } from './link-dialog-edit'
import { LinkPreview } from './link-dialog-preview'

export const onClickLinkCallback$ = Cell<ClickLinkCallback | null>(null)

interface LinkDialogProps {
  triggerRef: React.RefObject<HTMLElement>
}

export const LinkDialog = ({ triggerRef }: LinkDialogProps) => {
  const [
    editorRootElementRef,
    activeEditor,
    linkDialogState,
    onClickLinkCallback,
  ] = useCellValues(
    editorRootElementRef$,
    activeEditor$,
    linkDialogState$,
    onClickLinkCallback$
  )
  const publishWindowChange = usePublisher(onWindowChange$)
  const updateLink = usePublisher(updateLink$)
  const cancelLinkEdit = usePublisher(cancelLinkEdit$)
  const switchFromPreviewToLinkEdit = usePublisher(switchFromPreviewToLinkEdit$)
  const removeLink = usePublisher(removeLink$)

  useEffect(() => {
    const update = () => {
      activeEditor?.getEditorState().read(() => {
        publishWindowChange(true)
      })
    }

    window.addEventListener('resize', update)
    window.addEventListener('scroll', update)

    return () => {
      window.removeEventListener('resize', update)
      window.removeEventListener('scroll', update)
    }
  }, [activeEditor, publishWindowChange])

  const state = useOverlayTriggerState({})
  const theRect = linkDialogState.rectangle
  const positionTriggerRef = triggerRef.current?.getBoundingClientRect()

  const position: PopoverPosition = {
    position: 'fixed',
    top: `${(theRect?.top ?? 0) + 20}px`,
    left: `${(positionTriggerRef?.left ?? 0) - (linkDialogState.type === 'edit' ? 150 : 0)}px`,
  }

  if (!editorRootElementRef) return null

  return (
    linkDialogState.type !== 'inactive' && (
      <Popover triggerRef={triggerRef} state={state} position={position}>
        <>
          {linkDialogState.type === 'edit' && (
            <LinkEditForm
              url={linkDialogState.url}
              title={linkDialogState.title}
              onSubmit={updateLink}
              onCancel={cancelLinkEdit.bind(null)}
            />
          )}

          {linkDialogState.type === 'preview' && (
            <LinkPreview
              url={linkDialogState.url}
              onClickLinkCallback={onClickLinkCallback}
              switchFromPreviewToLinkEdit={switchFromPreviewToLinkEdit}
              removeLink={removeLink}
            />
          )}
        </>
      </Popover>
    )
  )
}
