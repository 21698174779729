import { useState } from 'react'

import { IconName } from '../icon/icon'
import { IconPrefix } from '../icon/icon'
import IconButton from '../icon-button/icon-button'
import { PopoverTrigger } from '../popover/popover-trigger'
import EmojiPicker, { BaseEmoji, EmojiPickerProps } from './emoji-picker'
import styles from './emoji-picker.module.css'

interface EmojiPickerTriggerProps
  extends Omit<EmojiPickerProps, 'onEmojiSelect'> {
  /** Ref of the input or textarea */
  inputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  /** Handler that is called when the value changes. */
  onChange?: (value: string) => void
  /** Size of the emoji picker trigger */
  size?: 'small' | 'medium' | 'large'
  /** Icon of the emoji picker trigger */
  icon?: IconName
  /** Icon prefix of the emoji picker trigger */
  iconPrefix?: IconPrefix
  /** Width of the emoji picker trigger */
  width?: string
  /** Whether the emoji picker trigger is disabled */
  isDisabled?: boolean
}

/** An emoji picker is a component that allows users to select an emoji from a list. */
export function EmojiPickerTrigger({
  size = 'small',
  icon = 'face-smile',
  iconPrefix = 'far',
  width = '275px',
  isDisabled,
  ...props
}: EmojiPickerTriggerProps) {
  const [isOpen, setIsOpen] = useState(false)

  const onEmojiSelect = (emoji: BaseEmoji) => {
    if (!props.inputRef?.current) {
      setIsOpen(false)
      props.onChange?.(emoji.native)
      return
    }

    const { textBeforeCursor, textAfterCursor } = getTextBeforeAndAfterCursor()
    const newValue = textBeforeCursor + emoji.native + textAfterCursor
    setIsOpen(false)
    props.onChange?.(newValue)
    props.inputRef.current?.focus()
  }

  const getTextBeforeAndAfterCursor = () => {
    const cursor =
      props.inputRef?.current?.selectionStart || props.value?.length || 0
    const textBeforeCursor = props.value?.slice(0, cursor) || ''
    const textAfterCursor = props.value?.slice(cursor) || ''
    return { textBeforeCursor, textAfterCursor }
  }

  return (
    <PopoverTrigger
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      width={width}
      trigger={
        <IconButton
          icon={icon}
          prefix={iconPrefix}
          size={size}
          shape='rounded'
          isDisabled={isDisabled}
          className={styles.triggerButton}
        />
      }
    >
      <EmojiPicker {...props} onEmojiSelect={onEmojiSelect} />
    </PopoverTrigger>
  )
}

export default EmojiPickerTrigger
