import { ActionType } from '../../action-types'
import {
  AddLocalesAction,
  AddLocalesHistoryChange,
  AddLocalesInterface,
} from './add-locales'
import {
  RemoveLocalesAction,
  RemoveLocalesHistoryChange,
  RemoveLocalesInterface,
} from './remove-locales'
import {
  SelectLocaleAction,
  SelectLocaleHistoryChange,
  SelectLocaleInterface,
} from './select-locale'
import { SetLocalesAction, SetLocalesInterface } from './set-locales'

export {
  AddLocalesAction,
  RemoveLocalesAction,
  SelectLocaleAction,
  SetLocalesAction,
}

export type LocaleActionInterface =
  | AddLocalesInterface
  | RemoveLocalesInterface
  | SelectLocaleInterface
  | SetLocalesInterface

export type LocaleChangesHistory =
  | AddLocalesHistoryChange
  | RemoveLocalesHistoryChange
  | SelectLocaleHistoryChange

export const localeActionMap = {
  [ActionType.AddLocales]: AddLocalesAction,
  [ActionType.RemoveLocales]: RemoveLocalesAction,
  [ActionType.SelectLocale]: SelectLocaleAction,
  [ActionType.SetLocales]: SetLocalesAction,
}
