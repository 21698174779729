import { JsonObject, JsonProperty } from 'json2typescript'

export enum BotonicPackageNames {
  Core = '@botonic/core',
  React = '@botonic/react',
  PluginFlowBuilder = '@botonic/plugin-flow-builder',
  PluginKnowledgeBases = '@botonic/plugin-knowledge-bases',
  PluginHubtypeAnalytics = '@botonic/plugin-hubtype-analytics',
}

@JsonObject('HubtypeBotConfigBuildInfo')
class HubtypeBotConfigBuildInfo {
  @JsonProperty('botonic_cli_version', String, true)
  public botonicCliVersion = ''

  @JsonProperty('node_version', String, true)
  public nodeVersion = ''

  @JsonProperty('npm_version', String, true)
  public npmVersion = ''
}

@JsonObject('HubtypeBotConfig')
export class HubtypeBotConfig {
  @JsonProperty('build_info', HubtypeBotConfigBuildInfo, true)
  public buildInfo = new HubtypeBotConfigBuildInfo()

  @JsonProperty('packages', Object, true)
  public packages: Record<string, { version: string }> = {}
}
