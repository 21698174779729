/* eslint-disable @typescript-eslint/no-empty-function */
import { Edge } from 'reactflow'

import {
  ContentType,
  SubContentType,
  TopContentFieldsBase,
} from '../../../domain/models/content-fields'
import { VariableFormat } from '..'

export class ConditionValueFields extends TopContentFieldsBase {
  constructor(public name = '') {
    super()
  }

  contentType(): SubContentType {
    return ContentType.CONDITION_VALUE
  }

  static fromHubtypeCMS(value: string): ConditionValueFields {
    const newConditionValue = new ConditionValueFields()
    newConditionValue.id = value
    newConditionValue.name = value
    return newConditionValue
  }

  toHubtypeCMS(): string {
    return this.id
  }

  getFormattedValue(
    variableFormat?: VariableFormat
  ): string | number | boolean {
    if (variableFormat === VariableFormat.BOOLEAN) {
      return this.name === 'true'
    }
    if (variableFormat === VariableFormat.NUMBER) {
      return Number(this.name) ?? this.name
    }
    return this.name
  }

  getErrors(variableFormat?: VariableFormat): string[] {
    const fieldErrors = []
    if (!this.name) fieldErrors.push('Value is missing')
    if (
      this.name &&
      variableFormat === VariableFormat.NUMBER &&
      isNaN(Number(this.name))
    ) {
      fieldErrors.push('Value must be a number')
    }
    return fieldErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.name], value)
  }

  getConnections(edges: Edge[]): Edge | undefined {
    return edges.find(e => e.sourceHandle === this.id)
  }

  static getCopy(content: ConditionValueFields): ConditionValueFields {
    const newWhatsappButtonListRow = new ConditionValueFields()
    TopContentFieldsBase.copyContent(content, newWhatsappButtonListRow)
    return newWhatsappButtonListRow
  }
}
