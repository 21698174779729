import { Dialog, Icon } from '@hubtype/ui-react-web'

import { TrackEventName, useAnalytics } from '../../analytics'
import { postCloseFromFlowBuilderMessage } from '../../hubtype-events'

export const ConnectionLostModal = (): JSX.Element => {
  const analytics = useAnalytics()

  const close = (): void => {
    analytics.trackEvent(TrackEventName.UserSessionEnded)
    postCloseFromFlowBuilderMessage(null)
  }

  return (
    <Dialog
      title='Disconnected: Connection lost'
      headerIcon={<Icon icon='exclamation-circle' size='medium' />}
      discardButton={{ children: 'Close' }}
      onClose={close}
    >
      Your session has ended due to a temporary connection issue.
    </Dialog>
  )
}
