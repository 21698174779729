import { Node } from '@hubtype/ui-react-web/flow-builder'
import { useEffect } from 'react'
import {
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
  useUpdateNodeInternals,
} from 'reactflow'

import { ConditionalContentType } from '../../domain/models/content-fields'
import { CustomConditionFields, VariableFormat } from './model'

export interface CustomConditionNodeInterface
  extends ReactFlowNode<CustomConditionFields> {
  data: CustomConditionFields
  type: ConditionalContentType.CUSTOM_CONDITION
}

export interface CustomConditionNodeProps
  extends ReactFlowNodeProps<CustomConditionFields> {
  data: CustomConditionFields
  type: ConditionalContentType.CUSTOM_CONDITION
}

export const CustomConditionNode = ({
  data,
  selected,
  id,
}: CustomConditionNodeProps) => {
  const updateNodeInternals = useUpdateNodeInternals()
  useEffect(() => updateNodeInternals(id), [data.values])

  return (
    <Node
      type='condition'
      isSelected={selected}
      nodeContent={data}
      hasHeaderDivider={true}
    >
      {({ NodeCondition, NodeSection, NodeGroup }) => (
        <>
          <NodeSection
            direction='horizontal'
            title='If'
            name={data.conditionVariable}
          />
          <NodeGroup>
            {data.values.map((value, i) => (
              <NodeCondition
                key={i}
                id={`${id}-${value.id}`}
                isConnectionRequired={
                  data.variableFormat === VariableFormat.BOOLEAN
                }
              >
                <NodeSection
                  direction='horizontal'
                  title='is'
                  name={value.name}
                />
              </NodeCondition>
            ))}
            {data.variableFormat !== VariableFormat.BOOLEAN && (
              <NodeCondition id={id + '-default'} isConnectionRequired={true}>
                Otherwise
              </NodeCondition>
            )}
          </NodeGroup>
        </>
      )}
    </Node>
  )
}
