import { useRef } from 'react'
import { AriaTabPanelProps, useTabPanel } from 'react-aria'

import { useTabListStateContext } from './context'
import styles from './tabs.module.css'

export interface TabPanelProps extends AriaTabPanelProps {
  children: React.ReactNode
}

export function TabPanel({ ...props }: TabPanelProps) {
  const state = useTabListStateContext()
  const ref = useRef<HTMLDivElement>(null)
  const { tabPanelProps } = useTabPanel(props, state, ref)

  if (props.id !== state.selectedKey) return null

  return (
    <div {...tabPanelProps} ref={ref} className={styles.tabPanel}>
      {props.children}
    </div>
  )
}

export default TabPanel
