import { cva } from 'class-variance-authority'
import { RefObject, useCallback } from 'react'

import styles from './field.module.css'
import { FieldFooter, FooterProps } from './footer'
import { FieldLabel, LabelProps } from './label'

export const fieldClasses = cva(styles.base, {
  variants: {
    status: {
      disabled: styles.disabled,
      error: styles.error,
      readOnly: styles.readOnly,
    },
    size: {
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
  },
})

export interface FieldProps extends LabelProps, FooterProps {
  /** Ref to the input element. */
  fieldRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>
  /** The children to render. */
  children: React.ReactNode
  /** Whether the input is disabled. */
  isDisabled?: boolean
  /** Whether the input can be selected but not changed by the user. */
  isReadOnly?: boolean
  /** Whether the input is missing. */
  isMissing?: boolean
  /** Whether the element should receive focus on render. */
  autoFocus?: boolean
  /** Temporary text that occupies the text input when it is empty. */
  placeholder?: string
  /** The name of the input element, used when submitting an HTML form */
  name?: string
  /** The size of the field. */
  size?: 'small' | 'medium' | 'large'
}

export function Field({ size = 'medium', ...props }: FieldProps) {
  const getStatus = useCallback(() => {
    if (props.isDisabled) return 'disabled'
    if (props.isReadOnly) return 'readOnly'
    if (props.isInvalid) return 'error'
  }, [props.isDisabled, props.isReadOnly, props.isInvalid])

  return (
    <div className={fieldClasses({ status: getStatus(), size })}>
      <FieldLabel {...props} />
      {props.children}
      <FieldFooter {...props} length={props.fieldRef?.current?.value.length} />
    </div>
  )
}

export default Field
