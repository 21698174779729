import Icon from '../../../icon/icon'
import Tooltip from '../../../tooltip/tooltip'
import styles from './widgets.module.css'

interface NodeCodeProps {
  code?: string
  isCodeHidden?: boolean
  errors: string[]
}

export const NodeCode = ({
  code,
  errors,
  isCodeHidden,
}: NodeCodeProps): JSX.Element => {
  const formatErrorContent = (errors: string[]) => {
    if (errors.length <= 2) {
      return (
        <>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </>
      )
    }
    return (
      <>
        {errors.slice(0, 2).map((error, index) => (
          <div key={index}>{error}</div>
        ))}
        <div>+{errors.length - 2} more</div>
      </>
    )
  }

  return (
    <div className={styles.code}>
      {!isCodeHidden && <div className={styles.codeText}>{code}</div>}
      {errors.length > 0 && (
        <Tooltip
          content={formatErrorContent(errors)}
          placement='top left'
          offset={16}
        >
          <span>
            <Icon
              icon='exclamation-triangle'
              size='medium'
              prefix='fad'
              className={styles.errorIcon}
            />
          </span>
        </Tooltip>
      )}
    </div>
  )
}
