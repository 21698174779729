import { memo } from 'react'

import { ImageFields } from '../../../../../domain/models/content-fields'
import { TextBodyBold } from '../../../base'
import { IMAGE } from '../../constants'
import { ContentImageField } from '../../editor-widgets/image-loader-widget'
import { EditorProps } from '../../node-editor-panel'
import { UniqueIdentifierField } from '../../unique-identifier/unique-identifier-widget'

interface ImageEditorProps extends EditorProps {
  nodeContent: ImageFields
}

export const ImageEditor = memo(
  ({ nodeContent, ...props }: ImageEditorProps): JSX.Element => {
    return (
      <>
        <TextBodyBold>Image</TextBodyBold>
        <UniqueIdentifierField
          {...props}
          {...nodeContent}
          value={nodeContent.code}
        />
        <ContentImageField
          {...props}
          field={IMAGE}
          image={nodeContent.image?.assetUrl}
        />
      </>
    )
  }
)
