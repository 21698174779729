import { ActionType } from '../../action-types'
import {
  AddWebviewAction,
  AddWebviewHistoryChange,
  AddWebviewInterface,
} from './add-webview'
import {
  RemoveWebviewAction,
  RemoveWebviewHistoryChange,
  RemoveWebviewInterface,
} from './remove-webview'
import {
  SelectWebviewAction,
  SelectWebviewHistoryChange,
  SelectWebviewInterface,
} from './select-webview'
import { SetWebviewsAction, SetWebviewsInterface } from './set-webviews'

export {
  AddWebviewAction,
  RemoveWebviewAction,
  SelectWebviewAction,
  SetWebviewsAction,
}

export type WebviewActionInterface =
  | AddWebviewInterface
  | RemoveWebviewInterface
  | SelectWebviewInterface
  | SetWebviewsInterface

export type WebviewChangesHistory =
  | AddWebviewHistoryChange
  | RemoveWebviewHistoryChange
  | SelectWebviewHistoryChange

export const webviewActionMap = {
  [ActionType.AddWebview]: AddWebviewAction,
  [ActionType.RemoveWebview]: RemoveWebviewAction,
  [ActionType.SetWebviews]: SetWebviewsAction,
  [ActionType.SelectWebview]: SelectWebviewAction,
}
