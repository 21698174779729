import { cx } from 'class-variance-authority'

import Icon, { IconName } from '../../../icon/icon'
import styles from './widgets.module.css'

interface NodeHeaderProps {
  iconComponent?: React.ReactNode
  isWhatsapp?: boolean
  name: string
  icon: IconName
  hasHeaderDivider?: boolean
}

export const NodeHeader = ({
  iconComponent,
  isWhatsapp,
  name,
  icon,
  hasHeaderDivider,
}: NodeHeaderProps): JSX.Element => {
  return (
    <div className={cx(styles.header)}>
      <div className={cx(styles.headerContent)}>
        <div>{name}</div>
        <div className={styles.headerIcons}>
          {isWhatsapp && <Icon icon='whatsapp' size='large' prefix='fab' />}
          {!iconComponent && <Icon icon={icon} size='large' />}
          {iconComponent}
        </div>
      </div>
      {hasHeaderDivider && <hr />}
    </div>
  )
}
