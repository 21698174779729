import { cx } from 'class-variance-authority'
import { useRef } from 'react'
import {
  mergeProps,
  useFocusRing,
  useSliderThumb,
  VisuallyHidden,
} from 'react-aria'

import { useSliderStateContext } from '../context'
import styles from '../slider.module.css'

interface ThumbProps {
  label?: string
  index: number
  trackRef: React.RefObject<HTMLDivElement>
}

export const Thumb = ({ label, index, trackRef }: ThumbProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const state = useSliderStateContext()
  const { thumbProps, inputProps, isDragging } = useSliderThumb(
    { index, trackRef, inputRef, 'aria-label': label },
    state
  )
  const { focusProps, isFocusVisible } = useFocusRing()

  return (
    <div
      {...thumbProps}
      className={cx(styles.thumb, {
        [styles.dragging]: isDragging,
      })}
      data-focus-visible={isFocusVisible || undefined}
    >
      <VisuallyHidden>
        <input {...mergeProps(inputProps, focusProps)} ref={inputRef} />
      </VisuallyHidden>
    </div>
  )
}
