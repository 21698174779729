import { Button, Icon, IconButton } from '@hubtype/ui-react-web'

import { getDateTime } from '../../../utils/date-utils'
import { TrackEventName, useAnalytics } from '../../analytics'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { InteractionMode } from '../../types'
import { VersionHistoryData } from '../header/version-history-dropdown/version-history-dropdown'
import { ModalType } from '../modal/modal-types'
import styles from './version-banner.module.css'

interface VersionBannerProps {
  restoreDraftFlow: () => Promise<void>
}

export const VersionBanner = ({ restoreDraftFlow }: VersionBannerProps) => {
  const analytics = useAnalytics()
  const setModalContent = useFlowBuilderSelector(ctx => ctx.setModalContent)
  const { currentVersion, interactionMode, loadingMessage } =
    useFlowBuilderSelector(ctx => ctx.state)

  if (
    loadingMessage ||
    (!currentVersion && interactionMode === InteractionMode.Edit)
  )
    return null

  const getTitle = (version: VersionHistoryData) => {
    const dateTitle = `Published on ${getDateTime(version.createdAt)}`
    if (!version.user) return dateTitle
    return `${dateTitle} (${version.user.getUserName()})`
  }

  const restoreVersion = () => {
    analytics.trackEvent(TrackEventName.VersionClickRestoreCta)
    setModalContent({ type: ModalType.RestoreVersion })
  }

  const onCancel = () => {
    analytics.trackEvent(TrackEventName.VersionClickClose)
    restoreDraftFlow()
  }

  return (
    <div className={styles.bannerContainer}>
      {interactionMode === InteractionMode.View && (
        <div className={styles.bannerSection}>
          <Icon icon='eye' />
          View only
        </div>
      )}
      {currentVersion && (
        <div className={styles.bannerSection}>
          {getTitle(currentVersion)}
          {interactionMode === InteractionMode.Edit && (
            <Button size='small' onPress={restoreVersion}>
              Restore to this version
            </Button>
          )}
          <IconButton
            className={styles.closeButton}
            icon='xmark'
            size='small'
            onPress={onCancel}
          />
        </div>
      )}
    </div>
  )
}
