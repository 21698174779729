import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import Button from '../button/button'
import TextInput from '../text-input/text-input'
import styles from './text-area-markdown.module.css'

const HTTP_URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)[^\s]*$/

interface LinkEditFormProps {
  url: string
  title: string
  onSubmit: (link: LinkFormFields) => void
  onCancel: () => void
}

interface LinkFormFields {
  url: string
  title: string
}

export function LinkEditForm({
  url,
  title,
  onSubmit,
  onCancel,
}: LinkEditFormProps) {
  const form = useForm<LinkFormFields>({
    values: {
      url,
      title,
    },
  })

  return (
    <form
      onSubmit={e => {
        e.stopPropagation()
        e.preventDefault()
        void form.handleSubmit(onSubmit)(e)
      }}
      onReset={e => {
        e.stopPropagation()
        onCancel()
      }}
      className={styles.linkDialogEditContainer}
    >
      <Controller
        name='url'
        control={form.control}
        rules={{
          required: { value: true, message: 'This is required' },
          pattern: {
            value: HTTP_URL_REGEX,
            message: 'Invalid URL format',
          },
        }}
        render={({ field }) => (
          <TextInput
            label={'Select or paste an URL'}
            {...field}
            autoFocus={true}
            isInvalid={!!form.formState.errors.url}
            errorMessage={form.formState.errors.url?.message}
          />
        )}
      />

      <div>
        <Button type='reset' intent='secondary' appearance='outline'>
          Cancel
        </Button>

        <Button type='submit'>Save</Button>
      </div>
    </form>
  )
}
