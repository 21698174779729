import { Banner, Checkbox, Dialog } from '@hubtype/ui-react-web'
import { Controller, UseFormReturn } from 'react-hook-form'

import { useFlowBuilderSelector } from '../../../../../reducer/hooks'
import { TextBodyBig, TextBodySmallBold, TextSmall } from '../../../../base'
import { TransferDetails } from '../transfer-flows-modal'
import { StyledTransferSummary } from '../transfer-flows-styles'

interface SummaryStepProps {
  form: UseFormReturn<TransferDetails>
  goBack: () => void
  transfer: () => void
}

export const SummaryStep = ({
  form,
  goBack,
  transfer,
}: SummaryStepProps): JSX.Element => {
  const { state } = useFlowBuilderSelector(ctx => ctx)

  return (
    <Dialog
      title='Transfer flows'
      confirmButton={{
        children: 'Transfer',
        intent: 'danger',
        onPress: transfer,
      }}
      discardButton={{ children: 'Back', onPress: goBack }}
    >
      <StyledTransferSummary>
        <TextBodySmallBold>Transfer to</TextBodySmallBold>
        <TextBodyBig>{form.getValues('targetBot')?.name}</TextBodyBig>
        <TextSmall>
          Transferring {state.flows.length} Flows, {state.locales.length}{' '}
          Languages
        </TextSmall>
      </StyledTransferSummary>
      <Banner
        intent='warning'
        description='This action will replace destination bot’s flow. This action can’t be undone.'
      />
      <form id='source-type-form'>
        <Controller
          name='openTargetBotOnTransfer'
          control={form.control}
          render={({ field }) => (
            <Checkbox
              {...field}
              isSelected={field.value}
              label='Open the destination bot after transferring the flow.'
            />
          )}
        />
      </form>
    </Dialog>
  )
}
