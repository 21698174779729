import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import {
  CHECK_AVAILABLE_AGENTS,
  QUEUE_CLOSED,
  QUEUE_OPEN,
  QUEUE_OPEN_WITHOUT_AGENTS,
} from '../../../UI/constants'
import { IdMapping, OrganizationContents } from '../../../UI/types'
import {
  HtArgumentType,
  HtFunctionArgumentLocale,
  HtFunctionNode,
} from '../cms/hubtype/function'
import { HtContentType } from '../cms/hubtype/node-types'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import { flatten } from '../visitors'
import { ContentType, TopContentFieldsBase } from '.'
import { CONDITIONAL_FUNCTIONS, QueueLocale } from './common'
import { ConditionalContentType } from './content-types'
import { QueueFields } from './queue'

export class QueueStatusFields extends TopContentFieldsBase {
  public queue?: QueueFields
  public queueLocales: QueueLocale[] = []
  public checkAvailableAgents = false

  contentType(): ConditionalContentType {
    return ContentType.QUEUE_STATUS
  }

  static getDisplayInfo() {
    return { name: 'Queue status', icon: 'split' }
  }

  getDisplayInfo() {
    return QueueStatusFields.getDisplayInfo()
  }

  setValuesByLocale(locale: string): void {
    this.queue = LocaleManager.getQueueByLocale(this.queueLocales, locale)
  }

  setContentByLocale(locale: LocaleCode): void {
    this.queueLocales = LocaleManager.setQueueByLocale(
      this.queueLocales,
      this.queue,
      locale
    )
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.queueLocales, newLocale, copyFrom)
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.queueLocales, localesToRemove)
  }

  static fromHubtypeCMS(
    component: HtFunctionNode,
    locale: string,
    organizationContents: OrganizationContents
  ): QueueStatusFields {
    const newQueueStatus = new QueueStatusFields()
    this.setHubtypeCmsCommonData(newQueueStatus, component)

    const hubtypeQueues = flatten(
      organizationContents.projects.map(project => project.queues)
    )
    component.content.arguments.forEach(argument => {
      if ('locale' in argument) {
        const queue = hubtypeQueues.find(
          q => q.id === argument.values[0].value.toString()
        )
        if (queue)
          newQueueStatus.queueLocales.push({
            id: queue.id,
            projectName: queue.projectName,
            name: queue.name,
            locale: argument.locale,
            isInvalid: false,
          })
        else
          newQueueStatus.queueLocales.push({
            id: argument.values[0].value.toString(),
            projectName: '',
            name: argument.values[1].value.toString(),
            locale: argument.locale,
            isInvalid: true,
          })
      } else if (argument.name === CHECK_AVAILABLE_AGENTS) {
        newQueueStatus.checkAvailableAgents = argument.value as boolean
      }
    })
    newQueueStatus.setValuesByLocale(locale)
    return newQueueStatus
  }

  toHubtypeCMS(position: XYPosition, locale: LocaleCode): HtFunctionNode {
    this.setContentByLocale(locale)
    const queueArguments: HtFunctionArgumentLocale[] = this.queueLocales.map(
      queue => {
        return {
          locale: queue.locale,
          values: [
            {
              type: HtArgumentType.STRING,
              name: 'queue_id',
              value: queue.id,
            },
            {
              type: HtArgumentType.STRING,
              name: 'queue_name',
              value: queue.name,
            },
          ],
        } as HtFunctionArgumentLocale
      }
    )

    const node: HtFunctionNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.FUNCTION,
      content: {
        action: CONDITIONAL_FUNCTIONS.CHECK_QUEUE_STATUS,
        arguments: [
          ...queueArguments,
          {
            type: HtArgumentType.BOOLEAN,
            name: CHECK_AVAILABLE_AGENTS,
            value: this.checkAvailableAgents,
          },
        ],
        result_mapping: [],
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('Content ID')
    if (!this.queue) requiredFields.push('Queue')
    if (!this.hasEdgeWithHandle('open')) requiredFields.push('Connection')
    if (!this.hasEdgeWithHandle('closed')) requiredFields.push('Connection')
    if (
      this.checkAvailableAgents &&
      !this.hasEdgeWithHandle('open-without-agents')
    ) {
      requiredFields.push('Connection')
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    if (this.queue?.isInvalid) {
      fieldErrors.push(`${this.queue?.name} queue no longer exists`)
    }
    this.errors.fieldErrors = this.aggregateErrors(fieldErrors)
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors =
      LocaleManager.getLocalesWithEmptyOrInvalidQueue(
        this.queueLocales,
        locales
      )
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString(
      [this.code, this.queue?.name, this.queue?.projectName],
      value
    )
  }

  getConnections(edges: Edge[]): NodeEdges {
    const openEdge = edges.find(
      e => e.sourceHandle === `${this.id}-${QUEUE_OPEN}`
    )
    const closedEdge = edges.find(
      e => e.sourceHandle === `${this.id}-${QUEUE_CLOSED}`
    )
    const openNoAvailableEdge = edges.find(
      e => e.sourceHandle === `${this.id}-${QUEUE_OPEN_WITHOUT_AGENTS}`
    )
    return { buttons: [openEdge, closedEdge, openNoAvailableEdge] }
  }

  static getCopy(content: QueueStatusFields): QueueStatusFields {
    const newQueueStatus = new QueueStatusFields()
    TopContentFieldsBase.copyContent(content, newQueueStatus)
    if (content.queue) {
      newQueueStatus.queue = QueueFields.getCopy(content.queue)
    }
    return newQueueStatus
  }

  getIdMappingForOldFields(oldQueueStatus: QueueStatusFields): IdMapping {
    return {
      [`${oldQueueStatus.id}-open`]: `${this.id}-open`,
      [`${oldQueueStatus.id}-closed`]: `${this.id}-closed`,
      [`${oldQueueStatus.id}-open-without-agents`]: `${this.id}-open-without-agents`,
    }
  }
}
