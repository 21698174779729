import { Item } from 'react-stately'

export interface TabProps {
  children: React.ReactNode
  id: string
  href?: string
}

export function Tab({ id, ...props }: TabProps) {
  return <Item key={id} {...props} />
}

export default Tab
