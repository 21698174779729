import { Checkbox, Dialog } from '@hubtype/ui-react-web'
import { useState } from 'react'

import { useAnalytics } from '../../../analytics'
import { TrackEventName } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { ModalType } from '../../modal/modal-types'
import { HIDE_CONTENT_ID_EDIT_WARNING } from './unique-identifier-widget'

export interface UniqueIdentifierModalProps {
  type: ModalType.EditUniqueIdentifier
  isSmartIntentTitle: boolean
  isAiGenerated: boolean
  enableEdit: () => void
}

export const UniqueIdentifierModal = ({
  isSmartIntentTitle,
  isAiGenerated,
  enableEdit,
}: UniqueIdentifierModalProps): JSX.Element => {
  const analytics = useAnalytics()
  const { setModalContent } = useFlowBuilderSelector(ctx => ctx)
  const [isHideModalChecked, setIsHideModalChecked] = useState(false)

  const onEdit = (): void => {
    if (isHideModalChecked) {
      sessionStorage.setItem(HIDE_CONTENT_ID_EDIT_WARNING, 'true')
    }
    setModalContent()
    enableEdit()
  }

  const closeModal = (hasEdited?: boolean): void => {
    analytics.trackEvent(
      isSmartIntentTitle
        ? TrackEventName.SmartIntentTitleEdit
        : TrackEventName.ContentIdEdit,
      {
        action: hasEdited ? 'edit_anyway' : 'cancel',
        was_ai_generated: isAiGenerated || false,
      }
    )
  }

  return (
    <Dialog
      title={isSmartIntentTitle ? 'Edit smart intent title' : 'Edit content ID'}
      confirmButton={{ children: 'Edit anyway', onPress: onEdit }}
      onClose={closeModal}
    >
      Editing a {isSmartIntentTitle ? 'smart intent title' : 'content ID'} may
      result in data duplicates or other misinterpretations in logs and reports.
      <Checkbox
        label="Don't show this message again."
        isSelected={isHideModalChecked}
        onChange={setIsHideModalChecked}
      />
    </Dialog>
  )
}
