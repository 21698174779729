import { DragEvent } from 'react'
import { NodeChange, NodeRemoveChange } from 'reactflow'

import { ContentType, NodeContentType } from '../domain/models/content-fields'
import { CustomConditionNode } from '../nodes/custom-conditional/node'
import { KnowledgeBaseNode } from '../nodes/knowledge-base'
import { SmartIntentNode } from '../nodes/smart-intent'
import { WhatsappCTAUrlButtonNode } from '../nodes/whatsapp-cta-url-button'
import BotActionNode from './components/nodes/bot-action-node'
import CarouselNode from './components/nodes/carousel-node'
import ChannelNode from './components/nodes/channel-node'
import CountryConditionNode from './components/nodes/country-condition-node'
import FallbackNode from './components/nodes/fallback-node'
import GoToFlowNode from './components/nodes/go-to-flow'
import HandoffNode from './components/nodes/handoff-node'
import ImageNode from './components/nodes/image-node'
import KeywordNode from './components/nodes/keyword-node'
import QueueStatusNode from './components/nodes/queue-status-node'
import StartNode from './components/nodes/start-node/start-node'
import TextNode from './components/nodes/text-node'
import VideoNode from './components/nodes/video-node'
import WhatsappButtonListNode from './components/nodes/whatsapp-list-node'
import { APPLICATION_REACTFLOW } from './constants'
import { NodeTypes } from './types'

export const nodeTypes = {
  [ContentType.TEXT]: TextNode,
  [ContentType.CAROUSEL]: CarouselNode,
  [ContentType.IMAGE]: ImageNode,
  [ContentType.VIDEO]: VideoNode,
  [ContentType.HANDOFF]: HandoffNode,
  [ContentType.KEYWORD]: KeywordNode,
  [ContentType.QUEUE_STATUS]: QueueStatusNode,
  [ContentType.CHANNEL]: ChannelNode,
  [ContentType.FALLBACK]: FallbackNode,
  [ContentType.WHATSAPP_BUTTON_LIST]: WhatsappButtonListNode,
  [ContentType.GO_TO_FLOW]: GoToFlowNode,
  [ContentType.BOT_ACTION]: BotActionNode,
  [ContentType.START]: StartNode,
  [ContentType.COUNTRY_CONDITION]: CountryConditionNode,
  [ContentType.CUSTOM_CONDITION]: CustomConditionNode,
  [ContentType.SMART_INTENT]: SmartIntentNode,
  [ContentType.WHATSAPP_CTA_URL_BUTTON]: WhatsappCTAUrlButtonNode,
  [ContentType.KNOWLEDGE_BASE]: KnowledgeBaseNode,
}

export const onDragStart = (
  event: DragEvent,
  contentType: NodeContentType
): void => {
  event.dataTransfer.setData(APPLICATION_REACTFLOW, contentType)
  event.dataTransfer.effectAllowed = 'move'
}

export const getContentType = (event: DragEvent): ContentType => {
  return event.dataTransfer.getData(APPLICATION_REACTFLOW) as ContentType
}

export const onDragOver = (event: DragEvent): void => {
  event.preventDefault()
  event.dataTransfer.dropEffect = 'move'
}

export const getNodesToRemove = (
  changes: NodeChange[],
  nodes: NodeTypes[]
): NodeTypes[] | undefined => {
  const removeChanges = changes.filter(
    c => c.type === 'remove'
  ) as NodeRemoveChange[]
  if (removeChanges.length === 0) return undefined
  return nodes.filter(node => removeChanges.some(c => c.id === node.id))
}

export const getNonRemoveChanges = (changes: NodeChange[]): NodeChange[] => {
  return changes.filter(c => c.type !== 'remove' && c.type !== 'dimensions')
}
