import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { isWebview } from '../../../webviews/utils'
import { ImageNodeProps } from '../../types'
import styles from './node.module.css'

const ImageNode = ({ data, selected }: ImageNodeProps) => {
  return (
    <Node
      type='message'
      isSelected={selected}
      nodeContent={data}
      isWebview={isWebview(data.flowId)}
    >
      {() =>
        data.image?.assetUrl && (
          <img
            alt={data.code}
            className={styles.image}
            src={data.image.assetUrl}
          />
        )
      }
    </Node>
  )
}

export default memo(ImageNode)
