import { AriaToastRegionProps, useToastRegion } from '@react-aria/toast'
import { cx } from 'class-variance-authority'
import { useRef } from 'react'

import { useToastFeedback } from './context'
import Toast from './toast'
import styles from './toast.module.css'

export type ToastRegionProps = AriaToastRegionProps & {
  /** Optional class name to apply to the toast region */
  className?: string
}

export function ToastRegion({ className, ...props }: ToastRegionProps) {
  const ref = useRef<HTMLDivElement>(null)
  const state = useToastFeedback()
  const { regionProps } = useToastRegion(props, state, ref)

  return (
    <div
      {...regionProps}
      ref={ref}
      className={cx(styles.toastRegion, className)}
    >
      {state.visibleToasts.map(toast => (
        <Toast key={toast.key} toast={toast} />
      ))}
    </div>
  )
}

export default ToastRegion
