import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { ButtonStyle } from '../../../domain/models/content-fields'
import { isWebview } from '../../../webviews/utils'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { TextNodeProps } from '../../types'
import { MarkdownText } from './markdown/markdown'

const TextNode = ({ data, selected }: TextNodeProps): JSX.Element => {
  const updateNode = useFlowBuilderSelector(ctx => ctx.updateNode)
  const selectButton = (id: string) => {
    data.selectedSubContentId = id
    updateNode(data)
  }
  return (
    <Node
      type='message'
      isSelected={selected}
      nodeContent={data}
      isWebview={isWebview(data.flowId)}
    >
      {({ NodeButton }) =>
        (data.text || data.buttons.length !== 0) && (
          <>
            <MarkdownText isSelected={selected}>{data.text}</MarkdownText>
            {data.buttons.length > 0 &&
              data.buttons.map((b, i) => (
                <NodeButton
                  onClick={() => selectButton(b.id)}
                  key={i}
                  button={b}
                  isReply={data.buttonsStyle === ButtonStyle.QUICK_REPLY}
                />
              ))}
          </>
        )
      }
    </Node>
  )
}

export default memo(TextNode)
