import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo, useEffect, useState } from 'react'

import { useFlowBuilderSelector } from '../../reducer/hooks'
import { BotActionNodeProps } from '../../types'

const BotActionNode = ({ data, selected }: BotActionNodeProps) => {
  const statePayloads = useFlowBuilderSelector(
    ctx => ctx.state.nonMessageContents.payloads
  )
  const [payload, setPayload] = useState<string | undefined>(undefined)

  useEffect(() => setPayload(getPayload(data.payloadId)), [data.payloadId])

  const getPayload = (payloadId?: string): string | undefined => {
    return statePayloads.find(statePayload => statePayload.id === payloadId)
      ?.name
  }

  return (
    <Node type='message' isSelected={selected} nodeContent={data}>
      {({ NodeGroup, NodeSection }) => (
        <NodeGroup>
          <NodeSection title='Payload' name={payload} />
        </NodeGroup>
      )}
    </Node>
  )
}

export default memo(BotActionNode)
