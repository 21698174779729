import Badge from '../../../badge/badge'
import { NodeGroup } from './group'

export interface NodeBadgeListProps {
  items: string[]
  isSelected?: boolean
}

export const NodeBadgeList = ({ items, isSelected }: NodeBadgeListProps) => {
  if (items.length === 0) return null
  const remainingItems = items.length - 3

  return (
    <NodeGroup>
      {items.slice(0, 3).map((item, index) => (
        <Badge key={index} type={isSelected ? 'primary' : 'neutral'}>
          {item}
        </Badge>
      ))}
      {remainingItems > 0 && <p>+{remainingItems} more</p>}
    </NodeGroup>
  )
}
