import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { CountryConditionNodeProps } from '../../types'

const CountryConditionNode = ({
  data,
  selected,
  id,
}: CountryConditionNodeProps) => {
  return (
    <Node type='condition' isSelected={selected} nodeContent={data}>
      {({ NodeCondition, NodeGroup }) =>
        data.countries.length > 0 && (
          <NodeGroup>
            {data.countries.map((country, i) => (
              <NodeCondition key={i} id={`${id}-${country.id}`}>
                {country.name}
              </NodeCondition>
            ))}
            <NodeCondition id={`${id}-default`} isConnectionRequired={true}>
              Other
            </NodeCondition>
          </NodeGroup>
        )
      }
    </Node>
  )
}

export default memo(CountryConditionNode)
