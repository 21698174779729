import { DateConverter } from '@hubtype/util-shared'
import { JsonObject, JsonProperty } from 'json2typescript'

import { HubtypeExtractionJob } from './extraction-job'

export enum SourceType {
  File = 'file',
  Url = 'url',
}

@JsonObject('HubtypeKnowledgeSource')
export class HubtypeKnowledgeSource {
  @JsonProperty('id', String)
  id = ''

  @JsonProperty('name', String)
  name = ''

  @JsonProperty('type', String)
  type: SourceType = SourceType.Url

  // TODO: serialize directly to Country
  @JsonProperty('scraping_country_code', String, true)
  scrapingCountryCode = ''

  @JsonProperty('active_extraction_job', HubtypeExtractionJob)
  activeExtractionJob?: HubtypeExtractionJob | null = null

  @JsonProperty('last_extraction_job', HubtypeExtractionJob)
  lastExtractionJob: HubtypeExtractionJob | null = null

  @JsonProperty('created_by', String, true)
  createdBy = ''

  @JsonProperty('created_at', DateConverter)
  createdAt: Date = new Date()

  @JsonProperty('last_updated_by', String)
  lastUpdatedBy = ''

  @JsonProperty('last_updated_at', DateConverter)
  lastUpdatedAt: Date = new Date()

  public get fileType(): string | undefined {
    return this.type === SourceType.Url
      ? SourceType.Url
      : this.activeExtractionJob?.fileType
  }
}
