import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo } from 'react'

import { WhatsappButtonListSectionFields } from '../../../domain/models/content-fields'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { WhatsappButtonListNodeProps } from '../../types'
import { MarkdownText } from './markdown/markdown'

const WhatsappButtonListNode = ({
  data,
  selected,
}: WhatsappButtonListNodeProps): JSX.Element => {
  const updateNode = useFlowBuilderSelector(ctx => ctx.updateNode)

  const selectRow = (id: string) => {
    data.selectedSubContentId = id
    updateNode(data)
  }

  const getSectionTitle = (
    section: WhatsappButtonListSectionFields,
    index: number
  ) => {
    let sectionTitle = section.title
    if (!sectionTitle && data.sections.length > 1) {
      sectionTitle = `Section ${index + 1} title`
    }
    if (!sectionTitle) return undefined
    return <p>{sectionTitle}</p>
  }

  return (
    <Node
      type='message'
      isSelected={selected}
      isWhatsapp={true}
      nodeContent={data}
      hasHeaderDivider={true}
    >
      {({ NodeButton, NodeCondition, NodeGroup }) => (
        <>
          <MarkdownText isSelected={selected}>
            {data.text || 'Message text to introduce button list'}
          </MarkdownText>
          <NodeButton
            hasHandler={false}
            button={{ text: data.listButtonText }}
          />
          <NodeGroup>
            {data.sections.map((section, sIndex) =>
              section.rows.map((row, rIndex) => (
                <NodeCondition
                  key={row.id}
                  id={row.id}
                  handleType={rIndex === 0 ? 'section' : undefined}
                  onClick={() => selectRow(row.id)}
                  isConnectionRequired={true}
                >
                  <div>
                    {rIndex === 0 && getSectionTitle(section, sIndex)}
                    <strong>{row.title || 'Row title'}</strong>
                  </div>
                </NodeCondition>
              ))
            )}
          </NodeGroup>
        </>
      )}
    </Node>
  )
}

export default memo(WhatsappButtonListNode)
