import { Chip, TextInput, Tooltip } from '@hubtype/ui-react-web'
import { memo, useState } from 'react'

import { InputAction } from '../../constants'
import { FullWidthContainer, KeywordsContainer } from '../../editor-styles'
import { EditorProps } from '../../node-editor-panel'

interface KeywordsFieldProps extends EditorProps {
  field: InputAction
  values: string[]
}

export const KeywordsField = memo((props: KeywordsFieldProps): JSX.Element => {
  const [currentValue, setCurrentValue] = useState('')

  const MAX_KEYWORD_CHAR_LENGTH = 25

  const addItem = () => {
    if (!currentValue || props.values.includes(currentValue)) return
    props.onChange({
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: [...props.values, currentValue],
    })
    setCurrentValue('')
  }

  const deleteItem = (item: string) => {
    props.onChange({
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: props.values.filter(v => v !== item),
    })
  }

  return (
    <FullWidthContainer>
      <TextInput
        label={props.field.label}
        placeholder={props.field.placeholder}
        value={currentValue}
        isReadOnly={props.isReadOnly}
        isMissing={props.showMissingFields && !props.values.length}
        onChange={setCurrentValue}
        onKeyDown={e => {
          if (e.code === 'Enter') addItem()
        }}
        description={props.field.helperText}
      />
      <KeywordsContainer>
        {props.values.map((item, index) => (
          <Tooltip
            key={index}
            content={item}
            placement='left'
            isDisabled={item.length < MAX_KEYWORD_CHAR_LENGTH}
          >
            <div>
              <Chip
                key={index}
                intent='secondary'
                isDisabled={props.isReadOnly}
                onClose={() => deleteItem(item)}
              >
                {item}
              </Chip>
            </div>
          </Tooltip>
        ))}
      </KeywordsContainer>
    </FullWidthContainer>
  )
})
