import { cva } from 'class-variance-authority'
import { forwardRef, KeyboardEvent } from 'react'
import { useObjectRef, useTextField } from 'react-aria'

import Field, { FieldProps } from '../field/field'
import styles from './text-input.module.css'

const inputClasses = cva(styles.input, {
  variants: {
    size: {
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
    isMissing: {
      true: styles.missing,
    },
  },
})

export interface TextInputProps
  extends Omit<
    FieldProps,
    'labelProps' | 'errorMessageProps' | 'descriptionProps' | 'children'
  > {
  /** The current value (controlled). */
  value?: string
  /** The default value (uncontrolled). */
  defaultValue?: string
  /** Whether the textarea includes a resize handle. */
  hasResizeHandle?: boolean
  /** Handler that is called when the value changes. */
  onChange?: (value: string) => void
  /** Handler that is called when a key is pressed. */
  onKeyDown?: (evt: KeyboardEvent) => void
  /** The type of input to render. */
  type?: 'text' | 'search' | 'url' | 'tel' | 'email' | 'password' | 'number'
}

/** An input field includes a label and a text field users can type text into. They typically appear in forms and dialogs. */
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ size = 'medium', ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef)
    const { inputProps, ...fieldProps } = useTextField(
      { ...props, maxLength: undefined },
      ref
    )

    return (
      <Field {...props} {...fieldProps} fieldRef={ref} size={size}>
        <input
          {...inputProps}
          ref={ref}
          placeholder={props.placeholder || props.label}
          aria-label={props.label}
          className={inputClasses({
            size,
            isMissing: props.isMissing && !props.value,
          })}
        />
      </Field>
    )
  }
)

export default TextInput
