import { State } from '../../../types'
import { ActionType } from '../../action-types'
import { NodeAction } from './node-action'

export interface SetSelectedNodesInterface {
  type: ActionType.SetSelectedNodes
  nodeIds: string[]
}

export class SetSelectedNodesAction {
  static apply = (
    state: State,
    { nodeIds }: SetSelectedNodesInterface
  ): void => {
    NodeAction.setSelectedNodes(state, nodeIds)
  }
}
