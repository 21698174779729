import { Modal } from '@hubtype/ui-react-web'

import { ConnectionLostModal } from '../../one-user/modals/connection-lost'
import { SessionConflictModal } from '../../one-user/modals/session-conflict'
import { SessionEndedModal } from '../../one-user/modals/session-ended'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { AddFlowModal } from '../flow-selector/modals/add-flow-modal'
import { RemoveFlowModal } from '../flow-selector/modals/remove-flow-modal'
import { EditFlowModal } from '../flow-selector/modals/rename-flow-modal'
import { KnowledgeBaseModal } from '../header/ai-settings/modals/knowledge-base-modal'
import { PublishModal } from '../header/publish/publish-modal'
import { TransferFlowsModal } from '../header/transfer-flows/transfer-flows-modal/transfer-flows-modal'
import { RemoveLocalesModal } from '../locale-panel/remove-locale-modal'
import { EditUrlModal } from '../node-editor-panel/editor-widgets/url-widget/modals/edit-url-modal'
import { RemoveUrlModal } from '../node-editor-panel/editor-widgets/url-widget/modals/remove-url-modal'
import { RemovePayloadModal } from '../node-editor-panel/editors/bot-action-editor/remove-payload-modal'
import { UniqueIdentifierModal } from '../node-editor-panel/unique-identifier/unique-identifier-modal'
import { RestoreVersionModal } from '../version-banner/restore-version-modal'
import { ModalType, NON_DISMISSABLE_MODAL_TYPES } from './modal-types'

const MODAL_COMPONENTS_MAP = {
  [ModalType.AddFlow]: AddFlowModal,
  [ModalType.EditFlow]: EditFlowModal,
  [ModalType.RemoveFlow]: RemoveFlowModal,
  [ModalType.EditUrl]: EditUrlModal,
  [ModalType.RemoveUrl]: RemoveUrlModal,
  [ModalType.RemovePayload]: RemovePayloadModal,
  [ModalType.EditUniqueIdentifier]: UniqueIdentifierModal,
  [ModalType.ConnectionLost]: ConnectionLostModal,
  [ModalType.SessionConflict]: SessionConflictModal,
  [ModalType.SessionEnded]: SessionEndedModal,
  [ModalType.RemoveLocales]: RemoveLocalesModal,
  [ModalType.KnowledgeBase]: KnowledgeBaseModal,
  [ModalType.Publish]: PublishModal,
  [ModalType.RestoreVersion]: RestoreVersionModal,
  [ModalType.TransferFlows]: TransferFlowsModal,
}

export const FlowBuilderModal = (): JSX.Element | null => {
  const { state, setModalContent } = useFlowBuilderSelector(ctx => ctx)

  if (!state.modalContent) return null

  const Component = MODAL_COMPONENTS_MAP[state.modalContent.type]

  if (!Component) {
    throw new Error('Modal not implemented')
  }

  return (
    <Modal
      isOpen={true}
      onOpenChange={isOpen => !isOpen && setModalContent()}
      isKeyboardDismissDisabled={NON_DISMISSABLE_MODAL_TYPES.includes(
        state.modalContent.type
      )}
    >
      <Component {...(state.modalContent as any)} />
    </Modal>
  )
}
