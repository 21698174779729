import { Edge, XYPosition } from 'reactflow'

import { HtContentType } from '../../domain/models/cms/hubtype/node-types'
import {
  ContentType,
  InputContentType,
  TopContentFieldsBase,
} from '../../domain/models/content-fields'
import { NodeEdges } from '../../UI/components/edges/edge-utils'
import { toSnakeCase } from '../../utils/string-utils'
import { HtSmartIntentNode } from './ht-model'

export class SmartIntentFields extends TopContentFieldsBase {
  constructor(
    public title = '',
    public description = ''
  ) {
    super()
    this.errors.duplicatedIdentifierError = 'Title is duplicated'
  }

  contentType(): InputContentType {
    return ContentType.SMART_INTENT
  }

  static getDisplayInfo() {
    return { name: 'Smart intent', icon: 'brain' }
  }

  getDisplayInfo() {
    return SmartIntentFields.getDisplayInfo()
  }

  getIdentifier(): string {
    return this.title
  }

  static fromHubtypeCMS(component: HtSmartIntentNode): SmartIntentFields {
    const newIntent = new SmartIntentFields()
    this.setHubtypeCmsCommonData(newIntent, component)
    newIntent.title = this.formatTitle(component.content.title)
    newIntent.description = component.content.description
    return newIntent
  }

  static formatTitle(title: string): string {
    return toSnakeCase(title.toLowerCase()).replace(/^_+|_+$/g, '')
  }

  toHubtypeCMS(position: XYPosition): HtSmartIntentNode {
    const node: HtSmartIntentNode = {
      ...this.getHubtypeCmsCommonData(position),
      code: '',
      type: HtContentType.SMART_INTENT,
      content: {
        title: this.title,
        description: this.description,
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.title) requiredFields.push('Title')
    if (!this.description) requiredFields.push('Description')
    if (!this.hasEdgeWithHandle(this.id)) requiredFields.push('Connection')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.title, this.description], value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    return { followUp: this.getFollowUp(edges) }
  }

  static getCopy(content: SmartIntentFields): SmartIntentFields {
    const newIntents = new SmartIntentFields()
    TopContentFieldsBase.copyContent(content, newIntents)
    newIntents.title = ''
    return newIntents
  }
}
