import { Dialog } from '@hubtype/ui-react-web'

import { TrackEventName, useAnalytics } from '../../../../analytics'
import { ADDONS_DETAIL_URL_PATH } from '../../../../constants'
import { postCloseFromFlowBuilderMessage } from '../../../../hubtype-events'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'

export const KnowledgeBaseAddonsModal = (): JSX.Element => {
  const analytics = useAnalytics()

  const isSaved = useFlowBuilderSelector(ctx => ctx.state.isFlowSaved)

  const goToHubtypeKnowledgeBaseAddonModal = async (): Promise<void> => {
    await analytics.trackEvent(TrackEventName.AddOns, {
      origin: 'Knowledge base',
    })
    const closeData = `${ADDONS_DETAIL_URL_PATH}/knowledge-bases`
    if (isSaved) {
      postCloseFromFlowBuilderMessage(closeData)
      return
    }
  }

  return (
    <Dialog
      title='Knowledge base'
      confirmButton={{
        children: 'More info',
        onPress: goToHubtypeKnowledgeBaseAddonModal,
      }}
    >
      Leverage on GenAI to generate more human responses to your user inquiries
      with the information you already have about your company.
    </Dialog>
  )
}
