import { Flow, NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { NodeAction } from '../node-actions/node-action'
import { RemoveNodesAction } from '../node-actions/remove-nodes'
import { ReversibleAction } from '../reversible-action'
import { FlowAction } from './flow-action'

export interface RemoveFlowInterface {
  type: ActionType.RemoveFlow
  flowToRemove: Flow
}

export interface RemoveFlowHistoryChange {
  type: ActionType.RemoveFlow
  removedFlow: Flow
  removedNodes: NodeTypes[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class RemoveFlowAction extends ReversibleAction {
  static apply = (
    state: State,
    { flowToRemove }: RemoveFlowInterface
  ): void => {
    const { currentNode, currentFlowId } = state
    const nodesToRemove = state.nodes.filter(
      node => node.data.flowId === flowToRemove.id
    )
    RemoveNodesAction.removeNodes(state, nodesToRemove)
    FlowAction.removeFlow(state, flowToRemove)
    this.trackHistoryChange(
      state,
      flowToRemove,
      nodesToRemove,
      currentFlowId,
      currentNode
    )
  }

  static undo = (state: State, change: RemoveFlowHistoryChange) => {
    FlowAction.addFlow(state, change.removedFlow)
    NodeAction.addNodes(state, change.removedNodes)
    NodeAction.setSelectedNodes(state, [])
  }

  static redo = (state: State, change: RemoveFlowHistoryChange) => {
    FlowAction.removeFlow(state, change.removedFlow)
    RemoveNodesAction.removeNodes(state, change.removedNodes)
  }

  private static trackHistoryChange = (
    state: State,
    removedFlow: Flow,
    removedNodes: NodeTypes[],
    currentFlowId: string,
    currentNode?: NodeTypes
  ) => {
    const newChange: RemoveFlowHistoryChange = {
      type: ActionType.RemoveFlow,
      removedFlow,
      removedNodes,
      currentNode,
      currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }
}
