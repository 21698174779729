import { Icon, Tooltip } from '@hubtype/ui-react-web'
import { getIconPropsFromFileType } from '@hubtype/util-shared'
import { memo } from 'react'

import { TextBodySmall } from '../../../UI/components/base'
import { KNOWLEDGE_SOURCES } from '../../../UI/components/node-editor-panel/constants'
import { StyledIconWithText } from '../../../UI/components/node-editor-panel/editor-styles'
import { SortableDropdownWidgetField } from '../../../UI/components/node-editor-panel/editor-widgets/sortable-dropdown-widget'
import { EditorProps } from '../../../UI/components/node-editor-panel/node-editor-panel'
import { useFlowBuilderSelector } from '../../../UI/reducer/hooks'
import { KnowledgeBaseFields, KnowledgeSourceWithValidation } from '../model'

export interface TabSourcesProps extends EditorProps {
  nodeContent: KnowledgeBaseFields
}

export const TabSources = memo(
  ({ nodeContent, ...props }: TabSourcesProps): JSX.Element => {
    const { organizationContents } = useFlowBuilderSelector(ctx => ctx.state)

    return (
      <SortableDropdownWidgetField<KnowledgeSourceWithValidation>
        {...props}
        isLabelHidden={true}
        nodeContent={nodeContent}
        contentType={nodeContent.contentType()}
        applyButtonText='Add sources'
        field={KNOWLEDGE_SOURCES}
        values={nodeContent.sources.filter(source => !source.isInvalid)}
        sortableValues={nodeContent.sources}
        availableItems={organizationContents.knowledgeSources}
        isDragDisabled={true}
        searchPlaceholder='Search sources'
        header={item => {
          return (
            <Tooltip
              content='This source no longer exists'
              isDisabled={!item.isInvalid}
            >
              <StyledIconWithText $hasError={item.isInvalid}>
                <Icon {...getIconPropsFromFileType(item.fileType)} />
                <TextBodySmall>{item.name}</TextBodySmall>
              </StyledIconWithText>
            </Tooltip>
          )
        }}
      />
    )
  }
)
