import '@mdxeditor/editor/style.css'

import {
  BoldItalicUnderlineToggles,
  CreateLink,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  toolbarPlugin,
} from '@mdxeditor/editor'
import { cx } from 'class-variance-authority'
import { RefObject, useCallback, useEffect } from 'react'
import { useRef } from 'react'

import { fieldClasses, FieldProps } from '../field/field'
import { FieldFooter } from '../field/footer'
import { FieldLabel } from '../field/label'
import Icon from '../icon/icon'
import { LinkDialog } from './link-dialog'
import styles from './text-area-markdown.module.css'
import { useFocusTextAreaMarkdown } from './use-focus-text-area-markdown'

const BREAK_LINE_ENTITY = '\n'

export interface TextAreaMarkdownProps
  extends Omit<FieldProps, 'fieldRef' | 'children' | 'name' | 'ref'> {
  /** Reference to the MDX editor instance for controlling the editor programmatically */
  mdxEditorRef: RefObject<MDXEditorMethods>
  /** The markdown content value of the text area */
  value: string
  /** Callback function triggered when the content changes */
  onChange: (value: string) => void
  /** Enable bullet and numbered lists functionality in the editor */
  hasLists?: boolean
  /** Enable link creation and management functionality in the editor */
  hasLink?: boolean
  /** Enable variable support in the editor (if implemented) */
  hasVariables?: boolean
}

/** A field that allows users to write markdown in text. Text area markdown includes a label and a field that users can type into. */
export const TextAreaMarkdown = ({
  size = 'medium',
  hasLists,
  hasLink,
  hasVariables,
  mdxEditorRef,
  autoFocus,
  ...props
}: TextAreaMarkdownProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { isFocused, setIsFocused } = useFocusTextAreaMarkdown(containerRef)

  const getStatus = useCallback(() => {
    if (props.isDisabled) return 'disabled'
    if (props.isReadOnly) return 'readOnly'
    if (props.isInvalid) return 'error'
  }, [props.isDisabled, props.isReadOnly, props.isInvalid])

  const getPlugins = () => {
    const plugins = []
    if (hasLists) {
      plugins.push(listsPlugin())
    }
    if (hasLink) {
      plugins.push(linkPlugin({ disableAutoLink: true }))
      plugins.push(
        linkDialogPlugin({
          LinkDialog: () => <LinkDialog triggerRef={containerRef} />,
        })
      )
    }
    plugins.push(markdownShortcutPlugin())
    plugins.push(
      toolbarPlugin({
        toolbarClassName: styles.toolbarMarkdown,
        toolbarContents: () => (
          <>
            <BoldItalicUnderlineToggles options={['Bold', 'Italic']} />
            {hasLists && <ListsToggle options={['bullet', 'number']} />}
            {hasLink && <CreateLink />}
          </>
        ),
      })
    )
    return plugins
  }

  const getIconComponentFor = (name: string) => {
    const iconMap = {
      format_bold: <Icon icon='bold' />,
      format_italic: <Icon icon='italic' />,
      format_list_bulleted: <Icon icon='list-ul' />,
      format_list_numbered: <Icon icon='list-ol' />,
      link: <Icon icon='link' />,
      open_in_new: <Icon icon='arrow-up-right-from-square' />,
      edit: <Icon icon='edit' />,
      content_copy: <Icon prefix='far' icon='copy' />,
      link_off: <Icon icon='trash-can' />,
    }

    return iconMap[name as keyof typeof iconMap] || null
  }

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        setIsFocused(true)
        mdxEditorRef.current?.focus()
      }, 0)
    }
  }, [])

  const lengthWithoutBreakLines = props.value.replaceAll(
    BREAK_LINE_ENTITY,
    ''
  ).length

  return (
    <div className={fieldClasses({ status: getStatus(), size })}>
      <FieldLabel {...props} />
      <div
        ref={containerRef}
        className={cx(styles.textAreaContainer, {
          [styles.missing]: props.isMissing,
        })}
        aria-readonly={props.isReadOnly}
        aria-invalid={props.isInvalid}
        aria-disabled={props.isDisabled}
        data-focus-visible={isFocused}
      >
        <MDXEditor
          ref={mdxEditorRef}
          readOnly={props.isReadOnly || props.isDisabled}
          className={styles.textAreaMarkdown}
          placeholder={props.placeholder || props.label}
          markdown={props.value}
          onChange={props.onChange}
          plugins={getPlugins()}
          toMarkdownOptions={{ emphasis: '_', strong: '*' }}
          iconComponentFor={getIconComponentFor}
        />
      </div>
      <FieldFooter {...props} length={lengthWithoutBreakLines} />
    </div>
  )
}

export default TextAreaMarkdown
