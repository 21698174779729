import {
  HubtypeBot,
  HubtypeBotConfig,
  HubtypeUser,
} from '@hubtype/data-access-models'
import { DragEvent, useMemo, useReducer } from 'react'
import { Connection, EdgeSelectionChange, ReactFlowInstance } from 'reactflow'
import { useContextSelector } from 'use-context-selector'

import {
  PayloadFields,
  TopContentFields,
  UrlFields,
} from '../../domain/models/content-fields'
import { Locale, NewLocale } from '../../domain/models/locales/locale'
import { SUPPORTED_LOCALES } from '../../domain/models/locales/supported-locales'
import { VersionHistoryData } from '../components/header/version-history-dropdown/version-history-dropdown'
import { ModalContent } from '../components/modal/modal-types'
import { FALLBACK_FLOW, MAIN_FLOW } from '../constants'
import {
  ComputedPreviousFlow,
  Flow,
  InteractionMode,
  LoadingMessage,
  NodeTypes,
  NonMessageContents,
  OrganizationContents,
  State,
  Webview,
} from '../types'
import { ActionType } from './action-types'
import { FlowBuilderContext, FlowBuilderContextProps } from './context'
import { reducer } from './reducer'

export const initialState: State = {
  nodes: [],
  isLocalesPanelOpen: false,
  locales: [SUPPORTED_LOCALES.english],
  nonMessageContents: { urls: [], payloads: [] },
  organizationContents: {
    projects: [],
    featureFlags: {},
    knowledgeSources: [],
    knowledgeBaseDefaultInstructions: '',
    bots: [],
    currentBot: new HubtypeBot(),
  },
  botVariables: [],
  authToken: '',
  isReadOnly: false,
  currentLocale: SUPPORTED_LOCALES.english,
  isKnowledgeBaseActive: false,
  isFlowSaved: true,
  changeHistory: [],
  historyIndex: -1,
  currentFlowId: MAIN_FLOW.id,
  flows: [MAIN_FLOW, FALLBACK_FLOW],
  computedPreviousFlows: [
    { id: MAIN_FLOW.id, previousFlows: [], showPreviousFlows: false },
    { id: FALLBACK_FLOW.id, previousFlows: [], showPreviousFlows: false },
  ],
  webviews: [],
  hashPublished: '',
  hash: '',
  isOffline: false,
  versionHistoryData: [],
  interactionMode: InteractionMode.Edit,
}

export const useFlowBuilderSelector = <T>(
  selector: (context: FlowBuilderContextProps) => T
): T => {
  return useContextSelector(FlowBuilderContext, selector)
}

export function useFlowBuilder(): FlowBuilderContextProps {
  const [state, dispatch] = useReducer(reducer, initialState)

  const selectEdges = (changes: EdgeSelectionChange[]) => {
    dispatch({ type: ActionType.SelectEdges, changes })
  }

  const connectNodes = (connection: Connection) => {
    dispatch({ type: ActionType.ConnectNodes, connection })
  }

  const copyElements = (nodesToCopy: NodeTypes[], event: ClipboardEvent) => {
    dispatch({ type: ActionType.CopyElements, nodesToCopy, event })
  }

  const cutElements = (nodesToCut: NodeTypes[], event: ClipboardEvent) => {
    dispatch({ type: ActionType.CutElements, nodesToCut, event })
  }

  const nodeDragStart = (nodes: NodeTypes[]) =>
    dispatch({ type: ActionType.NodeDragStart, nodes })

  const nodeDragStop = (nodesToReposition: NodeTypes[]) =>
    dispatch({ type: ActionType.NodeDragStop, nodesToReposition })

  const nodeDrop = (event: DragEvent) =>
    dispatch({ type: ActionType.NodeDrop, event })

  const pasteElements = (event: ClipboardEvent) => {
    dispatch({ type: ActionType.PasteElements, event })
  }

  const removeEdgesById = (ids: string[]) => {
    dispatch({ type: ActionType.RemoveEdgesById, ids })
  }

  const removeNodes = (nodesToRemove: NodeTypes[]) => {
    dispatch({ type: ActionType.RemoveNodes, nodesToRemove })
  }

  const selectNode = (node: NodeTypes) => {
    dispatch({ type: ActionType.SelectNode, node })
  }

  const setOrganizationContents = (
    organizationContents: OrganizationContents
  ) => {
    dispatch({
      type: ActionType.SetOrganizationContents,
      organizationContents,
    })
  }

  const toggleInteractivity = (isInteractive: boolean) => {
    dispatch({ type: ActionType.ToggleInteractivity, isInteractive })
  }

  const selectLocale = (locale: Locale, isReversible?: boolean) => {
    dispatch({ type: ActionType.SelectLocale, locale, isReversible })
  }

  const toggleFlowSaved = (isFlowSaved: boolean) =>
    dispatch({ type: ActionType.ToggleFlowSaved, isFlowSaved })

  const setModalContent = (content?: ModalContent) =>
    dispatch({ type: ActionType.SetModalContent, content })

  const setReactFlowRefs = (
    reactFlowInstance: ReactFlowInstance,
    reactFlowWrapper: React.RefObject<HTMLDivElement>
  ) =>
    dispatch({
      type: ActionType.SetReactFlowRefs,
      reactFlowInstance,
      reactFlowWrapper,
    })

  const setAuthToken = (authToken: string) =>
    dispatch({ type: ActionType.SetAuthToken, authToken })

  const setLocales = (locales: Locale[]) => {
    dispatch({ type: ActionType.SetLocales, locales })
  }

  const addLocales = (newLocales: NewLocale[]) => {
    dispatch({ type: ActionType.AddLocales, newLocales })
  }

  const removeLocales = (localesToRemove: Locale[]) => {
    dispatch({ type: ActionType.RemoveLocales, localesToRemove })
  }

  const setBotVariables = (botVariables: string[]) => {
    dispatch({ type: ActionType.SetBotVariables, botVariables })
  }

  const addPayload = (newPayload: PayloadFields) => {
    dispatch({ type: ActionType.AddPayload, newPayload })
  }

  const removePayload = (payloadToRemove: PayloadFields) => {
    dispatch({ type: ActionType.RemovePayload, payloadToRemove })
  }

  const addUrl = (newUrl: UrlFields) => {
    dispatch({ type: ActionType.AddUrl, newUrl })
  }

  const removeUrl = (urlToRemove: UrlFields) => {
    dispatch({ type: ActionType.RemoveUrl, urlToRemove })
  }

  const editUrl = (urlToEdit: UrlFields, newName: string) => {
    dispatch({ type: ActionType.EditUrl, urlToEdit, newName })
  }

  const setSelectedNodes = (nodeIds: string[]) =>
    dispatch({ type: ActionType.SetSelectedNodes, nodeIds })

  const toggleLocalesPanel = (toggle: boolean) =>
    dispatch({ type: ActionType.ToggleLocalesPanel, toggle })

  const closeNodeEditor = () => dispatch({ type: ActionType.CloseNodeEditor })

  const updateAllContents = (
    nodes?: NodeTypes[],
    nonMessageContents?: NonMessageContents
  ) => {
    dispatch({
      type: ActionType.UpdateAllContents,
      nodes,
      nonMessageContents,
    })
  }

  const updateNode = (data: TopContentFields) => {
    dispatch({ type: ActionType.UpdateNode, data })
  }

  const setKnowledgeBaseActive = (
    isKnowledgeBaseActive: boolean,
    isReversible?: boolean
  ) => {
    dispatch({
      type: ActionType.SetKnowledgeBaseActive,
      isKnowledgeBaseActive,
      isReversible,
    })
  }

  const undo = () => {
    dispatch({ type: ActionType.Undo })
  }

  const redo = () => {
    dispatch({ type: ActionType.Redo })
  }

  const restoreChangeHistory = () => {
    dispatch({ type: ActionType.RestoreChangeHistory })
  }

  const selectFlow = (flowId: string, isReversible?: boolean) => {
    dispatch({ type: ActionType.SelectFlow, flowId, isReversible })
  }

  const setFlows = (flows: Flow[]) => {
    dispatch({ type: ActionType.SetFlows, flows })
  }

  const setLoadingMessage = (message?: LoadingMessage) => {
    dispatch({ type: ActionType.SetLoadingMessage, message })
  }

  const setCurrentVersion = (currentVersion?: VersionHistoryData) => {
    dispatch({ type: ActionType.SetCurrentVersion, currentVersion })
  }

  const toggleIsOffline = (isOffline: boolean) => {
    dispatch({ type: ActionType.ToggleIsOffline, isOffline })
  }

  const setComputedPreviousFlows = (
    computedPreviousFlows: ComputedPreviousFlow[]
  ) => {
    dispatch({
      type: ActionType.SetComputedPreviousFlows,
      computedPreviousFlows,
    })
  }

  const addFlow = (newFlow: Flow) => {
    dispatch({ type: ActionType.AddFlow, newFlow })
  }

  const removeFlow = (flowToRemove: Flow) => {
    dispatch({ type: ActionType.RemoveFlow, flowToRemove })
  }

  const updateAllNodes = (newNodes: NodeTypes[], isReversible?: boolean) => {
    dispatch({ type: ActionType.UpdateAllNodes, newNodes, isReversible })
  }

  const addWebview = (newWebview: Webview) => {
    dispatch({ type: ActionType.AddWebview, newWebview })
  }

  const removeWebview = (webviewToRemove: Webview) => {
    dispatch({ type: ActionType.RemoveWebview, webviewToRemove })
  }

  const setWebviews = (webviews: Webview[]) => {
    dispatch({ type: ActionType.SetWebviews, webviews })
  }

  const selectWebview = (webviewId: string) => {
    dispatch({ type: ActionType.SelectWebview, webviewId })
  }

  const setHashPublished = (hashPublished: string) => {
    dispatch({ type: ActionType.SetHashPublished, hashPublished })
  }

  const setHash = (hash: string) => {
    dispatch({ type: ActionType.SetHash, hash })
  }

  const setBotConfig = (botConfig: HubtypeBotConfig) => {
    dispatch({
      type: ActionType.SetBotConfig,
      botConfig,
    })
  }

  const setVersionHistoryData = (versionHistoryData: VersionHistoryData[]) => {
    dispatch({
      type: ActionType.SetVersionHistoryData,
      versionHistoryData,
    })
  }

  const setHubtypeUser = (user: HubtypeUser) => {
    dispatch({ type: ActionType.SetHubtypeUser, user })
  }

  const setInteractionMode = (mode: InteractionMode) => {
    dispatch({ type: ActionType.SetInteractionMode, mode })
  }

  return {
    state,
    ...useMemo(
      () => ({
        addFlow,
        addLocales,
        addPayload,
        addUrl,
        addWebview,
        closeNodeEditor,
        connectNodes,
        copyElements,
        cutElements,
        editUrl,
        nodeDragStart,
        nodeDragStop,
        nodeDrop,
        pasteElements,
        redo,
        removeEdgesById,
        removeFlow,
        removeLocales,
        removeNodes,
        removePayload,
        removeUrl,
        removeWebview,
        restoreChangeHistory,
        selectEdges,
        selectFlow,
        selectLocale,
        selectNode,
        selectWebview,
        setAuthToken,
        setBotConfig,
        setBotVariables,
        setComputedPreviousFlows,
        setCurrentVersion,
        setHubtypeUser,
        setInteractionMode,
        setFlows,
        setHash,
        setHashPublished,
        setKnowledgeBaseActive,
        setLoadingMessage,
        setLocales,
        setOrganizationContents,
        setModalContent,
        setReactFlowRefs,
        setSelectedNodes,
        setVersionHistoryData,
        setWebviews,
        toggleFlowSaved,
        toggleInteractivity,
        toggleIsOffline,
        toggleLocalesPanel,
        undo,
        updateAllContents,
        updateAllNodes,
        updateNode,
      }),
      []
    ),
  }
}
