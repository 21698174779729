import { cva, cx } from 'class-variance-authority'
import { ReactNode, useRef } from 'react'
import { useDialog } from 'react-aria'

import Button, { ButtonProps } from '../../button/button'
import IconButton from '../../icon-button/icon-button'
import { useModalTriggerContext } from '../context'
import styles from './dialog.module.css'

const dialogClasses = cva(styles.dialog, {
  variants: {
    hasHeaderDivider: {
      true: styles.hasHeaderDivider,
    },
    hasFooterDivider: {
      true: styles.hasFooterDivider,
    },
  },
})

export interface DialogProps {
  /** The title of the dialog. */
  title: string
  /** The icon to display in the header. */
  headerIcon?: ReactNode
  /** The content of the dialog. */
  children: ReactNode
  /** The confirm button props. */
  confirmButton?: ButtonProps
  /** The discard button props. */
  discardButton?: Partial<ButtonProps>
  /** The accessibility role for the dialog. */
  role?: 'dialog' | 'alertdialog'
  /** Whether to remove the discard button. */
  hasDiscardButton?: boolean
  /** Whether to remove the header divider. */
  hasHeaderDivider?: boolean
  /** Whether to remove the footer divider. */
  hasFooterDivider?: boolean
  /** The class name for the dialog. */
  className?: string
  /** Handler that is called when the dialog is closed. */
  onClose?: () => void
}

export function Dialog({
  hasDiscardButton = true,
  hasHeaderDivider = false,
  hasFooterDivider = false,
  ...props
}: DialogProps) {
  const ref = useRef<HTMLDivElement>(null)
  const state = useModalTriggerContext()
  if (!state) {
    throw new Error('ModalTriggerStateContext used outside of its Provider')
  }
  const { dialogProps, titleProps } = useDialog(props, ref)

  const close = () => {
    state.close()
    props.onClose?.()
  }

  return (
    <div
      {...dialogProps}
      ref={ref}
      className={cx(
        dialogClasses({ hasHeaderDivider, hasFooterDivider }),
        props.className
      )}
    >
      <div className={styles.header}>
        <div {...titleProps} className={styles.title}>
          {props.headerIcon}
          {props.title}
        </div>
        <IconButton size='small' icon='close' onPress={close} />
      </div>
      <div className={styles.body}>{props.children}</div>
      {(hasDiscardButton || props.confirmButton) && (
        <div className={styles.footer}>
          {hasDiscardButton && (
            <Button
              onPress={close}
              intent='secondary'
              appearance='outline'
              children='Cancel'
              {...props.discardButton}
            />
          )}
          {props.confirmButton && <Button {...props.confirmButton} />}
        </div>
      )}
    </div>
  )
}

export default Dialog
