import { Dialog } from '@hubtype/ui-react-web'

import { TrackEventName, useAnalytics } from '../../analytics'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { useRestoreVersion } from './use-restore-version'

export const RestoreVersionModal = (): JSX.Element => {
  const analytics = useAnalytics()
  const restoreVersion = useRestoreVersion()
  const setModalContent = useFlowBuilderSelector(ctx => ctx.setModalContent)

  const restorePreviousVersion = (): void => {
    analytics.trackEvent(TrackEventName.VersionClickRestoreConfirmationCta)
    setModalContent()
    restoreVersion()
  }

  const cancel = async (): Promise<void> => {
    await analytics.trackEvent(TrackEventName.VersionClickCancelCta)
  }

  return (
    <Dialog
      title='Restore version'
      confirmButton={{
        children: 'Restore version',
        intent: 'danger',
        onPress: restorePreviousVersion,
      }}
      onClose={cancel}
    >
      You're about to restore this conversational app to an older version. All
      changes made from there will be lost. Once loaded you can publish again
      the restored version.
    </Dialog>
  )
}
