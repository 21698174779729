import { Dialog } from '@hubtype/ui-react-web'

import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { Flow } from '../../../types'
import { TextBodySmall, TextBodySmallBold } from '../../base'
import { ModalType } from '../../modal/modal-types'

export interface RemoveFlowModalProps {
  type: ModalType.RemoveFlow
  flowToRemove: Flow
  linkedFlows: Flow[]
}

export const RemoveFlowModal = ({
  flowToRemove,
  linkedFlows,
}: RemoveFlowModalProps): JSX.Element => {
  const { setModalContent, removeFlow } = useFlowBuilderSelector(ctx => ctx)

  const onClickAccept = async (): Promise<void> => {
    setModalContent()
  }

  const onDeleteFlow = (): void => {
    removeFlow(flowToRemove)
    setModalContent()
  }

  if (linkedFlows.length)
    return (
      <Dialog
        title='Delete flow'
        confirmButton={{ children: 'Accept', onPress: onClickAccept }}
        hasDiscardButton={false}
      >
        <TextBodySmall>
          This flow can't be deleted as it is currently connected to other
          flows. Please remove those connections before deleting it.
        </TextBodySmall>
        <TextBodySmall>
          {`'${flowToRemove.name}' flow is currently being used in:`}
        </TextBodySmall>
        {linkedFlows.length && (
          <div>
            {linkedFlows.map(flow => (
              <TextBodySmallBold key={flow.id}>{flow.name}</TextBodySmallBold>
            ))}
          </div>
        )}
      </Dialog>
    )

  return (
    <Dialog
      title='Delete flow'
      confirmButton={{ children: 'Delete', onPress: onDeleteFlow }}
    >
      <TextBodySmall>
        Deleting '{flowToRemove.name}' flow will also delete all conversational
        elements in it. This action can be undone by using cmd+z/ctrl+z.
      </TextBodySmall>
    </Dialog>
  )
}
