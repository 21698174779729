import { ActionType } from '../../action-types'
import { CopyAction, CopyInterface } from './copy'
import { CutAction, CutHistoryChange, CutInterface } from './cut'
import {
  DropNodeAction,
  DropNodeHistoryChange,
  DropNodeInterface,
} from './drop-node'
import { NodeDragStartAction, NodeDragStartInterface } from './node-drag-start'
import { PasteAction, PasteHistoryChange, PasteInterface } from './paste'
import {
  RemoveNodesAction,
  RemoveNodesHistoryChange,
  RemoveNodesInterface,
} from './remove-nodes'
import { SelectNodeAction, SelectNodeInterface } from './select-node'
import {
  SetSelectedNodesAction,
  SetSelectedNodesInterface,
} from './set-selected-nodes'
import {
  UpdateAllNodesAction,
  UpdateAllNodesHistoryChange,
  UpdateAllNodesInterface,
} from './update-all-nodes'
import {
  UpdateNodeAction,
  UpdateNodeHistoryChange,
  UpdateNodeInterface,
} from './update-node'
import {
  UpdateNodesPositionAction,
  UpdateNodesPositionHistoryChange,
  UpdateNodesPositionInterface,
} from './update-node-position'

export {
  CopyAction,
  CutAction,
  DropNodeAction,
  NodeDragStartAction,
  PasteAction,
  RemoveNodesAction,
  SelectNodeAction,
  SetSelectedNodesAction,
  UpdateAllNodesAction,
  UpdateNodeAction,
  UpdateNodesPositionAction,
}

export type NodeActionInterface =
  | CopyInterface
  | CutInterface
  | DropNodeInterface
  | NodeDragStartInterface
  | PasteInterface
  | RemoveNodesInterface
  | SelectNodeInterface
  | SetSelectedNodesInterface
  | UpdateAllNodesInterface
  | UpdateNodeInterface
  | UpdateNodesPositionInterface

export type NodeChangesHistory =
  | CutHistoryChange
  | DropNodeHistoryChange
  | PasteHistoryChange
  | RemoveNodesHistoryChange
  | UpdateAllNodesHistoryChange
  | UpdateNodeHistoryChange
  | UpdateNodesPositionHistoryChange

export const nodeActionMap = {
  [ActionType.CopyElements]: CopyAction,
  [ActionType.CutElements]: CutAction,
  [ActionType.NodeDragStart]: NodeDragStartAction,
  [ActionType.NodeDragStop]: UpdateNodesPositionAction,
  [ActionType.NodeDrop]: DropNodeAction,
  [ActionType.PasteElements]: PasteAction,
  [ActionType.RemoveNodes]: RemoveNodesAction,
  [ActionType.SelectNode]: SelectNodeAction,
  [ActionType.SetSelectedNodes]: SetSelectedNodesAction,
  [ActionType.UpdateAllNodes]: UpdateAllNodesAction,
  [ActionType.UpdateNode]: UpdateNodeAction,
}
