import { IconButton } from '../../icon-button/icon-button'
import { useSliderStateContext } from '../context'
import styles from '../slider.module.css'

interface SliderInputProps {
  minValue?: number
  maxValue?: number
}

export const SliderInput = ({
  minValue = 0,
  maxValue = 100,
}: SliderInputProps) => {
  const state = useSliderStateContext()
  const onInputChange = (value: string): void => {
    if (new RegExp('^[0-9]*$').test(value)) {
      state.setThumbValue(0, Number(value))
    }
  }

  return (
    <div className={styles.inputContainer}>
      <input
        aria-label='Slider value'
        className={styles.input}
        disabled={state.isDisabled}
        value={state.getThumbValue(0)}
        onChange={({ target }) => onInputChange(target.value)}
      />
      <div>
        <IconButton
          aria-label='Increment'
          icon='caret-up'
          size='small'
          onPress={() => {
            state.incrementThumb(0, 1)
            state.setFocusedThumb(0)
          }}
          isDisabled={state.getThumbValue(0) === maxValue || state.isDisabled}
        />
        <IconButton
          aria-label='Decrement'
          icon='caret-down'
          size='small'
          onPress={() => {
            state.decrementThumb(0, 1)
            state.setFocusedThumb(0)
          }}
          isDisabled={state.getThumbValue(0) === minValue || state.isDisabled}
        />
      </div>
    </div>
  )
}
