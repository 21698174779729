import { Icon, Switch } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { MEANINGFUL_ELEMENT } from '../constants'
import styles from '../edit-panel.module.css'
import { EditorProps } from '../node-editor-panel'

interface MeaningfulElementProps extends EditorProps {
  isMeaningful: boolean
}

export const MeaningfulElement = memo((props: MeaningfulElementProps) => {
  const onCheckboxChange = (isSelected: boolean) => {
    props.onChange({
      type: MEANINGFUL_ELEMENT.actionType,
      fieldKey: MEANINGFUL_ELEMENT.key,
      value: isSelected,
    })
  }
  return (
    <div className={styles.footer}>
      <span className={styles.footerTitle}>
        <Icon icon='chart-simple' size='small' />
        Advanced Analytics
      </span>
      <Switch
        isReadOnly={props.isReadOnly}
        isSelected={props.isMeaningful}
        description={MEANINGFUL_ELEMENT.helperText}
        onChange={onCheckboxChange}
      >
        {MEANINGFUL_ELEMENT.label}
      </Switch>
    </div>
  )
})
