import { ClickLinkCallback } from '@mdxeditor/editor'

import IconButton from '../icon-button/icon-button'
import styles from './text-area-markdown.module.css'

interface LinkPreviewProps {
  url: string
  onClickLinkCallback: ClickLinkCallback | null
  switchFromPreviewToLinkEdit: () => void
  removeLink: () => void
}

export function LinkPreview({
  url,
  onClickLinkCallback,
  switchFromPreviewToLinkEdit,
  removeLink,
}: LinkPreviewProps) {
  return (
    <div className={styles.linkDialogPreviewContainer}>
      <a
        href={url}
        target='_blank'
        rel='noreferrer'
        onClick={e => {
          if (onClickLinkCallback !== null) {
            e.preventDefault()
            onClickLinkCallback(url)
          }
        }}
        title={`Open ${url} in new window`}
      >
        <span>{url}</span>
      </a>

      <IconButton
        icon='edit'
        size='small'
        onPress={() => switchFromPreviewToLinkEdit()}
      />

      <IconButton
        icon='copy'
        prefix='far'
        size='small'
        onPress={() => {
          void window.navigator.clipboard.writeText(url)
        }}
      />

      <IconButton icon='trash-can' size='small' onPress={() => removeLink()} />
    </div>
  )
}
