import { HubtypeProvider } from '@hubtype/data-access-models'
import { v5 as uuidv5 } from 'uuid'

import { CountryInfo } from '../domain/models/content-fields'
import { CountryCode } from '../domain/models/locales/country-code'
import { countryCodesToCountryInfo } from '../domain/models/locales/locale-utils'
import { Flow } from './types'

export const INITIAL_VIEWPORT = { x: 400, y: 200, zoom: 0.8 }

export const ADDONS_DETAIL_URL_PATH = '/upgrades/addons/detail'

export const AVAILABLE_CHANNELS = [
  { id: HubtypeProvider.Apple, icon: 'apple', name: 'Apple Business Chat' },
  {
    id: HubtypeProvider.Facebook,
    icon: 'facebook-messenger',
    name: 'Facebook Messenger',
  },
  { id: HubtypeProvider.Instagram, icon: 'instagram', name: 'Instagram' },
  { id: HubtypeProvider.Telegram, icon: 'telegram', name: 'Telegram' },
  { id: HubtypeProvider.Twitter, icon: 'twitter', name: 'Twitter' },
  { id: HubtypeProvider.Webchat, icon: 'messages', name: 'Webchat' },
  { id: HubtypeProvider.Whatsapp, icon: 'whatsapp', name: 'WhatsApp' },
]

export const AVAILABLE_COUNTRIES: CountryInfo[] =
  countryCodesToCountryInfo(CountryCode)

export const APPLICATION_REACTFLOW = 'application/reactflow'
export const APPLICATION_COPY_PASTE = 'application/reactflow-copypaste'

export const HTTP_URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)[^\s]*$/

export const ALPHANUMERIC_WITH_SYMBOLS_REGEX = /^[a-z0-9 _.&#<>+-]*$/i

export const NODE_HEIGHT = 58
export const NODE_WIDTH = 200
export const BUILD_PANEL_WIDTH = 232
export const LEFT_PANEL_MARGIN = 16

export const DRAFT_VERSION = 'draft'
export const LATEST_VERSION = 'latest'

export const FEEDBACK_TIMEOUT = 2000
export const INPUT_DEBOUNCE_DELAY = 500
export const SEARCH_DEBOUNCE_DELAY = 500

export const MAX_ROW_TITLE_LENGTH = 24
export const MAX_ROW_DESCRIPTION_LENGTH = 72
export const MAX_SECTION_TITLE_LENGTH = 60
export const MAX_WHATSAPP_LIST_MESSAGE_LENGTH = 1000
export const MAX_WHATSAPP_BUTTON_TEXT_LENGTH = 20
export const MAX_WHATSAPP_HEADER_TEXT_LENGTH = 60
export const MAX_WHATSAPP_FOOTER_TEXT_LENGTH = 60
export const MAX_WHATSAPP_MAIN_TEXT_LENGTH = 1024
export const MAX_FLOW_NAME_LENGTH = 30

// uuid namespace to generate deterministic uuids given a string
const UUID_NAMESPACE = '61d5e4bf-98e7-4695-8411-18668878cd58'

export const START_NODE_ID = uuidv5('start-node-id', UUID_NAMESPACE)
export const FALLBACK_NODE_ID = uuidv5('fallback-node-id', UUID_NAMESPACE)

export const MAIN_FLOW: Flow = {
  id: uuidv5('main-flow', UUID_NAMESPACE),
  name: 'Main',
}

export const KNOWLEDGE_BASE_FLOW: Flow = {
  id: uuidv5('knowledge-base-flow', UUID_NAMESPACE),
  name: 'Knowledge base',
}

export const FALLBACK_FLOW: Flow = {
  id: uuidv5('fallback-flow', UUID_NAMESPACE),
  name: 'Fallback',
}

export const CHECK_AVAILABLE_AGENTS = 'check_available_agents'
export const QUEUE_OPEN_WITHOUT_AGENTS = 'open-without-agents'
export const QUEUE_OPEN = 'open'
export const QUEUE_CLOSED = 'closed'

export const FALLBACK_MESSAGE_1 = '1-message'
export const FALLBACK_MESSAGE_2 = '2-message'

export const HIDDEN_FLOW_DESCRIPTIONS = 'flow-builder-hidden-flow-descriptions'
export const FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY =
  'flow-builder-preview-state'
export const BOTONIC_STATE_STORAGE_KEY = 'botonicState'

export const SESSION_RESOLUTION_TIME = 3000
export const IS_SAVING_OTHER_USER_SESSION_TIMEOUT = 1000

export const GENERIC_ERROR_MESSAGE_DESCRIPTION =
  'Please try again later or contact support for further assistance.'
