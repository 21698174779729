export const COLORS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',

  /*Neutral*/
  N900: '#24252c',
  N800: '#2e3038',
  N700: '#393B45',
  N600: '#50525f',
  N500: '#666a7a',
  N400: '#84889d',
  N300: '#9b9daf',
  N200: '#c4c6d0',
  N100: '#e8e8ea',
  N50: '#f0f0f3',

  /*Lilara*/
  L900: '#10003d',
  L800: '#200066',
  L700: '#321189',
  L600: '#522ec6',
  L500: '#6e49ff',
  L400: '#8b6dff',
  L300: '#a791ff',
  L200: '#cbbdff',
  L100: '#e8e2ff',
  L50: '#f4f1ff',

  /*Aqua*/
  A900: '#1e4c4c',
  A800: '#286464',
  A700: '#327d7d',
  A600: '#43a8a8',
  A500: '#54d2d2',
  A400: '#76dbdb',
  A300: '#98e4e4',
  A200: '#c1efef',
  A100: '#e4f8f8',
  A50: '#f2fbfb',

  /*Green*/
  G900: '#144725',
  G800: '#1b5e31',
  G700: '#21753d',
  G600: '#2d9e52',
  G500: '#38c566',
  G400: '#60d185',
  G300: '#87dca3',
  G200: '#b7eac8',
  G100: '#e0f6e7',
  G50: '#effaf3',

  /*Yellow*/
  Y900: '#5c4900',
  Y800: '#7a6100',
  Y700: '#987900',
  Y600: '#cca200',
  Y500: '#ffcb00',
  Y400: '#ffd533',
  Y300: '#ffe065',
  Y200: '#ffeca3',
  Y100: '#fff7d7',
  Y50: '#fffbeb',

  /*Orange*/
  O900: '#5c3715',
  O800: '#7a491c',
  O700: '#985b23',
  O600: '#cc7a2f',
  O500: '#ff983b',
  O400: '#ffad62',
  O300: '#ffc189',
  O200: '#ffdab8',
  O100: '#ffefe0',
  O50: '#fff7f0',

  /*Red*/
  R900: '#5c1313',
  R800: '#7a1a1a',
  R700: '#982020',
  R600: '#cc2b2b',
  R500: '#ff3636',
  R400: '#ff5e5e',
  R300: '#ff8686',
  R200: '#ffb6b6',
  R100: '#ffdfdf',
  R50: '#ffefef',

  /*Pink*/
  P900: '#5c1828',
  P800: '#7a2035',
  P700: '#982742',
  P600: '#cc3559',
  P500: '#ff426f',
  P400: '#ff688c',
  P300: '#ff8da8',
  P200: '#ffbbcb',
  P100: '#ffe1e8',
  P50: '#fff0f4',
}

export const GRADIENT = {
  AI_DARK: `linear-gradient(90deg, ${COLORS.L300} 0%, ${COLORS.R300} 100%)`,
  AI_LIGHT: `linear-gradient(90deg, ${COLORS.L500} 0%, ${COLORS.P600} 100%)`,
  L100: `linear-gradient(${COLORS.L100}, ${COLORS.L100})`,
  L50: `linear-gradient(${COLORS.L50}, ${COLORS.L50})`,
  L500: `linear-gradient(${COLORS.L500}, ${COLORS.L500})`,
  N900: `linear-gradient(${COLORS.N900}, ${COLORS.N900})`,
  WHITE: `linear-gradient(${COLORS.WHITE}, ${COLORS.WHITE})`,
}
