import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo, useEffect, useState } from 'react'
import { useUpdateNodeInternals } from 'reactflow'

import { useFlowBuilderSelector } from '../../reducer/hooks'
import { CarouselNodeProps } from '../../types'
import { TextBodySmall } from '../base'
import { MarkdownText } from './markdown/markdown'
import styles from './node.module.css'

const CarouselNode = ({ data, selected }: CarouselNodeProps): JSX.Element => {
  const updateNode = useFlowBuilderSelector(ctx => ctx.updateNode)
  const updateNodeInternals = useUpdateNodeInternals()
  const [selectedElement, setSelectedElement] = useState(
    data.selectedSubContentId
  )
  const selectElement = (id: string) => {
    if (id === data.selectedSubContentId) return
    setSelectedElement(id)
    data.selectedSubContentId = id
    updateNode(data)
    // used to call updateNodeInternals after the state is updated
    setTimeout(() => updateNodeInternals(id), 50)
  }

  useEffect(() => {
    setSelectedElement(data.selectedSubContentId)
  }, [data.selectedSubContentId])

  return (
    <Node type='message' isSelected={selected} nodeContent={data}>
      {({ NodeGroup, NodeButton, TriangleHandle }) => (
        <NodeGroup>
          {data.elements.map((element, index) =>
            selectedElement === element.id ? (
              <div
                className={styles.openedElement}
                key={index}
                onClick={() => selectElement(element.id)}
              >
                <strong>{element.title || 'Element title'}</strong>
                {element.subtitle && (
                  <MarkdownText isSelected={selectedElement === element.id}>
                    {element.subtitle}
                  </MarkdownText>
                )}
                {element.image?.assetUrl && (
                  <img
                    alt={element.title}
                    className={styles.image}
                    src={element.image.assetUrl}
                  />
                )}
                <NodeButton button={element.buttons[0]} />
              </div>
            ) : (
              <div
                className={styles.closedElement}
                key={index}
                onClick={() => selectElement(element.id)}
              >
                <TextBodySmall>
                  {element.title || 'Element title'}
                </TextBodySmall>
                {element.image?.assetUrl && (
                  <img alt={element.title} src={element.image.assetUrl} />
                )}
                <TriangleHandle
                  handleType='element'
                  id={element.buttons[0].id}
                  isHidden={!!element.buttons[0].url}
                  isRequired={!element.buttons[0].url}
                />
              </div>
            )
          )}
        </NodeGroup>
      )}
    </Node>
  )
}

export default memo(CarouselNode)
