import { Icon } from '@hubtype/ui-react-web'
import { Node } from '@hubtype/ui-react-web/flow-builder'
import { cx } from 'class-variance-authority'
import { memo } from 'react'

import { HTTP_URL_REGEX } from '../../constants'
import { VideoNodeProps } from '../../types'
import styles from './node.module.css'

const VideoNode = ({ data, selected }: VideoNodeProps) => {
  function isVideoValid(): boolean {
    if (!data.videoUrl) return false
    return HTTP_URL_REGEX.test(data.videoUrl)
  }
  return (
    <Node type='message' isSelected={selected} nodeContent={data}>
      {({ NodeGroup }) =>
        isVideoValid() && (
          <NodeGroup>
            <div className={cx(styles.video, selected && styles.selected)}>
              <Icon icon='film' size='medium' />
              <span>{data.videoUrl}</span>
            </div>
          </NodeGroup>
        )
      }
    </Node>
  )
}

export default memo(VideoNode)
