import cloneDeep from 'lodash.clonedeep'

import { Locale } from '../../../../domain/models/locales/locale'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { LocaleAction } from './locale-action'

export interface SelectLocaleInterface {
  type: ActionType.SelectLocale
  locale: Locale
  isReversible?: boolean
}

export interface SelectLocaleHistoryChange {
  type: ActionType.SelectLocale
  newLocale: Locale
  oldLocale: Locale
  currentNode?: NodeTypes
  currentFlowId: string
}

export class SelectLocaleAction extends ReversibleAction {
  static apply = (
    state: State,
    { locale, isReversible = true }: SelectLocaleInterface
  ): void => {
    const oldLocale = state.currentLocale
    if (isReversible) this.trackHistoryChange(state, locale)
    LocaleAction.setCurrentLocale(state, locale)
    this.changeLocaleInNodes(state, locale, oldLocale)
  }

  static undo = (state: State, change: SelectLocaleHistoryChange) => {
    LocaleAction.setCurrentLocale(state, change.oldLocale)
    this.changeLocaleInNodes(state, change.oldLocale, change.newLocale)
  }

  static redo = (state: State, change: SelectLocaleHistoryChange) => {
    LocaleAction.setCurrentLocale(state, change.newLocale)
    this.changeLocaleInNodes(state, change.newLocale, change.oldLocale)
  }

  private static trackHistoryChange = (state: State, locale: Locale) => {
    const newChange: SelectLocaleHistoryChange = {
      type: ActionType.SelectLocale,
      newLocale: locale,
      oldLocale: state.currentLocale,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange, false)
  }

  private static changeLocaleInNodes = (
    state: State,
    newLocale: Locale,
    oldLocale: Locale
  ): void => {
    state.nodes = cloneDeep(state.nodes).map(node => {
      node.data.setContentByLocale(oldLocale.code)
      node.data.setValuesByLocale(newLocale.code, state.nonMessageContents)
      node.data.getLocalesWithErrors(state.locales.map(locale => locale.code))
      node.data.setErrors()
      return node
    })
    state.currentNode = state.nodes.find(
      node => node.id === state.currentNode?.id
    )
  }
}
