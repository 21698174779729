import { SessionConflictModalProps } from '../../one-user/modals/session-conflict'
import { SessionEndedModalProps } from '../../one-user/modals/session-ended'
import { RemoveFlowModalProps } from '../flow-selector/modals/remove-flow-modal'
import { EditFlowModalProps } from '../flow-selector/modals/rename-flow-modal'
import { RemoveLocalesModalProps } from '../locale-panel/remove-locale-modal'
import { EditUrlModalProps } from '../node-editor-panel/editor-widgets/url-widget/modals/edit-url-modal'
import { RemoveUrlModalProps } from '../node-editor-panel/editor-widgets/url-widget/modals/remove-url-modal'
import { RemovePayloadModalProps } from '../node-editor-panel/editors/bot-action-editor/remove-payload-modal'
import { UniqueIdentifierModalProps } from '../node-editor-panel/unique-identifier/unique-identifier-modal'

export type ModalContent =
  | { type: ModalType.AddFlow }
  | { type: ModalType.ConnectionLost }
  | { type: ModalType.KnowledgeBase }
  | { type: ModalType.Publish }
  | { type: ModalType.RestoreVersion }
  | { type: ModalType.TransferFlows }
  | EditFlowModalProps
  | EditUrlModalProps
  | RemoveFlowModalProps
  | RemoveLocalesModalProps
  | RemovePayloadModalProps
  | RemoveUrlModalProps
  | SessionConflictModalProps
  | SessionEndedModalProps
  | UniqueIdentifierModalProps

export enum ModalType {
  AddFlow = 'Add flow',
  ConnectionLost = 'Connection lost',
  EditFlow = 'Rename flow',
  EditUniqueIdentifier = 'Edit unique identifier',
  EditUrl = 'Edit URL',
  Exit = 'Exit Flow Builder',
  KnowledgeBase = 'Knowledge base',
  Publish = 'Publish conversational app',
  RemoveFlow = 'Delete flow',
  RemoveLocales = 'Remove languages',
  RemovePayload = 'Delete payload in use',
  RemoveUrl = 'Delete URL in use',
  RestoreVersion = 'Restore version',
  SessionConflict = 'Flow currently being edited',
  SessionEnded = 'Session ended',
  TransferFlows = 'Transfer flow',
}

export const NON_DISMISSABLE_MODAL_TYPES = [
  ModalType.ConnectionLost,
  ModalType.SessionConflict,
  ModalType.SessionEnded,
]
