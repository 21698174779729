import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('HubtypeFlowBuilderSettings')
export class HubtypeFlowBuilderSettings {
  @JsonProperty('cmsType', String, true)
  public cmsType = ''

  @JsonProperty('is_preview_enabled', Boolean, true)
  public isPreviewEnabled?: boolean = false
}
