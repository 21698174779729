import { useToastFeedback } from '@hubtype/ui-react-web'

import { getDate } from '../../../utils/date-utils'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { InteractionMode, LoadingMessage } from '../../types'
import { useSave } from '../header/save/use-save'

export const useRestoreVersion = () => {
  const { saveFlow } = useSave()
  const feedback = useToastFeedback()
  const { state, setCurrentVersion, setLoadingMessage, toggleInteractivity } =
    useFlowBuilderSelector(ctx => ctx)

  const restoreVersion = async (): Promise<void> => {
    if (!state.currentVersion) return
    setLoadingMessage(LoadingMessage.RestoringVersion)
    const savedFlowHash = await saveFlow(undefined, true)
    setLoadingMessage(undefined)

    if (savedFlowHash) {
      feedback.addSuccess(
        `Version published on ${getDate(state.currentVersion.createdAt)} has been restored`,
        'To replace the published version, you will need to publish again.',
        { timeout: 5000 }
      )
      setCurrentVersion(undefined)
      if (state.interactionMode === InteractionMode.Edit) {
        toggleInteractivity(true)
      }
    }
  }

  return restoreVersion
}
