import { cva, cx } from 'class-variance-authority'
import React, { ReactNode } from 'react'
import { Edge } from 'reactflow'

import { IconName } from '../../icon/icon'
import { TriangleHandle, TriangleHandleProps } from '../handle/handle'
import { Handles, InputHandle, TargetHandle } from '../handle/handles'
import styles from './node.module.css'
import { NodeBadgeList, NodeBadgeListProps } from './widgets/badge-list'
import { NodeButton, NodeButtonProps } from './widgets/button'
import { NodeCode } from './widgets/code'
import { NodeCondition, NodeConditionProps } from './widgets/condition'
import { NodeFooter, NodeFooterProps } from './widgets/footer'
import { NodeGroup, NodeGroupProps } from './widgets/group'
import { NodeHeader } from './widgets/header'
import { NodeSection, NodeSectionProps } from './widgets/section'

const nodeClasses = cva(styles.base, {
  variants: {
    type: {
      message: styles.messageNode,
      condition: styles.conditionalNode,
      input: styles.inputNode,
      rounded: styles.roundNode,
      fallback: styles.messageNode,
    },
    isSelected: {
      true: styles.selected,
    },
    isAi: {
      true: styles.aiNode,
    },
    hasErrors: {
      true: styles.hasErrors,
    },
  },
})

export interface NodeActionComponents {
  NodeCondition: (props: Omit<NodeConditionProps, 'handleProps'>) => ReactNode
  NodeButton: (props: Omit<NodeButtonProps, 'handleProps'>) => ReactNode
  NodeSection: (props: NodeSectionProps) => ReactNode
  NodeBadgeList: (props: Omit<NodeBadgeListProps, 'isSelected'>) => ReactNode
  TriangleHandle: (
    props: Omit<TriangleHandleProps, 'nodeContent' | 'isNodeSelected'>
  ) => ReactNode
  NodeGroup: (props: NodeGroupProps) => ReactNode
  NodeFooter: (props: NodeFooterProps) => ReactNode
}

export interface NodeContent {
  /** The code of the node */
  code: string
  /** The edges of the node */
  edges: Edge[]
  /** The errors of the node */
  errors: {
    showErrors?: boolean
    hasDuplicatedIdentifier?: boolean
    duplicatedIdentifierError: string
    fieldErrors: string[]
  }
  /** The display info of the node */
  getDisplayInfo: () => { name: string; icon: IconName }
  /** The content id of the node */
  getContentId: () => { id: string; type: string }
}

export interface NodeProps {
  /** The variant of the node */
  type: 'message' | 'condition' | 'input' | 'rounded' | 'fallback'
  /** The content of the node */
  nodeContent: NodeContent
  /** The content of the node */
  children?: (components: NodeActionComponents) => ReactNode
  /** Whether the node is selected */
  isSelected?: boolean
  /** Whether the node is a whatsapp node */
  isWhatsapp?: boolean
  /** Whether the node is an AI node */
  isAi?: boolean
  /** Whether the node is a webview node */
  isWebview?: boolean
  /** The icon component of the node */
  iconComponent?: React.ReactNode
  /** Whether the node has a header divider */
  hasHeaderDivider?: boolean
}

/** Node component used in the flow builder to represent content blocks */
export function Node({
  type = 'message',
  isSelected,
  isWhatsapp,
  children,
  iconComponent,
  nodeContent,
  isAi,
  isWebview,
  hasHeaderDivider,
}: NodeProps) {
  const allErrors = []

  if (nodeContent.errors?.hasDuplicatedIdentifier) {
    allErrors.push(nodeContent.errors?.duplicatedIdentifierError)
  }

  if (nodeContent.errors?.showErrors) {
    allErrors.push(...(nodeContent.errors?.fieldErrors || []))
  }

  const hasErrors = allErrors.length > 0

  const handlesComponentMap = {
    input: InputHandle,
    condition: TargetHandle,
    message: Handles,
    rounded: TargetHandle,
    fallback: TargetHandle,
  }

  const HandlesComponent = handlesComponentMap[type]

  const NodeConditionComponent = (
    props: Omit<NodeConditionProps, 'handleProps'>
  ) => {
    return (
      <NodeCondition
        {...props}
        handleProps={{ nodeContent, isNodeSelected: isSelected }}
      />
    )
  }

  const NodeButtonComponent = (props: Omit<NodeButtonProps, 'handleProps'>) => {
    return (
      <NodeButton
        {...props}
        handleProps={{ nodeContent, isNodeSelected: isSelected }}
      />
    )
  }

  const NodeBadgeListComponent = (props: NodeBadgeListProps) => {
    return <NodeBadgeList {...props} isSelected={isSelected} />
  }

  const TriangleHandleComponent = (
    props: Omit<TriangleHandleProps, 'nodeContent' | 'isNodeSelected'>
  ) => {
    return (
      <TriangleHandle
        {...props}
        nodeContent={nodeContent}
        isNodeSelected={isSelected}
      />
    )
  }

  const child = children?.({
    NodeCondition: NodeConditionComponent,
    NodeButton: NodeButtonComponent,
    NodeBadgeList: NodeBadgeListComponent,
    TriangleHandle: TriangleHandleComponent,
    NodeSection,
    NodeGroup,
    NodeFooter,
  })

  return (
    <div
      className={cx(styles.nodeContainer, {
        [styles.large]: type === 'rounded',
      })}
    >
      <NodeCode
        code={nodeContent.code}
        errors={allErrors}
        isCodeHidden={type === 'rounded'}
      />
      <div className={nodeClasses({ type, isAi, isSelected, hasErrors })}>
        {type !== 'rounded' && (
          <NodeHeader
            isWhatsapp={isWhatsapp}
            iconComponent={iconComponent}
            {...nodeContent.getDisplayInfo()}
            hasHeaderDivider={hasHeaderDivider}
          />
        )}
        {HandlesComponent && !isWebview && (
          <HandlesComponent
            nodeContent={nodeContent}
            isNodeSelected={isSelected}
            isConnectable={type !== 'fallback'}
          />
        )}
        {React.Children.toArray(child).filter(Boolean).length > 0 && (
          <div className={styles.content}>{child}</div>
        )}
      </div>
    </div>
  )
}

export default Node
