export enum FeatureFlag {
  AiSmartIntentsEnabled = 'ai_smart_intents_enabled',
  KnowledgebaseEnabled = 'knowledgebase_enabled',
}

export const hasSmartIntentsFeature = (
  orgFeatureFlags: Record<string, boolean>
) => {
  return Boolean(orgFeatureFlags[FeatureFlag.AiSmartIntentsEnabled])
}

export const hasKnowledgeBaseFeature = (
  orgFeatureFlags: Record<string, boolean>
) => {
  return Boolean(orgFeatureFlags[FeatureFlag.KnowledgebaseEnabled])
}
