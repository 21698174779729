import { useEffect } from 'react'

import { useRealtimeContext } from '../../../realtime'

export const useSaveBeforeUnload = (isSaved: boolean) => {
  const realtimeClient = useRealtimeContext()

  useEffect(() => {
    const handleBeforeUnload = async (evt: BeforeUnloadEvent) => {
      if (!isSaved) {
        evt.preventDefault()
        evt.returnValue = true
      }
    }

    const handleUnload = () => {
      if (realtimeClient) {
        realtimeClient.close()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('unload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('unload', handleUnload)
    }
  }, [isSaved, realtimeClient])
}
