import { useEffect, useState } from 'react'

// This useEffect is to manage the focus state of the field because the MDXEditor component doesn't expose a focus state
export function useFocusTextAreaMarkdown(
  containerRef: React.RefObject<HTMLDivElement>
) {
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    // Find the contenteditable element within our container
    const editableElement = container.querySelector('[contenteditable="true"]')
    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    if (editableElement) {
      editableElement.addEventListener('focus', handleFocus)
      editableElement.addEventListener('blur', handleBlur)

      return () => {
        editableElement.removeEventListener('focus', handleFocus)
        editableElement.removeEventListener('blur', handleBlur)
      }
    } else {
      // If not found immediately, observe until it's added
      const observer = new MutationObserver(mutations => {
        for (const mutation of mutations) {
          const editableElement = container.querySelector(
            '[contenteditable="true"]'
          )
          if (editableElement) {
            editableElement.addEventListener('focus', handleFocus)
            editableElement.addEventListener('blur', handleBlur)

            observer.disconnect()
            return () => {
              editableElement.removeEventListener('focus', handleFocus)
              editableElement.removeEventListener('blur', handleBlur)
            }
          }
        }
      })

      observer.observe(container, { childList: true, subtree: true })
      return () => observer.disconnect()
    }
  }, [containerRef])

  return { isFocused, setIsFocused }
}
