import { State, Webview } from '../../../types'
import { ActionType } from '../../action-types'

export interface SetWebviewsInterface {
  type: ActionType.SetWebviews
  webviews: Webview[]
}

export class SetWebviewsAction {
  static apply = (state: State, { webviews }: SetWebviewsInterface): void => {
    state.webviews = webviews
    state.currentWebviewId = undefined
  }
}
