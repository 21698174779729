import { HubtypeQueue } from '@hubtype/data-access-models'
import {
  SearchBar,
  SelectSection,
  SelectWithSections,
} from '@hubtype/ui-react-web'
import { memo, useState } from 'react'

import {
  QueueFields,
  QueueStatusFields,
} from '../../../../../domain/models/content-fields'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { QUEUE_OPEN_WITHOUT_AGENTS } from '../../../../constants'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { TextBodyBold } from '../../../base'
import { HtInfoPanel } from '../../../base/ht-info-panel/ht-info-panel'
import { StyledHeader } from '../../../flow-selector/flow-selector-styles'
import { Action, CHECK_AVAILABLE_AGENTS, QUEUE } from '../../constants'
import { FieldsCheckbox } from '../../editor-widgets/checkbox-widget'
import { EditorProps } from '../../node-editor-panel'
import { UniqueIdentifierField } from '../../unique-identifier/unique-identifier-widget'

interface QueueStatusEditorProps extends EditorProps {
  nodeContent: QueueStatusFields
}

export const QueueStatusEditor = memo(
  ({ nodeContent, ...props }: QueueStatusEditorProps): JSX.Element => {
    const analytics = useAnalytics()
    const organizationContents = useFlowBuilderSelector(
      ctx => ctx.state.organizationContents
    )
    const [flowFilter, setFlowFilter] = useState<string>('')

    const getQueueSelectItem = (): SelectSection<HubtypeQueue>[] => {
      return organizationContents.projects.map(p => ({
        title: p.name,
        items: p.queues,
      }))
    }

    const onQueueChange = (queue?: HubtypeQueue) => {
      if (!queue) return
      const value = QueueFields.fromHubtype(queue)
      const change = { type: QUEUE.actionType, fieldKey: QUEUE.key, value }
      props.onChange(change)
    }

    const onAgentAvailabilityChange = (action: Action) => {
      if (nodeContent.checkAvailableAgents) {
        const edges = nodeContent.edges.filter(
          edge =>
            edge.sourceHandle !==
            `${nodeContent.id}-${QUEUE_OPEN_WITHOUT_AGENTS}`
        )
        //@ts-expect-error due to Action Type
        action = { ...action, edges }
      }
      analytics.trackEvent(TrackEventName.AgentAvailability, {
        action: nodeContent.checkAvailableAgents ? false : true,
      })
      props.onChange(action)
    }

    return (
      <>
        <TextBodyBold>Queue status</TextBodyBold>
        <UniqueIdentifierField
          {...props}
          {...nodeContent}
          value={nodeContent.code}
        />
        <SelectWithSections
          {...props}
          label={QUEUE.label}
          sections={getQueueSelectItem()}
          selectedKey={nodeContent.queue?.id}
          onChange={onQueueChange}
          filterValue={flowFilter}
          isReadOnly={props.isReadOnly}
          isMissing={props.showMissingFields && !nodeContent.queue}
          header={
            <StyledHeader>
              <SearchBar
                label='Search queue'
                defaultValue={flowFilter}
                size='small'
                onChangeWithDebounce={setFlowFilter}
              />
            </StyledHeader>
          }
        />
        <FieldsCheckbox
          {...props}
          field={CHECK_AVAILABLE_AGENTS}
          checked={nodeContent.checkAvailableAgents}
          onChange={onAgentAvailabilityChange}
        />
        <HtInfoPanel
          title='About queue status condition'
          text='Queue status condition is based on whether the selected queue is open or closed. Different flows can also be set depending on whether there are available agents at the moment or not. Queue status is defined in the queue settings tab.'
        ></HtInfoPanel>
      </>
    )
  }
)
