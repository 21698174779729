import { DateConverter } from '@hubtype/util-shared'
import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('HubtypeKnowledgeBase')
export class HubtypeKnowledgeBase {
  @JsonProperty('id', String)
  public id = ''

  @JsonProperty('name', String, true)
  public name = ''

  @JsonProperty('created_by', String, true)
  public createdBy?: string = undefined

  @JsonProperty('created_at', DateConverter, true)
  public createdAt?: Date = undefined

  @JsonProperty('last_updated_by', String, true)
  public lastUpdatedBy?: string = undefined

  @JsonProperty('last_updated_at', DateConverter, true)
  public lastUpdatedAt?: Date = undefined

  @JsonProperty('is_main', Boolean, true)
  public isMain = true

  @JsonProperty('instructions', String, true)
  public instructions = ''
}
