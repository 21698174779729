import cloneDeep from 'lodash.clonedeep'

import { Locale, NewLocale } from '../../../../domain/models/locales/locale'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { LocaleAction } from './locale-action'

export interface AddLocalesInterface {
  type: ActionType.AddLocales
  newLocales: NewLocale[]
}

export interface AddLocalesHistoryChange {
  type: ActionType.AddLocales
  newLocales: NewLocale[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class AddLocalesAction extends ReversibleAction {
  static apply = (state: State, { newLocales }: AddLocalesInterface): void => {
    if (newLocales.length === 0) return
    this.trackHistoryChange(state, newLocales)
    const locales = this.getLocalesFromNewLocales(newLocales)
    LocaleAction.addLocales(state, locales)
    this.addLocalesInNodes(state, newLocales)
  }

  static undo = (state: State, change: AddLocalesHistoryChange) => {
    const locales = this.getLocalesFromNewLocales(change.newLocales)
    LocaleAction.removeLocales(state, locales)
  }

  static redo = (state: State, change: AddLocalesHistoryChange) => {
    const locales = this.getLocalesFromNewLocales(change.newLocales)
    LocaleAction.addLocales(state, locales)
    this.addLocalesInNodes(state, change.newLocales)
  }

  private static trackHistoryChange = (state: State, locales: NewLocale[]) => {
    const newChange: AddLocalesHistoryChange = {
      type: ActionType.AddLocales,
      newLocales: locales,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static getLocalesFromNewLocales = (
    newLocales: NewLocale[]
  ): Locale[] => {
    return newLocales.map(newLocale => newLocale.locale)
  }

  private static addLocalesInNodes = (
    state: State,
    newLocales: NewLocale[]
  ): void => {
    const newNodes = cloneDeep(state.nodes)
    newLocales.forEach(newLocale => {
      const copyFrom = newLocale.copyFrom
      const newLocaleCode = newLocale.locale.code
      if (copyFrom) {
        newNodes.forEach(node => node.data.addLocale(newLocaleCode, copyFrom))
      }
    })
    state.nodes = newNodes
  }
}
