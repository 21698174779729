import { Dialog } from '@hubtype/ui-react-web'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { usePublish } from './use-publish'

export const PublishModal = (): JSX.Element => {
  const setModalContent = useFlowBuilderSelector(ctx => ctx.setModalContent)
  const analytics = useAnalytics()
  const { publishFlow } = usePublish()

  const publish = async (): Promise<void> => {
    setModalContent()
    await Promise.all([
      analytics.trackEvent(TrackEventName.PublishConfirmation, {
        has_published: true,
      }),
      await publishFlow(),
    ])
  }

  const cancel = async (): Promise<void> => {
    await analytics.trackEvent(TrackEventName.PublishConfirmation, {
      has_published: false,
    })
  }

  return (
    <Dialog
      title='Publish conversational app'
      confirmButton={{ children: 'Publish', onPress: publish }}
      onClose={cancel}
    >
      Please note: If you go ahead, you'll be replacing the current live
      version. You can always find older versions from the 'Version history'
      dropdown.
    </Dialog>
  )
}
