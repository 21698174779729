import cloneDeep from 'lodash.clonedeep'
import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { IdMapping, NonMessageContents } from '../../../UI/types'
import { HtCarouselNode } from '../cms/hubtype/carousel'
import { HtContentType } from '../cms/hubtype/node-types'
import { LocaleCode } from '../locales/code'
import {
  ContentType,
  ElementFields,
  MessageContentType,
  TopContentFieldsBase,
} from '.'

export class CarouselFields extends TopContentFieldsBase {
  constructor(
    public elements: ElementFields[] = [],
    public selectedSubContentId?: string
  ) {
    super()
  }

  contentType(): MessageContentType {
    return ContentType.CAROUSEL
  }

  static getDisplayInfo() {
    return { name: 'Carousel', icon: 'rectangle-vertical-history' }
  }

  getDisplayInfo() {
    return CarouselFields.getDisplayInfo()
  }

  setValuesByLocale(
    locale: string,
    nonMessageContents: NonMessageContents
  ): void {
    this.elements.forEach(e => e.setValuesByLocale(locale, nonMessageContents))
  }

  setContentByLocale(locale: LocaleCode): void {
    this.elements.forEach(element => element.setContentByLocale(locale))
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    this.elements.forEach(element => element.addLocale(newLocale, copyFrom))
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    this.elements.forEach(element => element.removeLocales(localesToRemove))
  }

  static fromHubtypeCMS(component: HtCarouselNode): CarouselFields {
    const newCarousel = new CarouselFields()
    this.setHubtypeCmsCommonData(newCarousel, component)
    return newCarousel
  }

  toHubtypeCMS(position: XYPosition, locale: LocaleCode): HtCarouselNode {
    const node: HtCarouselNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.CAROUSEL,
      content: {
        elements: this.elements.map(element => element.toHubtypeCMS(locale)),
      },
    }
    return node
  }

  static cloneWithElements(
    carousel: CarouselFields,
    elements: ElementFields[]
  ): CarouselFields {
    const clone = cloneDeep(carousel)
    clone.elements = elements
    return clone
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('Content ID')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    const elementsErrors = this.elements.flatMap(elements =>
      elements.getErrors(this.edges)
    )
    this.errors.fieldErrors = [
      ...fieldErrors,
      ...this.aggregateErrors(elementsErrors),
    ]
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    this.errors.localesWithErrors = [
      ...new Set(
        this.elements.flatMap(element => element.getLocalesWithErrors(locales))
      ),
    ]
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    if (this.fieldsIncludeString([this.code], value)) return true
    const includesString = this.elements.map(e => e.hasString(value))
    return includesString.indexOf(true) !== -1
  }

  getConnections(edges: Edge[]): NodeEdges {
    return {
      buttons: this.elements.map(e => e.getConnections(edges)).flat(),
      followUp: this.getFollowUp(edges),
    }
  }

  static getCopy(content: CarouselFields): CarouselFields {
    const newCarousel = new CarouselFields()
    TopContentFieldsBase.copyContent(content, newCarousel)
    newCarousel.elements = content.elements.map(element =>
      ElementFields.getCopy(element)
    )
    return newCarousel
  }

  getIdMappingForOldFields(oldCarousel: CarouselFields): IdMapping {
    return this.elements.reduce((idMapping: IdMapping, element, i) => {
      const elementIdMapping = element.getIdMappingForOldFields(
        oldCarousel.elements[i]
      )
      return { ...idMapping, ...elementIdMapping }
    }, {})
  }
}
