import { cva } from 'class-variance-authority'

import { Icon } from '../../../icon/icon'
import { NodeHandleProps, TriangleHandle } from '../../handle/handle'
import styles from './widgets.module.css'

const buttonVariants = cva(styles.buttonContainer, {
  variants: {
    isReply: {
      true: styles.reply,
    },
    isEmpty: {
      true: styles.empty,
    },
  },
})

export interface NodeButtonProps {
  button: {
    id?: string
    text: string
    url?: { name: string }
  }
  hasHandler?: boolean
  isReply?: boolean
  handleProps: NodeHandleProps
  onClick?(): void
}

export const NodeButton = ({
  button,
  isReply,
  hasHandler = true,
  handleProps,
  onClick,
}: NodeButtonProps): JSX.Element => {
  const buttonUrl = button.url?.name
  return (
    <div
      className={buttonVariants({ isReply, isEmpty: !button.text })}
      onClick={onClick}
    >
      <div>{button.text || 'Button'}</div>
      {!isReply && buttonUrl && (
        <a href={buttonUrl} target='_blank' rel='noopener noreferrer'>
          <Icon icon='link' size='medium' />
        </a>
      )}
      {hasHandler && (
        <TriangleHandle
          {...handleProps}
          id={button.id}
          handleType='button'
          isHidden={!!button.url}
          isRequired={!button.url}
        />
      )}
    </div>
  )
}
