import { Badge, SelectItem, SelectProps } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ROLES } from '../../roles'
import { InteractionMode } from '../../types'
import { Size } from '../base'
import { HorizontalContainer } from '../base/containers'
import { KnowledgeBaseButton } from './ai-settings/knowledge-base-button'
import { AlertCenter } from './alert-center/alert-center'
import { useAlertCenter } from './alert-center/use-alert-center'
import { ConnectionStatus } from './connection-status/connection-status'
import { ExitButton } from './exit/exit-button'
import styles from './header.module.css'
import { LocaleDropdown } from './locale-dropdown/locale-dropdown'
import { PreviewButton } from './preview/preview-button'
import { PublishButton } from './publish/publish-button'
import { SaveIndicator } from './save/save-indicator'
import { TransferFlowsButton } from './transfer-flows/transfer-flows-button'
import {
  VersionHistoryData,
  VersionHistoryDropdown,
} from './version-history-dropdown/version-history-dropdown'

export const headerDropdownProps: Partial<SelectProps<SelectItem>> = {
  buttonProps: {
    intent: 'secondary',
    width: 'max-content',
  },
  popoverProps: {
    listMaxHeight: '450px',
    width: '240px',
    placement: 'bottom right',
  },
  isLabelHidden: true,
}

export interface HeaderProps {
  loadPublishedVersion(version: VersionHistoryData): Promise<void>
}

export const Header = memo(
  ({ loadPublishedVersion }: HeaderProps): JSX.Element => {
    const { organizationContents, interactionMode } = useFlowBuilderSelector(
      ctx => ctx.state
    )
    const { errors } = useAlertCenter()

    return (
      <div className={styles.header} role={ROLES.BANNER}>
        <h1 className={styles.title}>
          {organizationContents.currentBot.name}
          {interactionMode === InteractionMode.View && <span>(View only)</span>}
          {organizationContents.currentBot.isTest && (
            <Badge type='system'>Staging</Badge>
          )}
        </h1>
        <HorizontalContainer $size={Size.MEDIUM} $align='center'>
          <KnowledgeBaseButton />
          <LocaleDropdown />
          <VersionHistoryDropdown loadPublishedVersion={loadPublishedVersion} />
        </HorizontalContainer>
        <HorizontalContainer $size={Size.SMALL} $align='center'>
          <div className={styles.fixedWidthContainer}>
            <ConnectionStatus />
            <SaveIndicator hasFlowErrors={!!errors?.nodeErrors.length} />
            <AlertCenter errors={errors} />
          </div>
          <PreviewButton />
          <PublishButton hasFlowErrors={!!errors?.nodeErrors.length} />
        </HorizontalContainer>
        <HorizontalContainer $size={Size.SMALL} $align='center'>
          <TransferFlowsButton />
          <ExitButton />
        </HorizontalContainer>
      </div>
    )
  }
)
