import { Icon } from '@hubtype/ui-react-web'
import { Node } from '@hubtype/ui-react-web/flow-builder'
import { getIconPropsFromFileType } from '@hubtype/util-shared'
import {
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
} from 'reactflow'

import { MessageContentType } from '../../domain/models/content-fields'
import AiMagicWandIcon from '../../UI/assets/ai-magic-wand.svg'
import { Size, TextSmall } from '../../UI/components/base'
import { HorizontalContainer } from '../../UI/components/base/containers'
import { KnowledgeBaseFields } from './model'

export interface KnowledgeBaseNodeInterface
  extends ReactFlowNode<KnowledgeBaseFields> {
  data: KnowledgeBaseFields
  type: MessageContentType.KNOWLEDGE_BASE
}

export interface KnowledgeBaseNodeProps
  extends ReactFlowNodeProps<KnowledgeBaseFields> {
  data: KnowledgeBaseFields
  type: MessageContentType.KNOWLEDGE_BASE
}

const MAX_SOURCES_TO_SHOW = 3

export const KnowledgeBaseNode = ({
  data,
  selected,
}: KnowledgeBaseNodeProps) => {
  return (
    <Node
      type='message'
      isAi={true}
      isSelected={selected}
      nodeContent={data}
      iconComponent={<AiMagicWandIcon />}
      hasHeaderDivider={true}
    >
      {({ NodeFooter }) => (
        <>
          <strong>Knowledge sources</strong>
          {data.sources.slice(0, MAX_SOURCES_TO_SHOW).map(source => (
            <HorizontalContainer key={source.id} $size={Size.SMALL}>
              <Icon {...getIconPropsFromFileType(source.fileType)} />
              <TextSmall $wordBreak='break-word'>{source.name}</TextSmall>
            </HorizontalContainer>
          ))}
          {data.sources.length > MAX_SOURCES_TO_SHOW && (
            <p> +{data.sources.length - MAX_SOURCES_TO_SHOW} more</p>
          )}
          {data.feedbackEnabled && (
            <NodeFooter>
              <Icon icon='thumbs-up' prefix='far' />
              <Icon icon='thumbs-down' prefix='far' />
            </NodeFooter>
          )}
        </>
      )}
    </Node>
  )
}
