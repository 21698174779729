import { DateConverter } from '@hubtype/util-shared'
import { JsonObject, JsonProperty } from 'json2typescript'

export enum ExtractionJobStatus {
  Completed = 'completed',
  Pending = 'pending',
  Running = 'running',
  Failed = 'failed',
}

@JsonObject('HubtypeExtractionJob')
export class HubtypeExtractionJob {
  @JsonProperty('id', String)
  id = ''

  @JsonProperty('file_name', String, true)
  fileName: string | null = null

  @JsonProperty('file_url', String, true)
  fileUrl: string | null = null

  @JsonProperty('url', String, true)
  url: string | null = null

  @JsonProperty('hash', String, true)
  hash: string | null = null

  @JsonProperty('is_active', Boolean)
  isActive = true

  @JsonProperty('status', String)
  status: ExtractionJobStatus = ExtractionJobStatus.Failed

  @JsonProperty('created_at', DateConverter)
  createdAt: Date = new Date()

  get fileType(): string | undefined {
    return this.fileName?.split('.').pop()
  }
}
