import { NodeTypes, State, Webview } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { WebviewAction } from './webview-action'

export interface RemoveWebviewInterface {
  type: ActionType.RemoveWebview
  webviewToRemove: Webview
}

export interface RemoveWebviewHistoryChange {
  type: ActionType.RemoveWebview
  removedWebview: Webview
  currentWebviewId?: string
  currentNode?: NodeTypes
}

export class RemoveWebviewAction extends ReversibleAction {
  static apply = (
    state: State,
    { webviewToRemove }: RemoveWebviewInterface
  ): void => {
    WebviewAction.removeWebview(state, webviewToRemove)
    this.trackHistoryChange(state, webviewToRemove, state.currentWebviewId)
  }

  static undo = (state: State, change: RemoveWebviewHistoryChange) => {
    WebviewAction.addWebview(state, change.removedWebview)
  }

  static redo = (state: State, change: RemoveWebviewHistoryChange) => {
    WebviewAction.removeWebview(state, change.removedWebview)
  }

  private static trackHistoryChange = (
    state: State,
    removedWebview: Webview,
    currentWebviewId?: string
  ) => {
    const newChange: RemoveWebviewHistoryChange = {
      type: ActionType.RemoveWebview,
      removedWebview,
      currentWebviewId,
      currentNode: state.currentNode,
    }
    this.updateChangesHistory(state, newChange)
  }
}
