import {
  HubtypeBot,
  HubtypeBotConfig,
  HubtypeKnowledgeBase,
  HubtypeKnowledgeSource,
  HubtypeProject,
  HubtypeProviderAccount,
  HubtypeQueue,
  HubtypeUser,
} from '@hubtype/data-access-models'

import { OrganizationContents } from '../../UI/types'
import { HubtypeApi } from './hubtype-api'

export class HubtypeService {
  // TODO: Replace with user service getUser method
  static async getUserData(
    authToken: string,
    userId: string
  ): Promise<HubtypeUser | undefined> {
    const data = await HubtypeApi.get(authToken, `users/${userId}/`, 'v2')
    if (!data) return undefined
    const user = new HubtypeUser()
    user.id = data.id
    user.email = data.email
    user.organizationId = data.organization_id
    user.role = data.role
    user.username = data.username
    user.firstName = data.first_name
    user.lastName = data.last_name
    user.pic = data.pic
    return user
  }

  static async getBots(authToken: string): Promise<HubtypeBot[]> {
    const data = await HubtypeApi.get(
      authToken,
      `bots/?include_preview_providers=${true}`,
      'v1',
      { page_size: 100 }
    )
    if (!data) return []
    return data.results.map((botData: any) => {
      const bot = new HubtypeBot()
      bot.id = botData.id
      bot.name = botData.name
      bot.providerAccounts = botData.provider_accounts.map((provider: any) => {
        const providerAccount = new HubtypeProviderAccount()
        providerAccount.id = provider.id
        providerAccount.provider = provider.provider
        providerAccount.name = provider.name
        providerAccount.isTest = provider.is_test
        providerAccount.isActive = provider.is_active
        providerAccount.phoneNumber = provider.phone_number
        providerAccount.netlifyUrl = provider.netlify_url
        return providerAccount
      })
      bot.flowBuilderSettings = botData.flow_builder_settings
      bot.isTest = botData.is_test
      return bot
    })
  }

  static async getOrganizationContents(
    authToken: string,
    botId: string
  ): Promise<OrganizationContents> {
    const projects = await this.getHubtypeProjects(authToken)
    const featureFlags = await this.getOrganizationFeatureFlags(authToken)
    const { knowledgeSources, knowledgeBaseDefaultInstructions } =
      await this.getOrganizationKnowledgeSources(authToken)
    const bots = await this.getBots(authToken)
    const currentBot = bots.find(cApps => cApps.id === botId)!
    return {
      projects,
      featureFlags,
      knowledgeSources,
      currentBot: currentBot,
      bots: bots,
      knowledgeBaseDefaultInstructions,
    }
  }

  private static async getOrganizationFeatureFlags(
    authToken: string
  ): Promise<Record<string, boolean>> {
    const data = await HubtypeApi.get(authToken, 'my-organization/')
    if (!data) return {}
    return data.feature_flags
  }

  private static async getHubtypeProjects(
    authToken: string
  ): Promise<HubtypeProject[]> {
    const data = await HubtypeApi.get(
      authToken,
      'projects/?page=1&page_size=100',
      'v2'
    )
    if (!data) return []
    return data.results.map((project: any) => {
      const hubtypeProject = new HubtypeProject()
      hubtypeProject.id = project.id
      hubtypeProject.name = project.name
      hubtypeProject.queues = project.queues.map((queue: any) => {
        const hubtypeQueue = new HubtypeQueue()
        hubtypeQueue.id = queue.id
        hubtypeQueue.name = queue.name
        hubtypeQueue.projectName = project.name
        hubtypeQueue.projectId = project.id
        return hubtypeQueue
      })
      return hubtypeProject
    })
  }

  private static async getOrganizationKnowledgeSources(
    authToken: string
  ): Promise<{
    knowledgeSources: HubtypeKnowledgeSource[]
    knowledgeBaseDefaultInstructions: string
  }> {
    const mainKnowledgeBase: HubtypeKnowledgeBase = await HubtypeApi.get(
      authToken,
      `ai/knowledge_bases/main/`
    )
    if (!mainKnowledgeBase)
      return {
        knowledgeSources: [],
        knowledgeBaseDefaultInstructions: '',
      }
    const knowledgeSources = await this.getNewKnowledgeSources(
      authToken,
      mainKnowledgeBase.id
    )
    return {
      knowledgeSources,
      knowledgeBaseDefaultInstructions: mainKnowledgeBase.instructions,
    }
  }

  private static async getNewKnowledgeSources(
    authToken: string,
    knowledgeBaseId: string
  ): Promise<HubtypeKnowledgeSource[]> {
    const responseData = await HubtypeApi.get(
      authToken,
      `ai/knowledge_bases/${knowledgeBaseId}/sources/`,
      'v1',
      { page_size: 100 }
    )
    const knowledgeSources = responseData.results.map((source: any) => {
      const currentKnowledgeSource = new HubtypeKnowledgeSource()
      currentKnowledgeSource.id = source.id
      currentKnowledgeSource.name = source.name
      currentKnowledgeSource.type = source.type
      currentKnowledgeSource.activeExtractionJob = source.active_extraction_job
      return currentKnowledgeSource
    }) as HubtypeKnowledgeSource[]

    const data = knowledgeSources
      .filter(source => source.activeExtractionJob)
      .sort((a, b) => a.name.localeCompare(b.name))
    if (!data) return []
    return data
  }

  static async getBotConfig(
    authToken: string,
    botId: string
  ): Promise<HubtypeBotConfig | undefined> {
    const data = await HubtypeApi.get(authToken, `bots/${botId}/config/`, 'v2')
    if (!data) return undefined
    const botConfig = new HubtypeBotConfig()
    botConfig.buildInfo.botonicCliVersion = data.build_info.botonic_cli_version
    botConfig.buildInfo.nodeVersion = data.build_info.node_version
    botConfig.buildInfo.npmVersion = data.build_info.npm_version
    botConfig.packages = data.packages
    return botConfig
  }

  static async generateContentIdWithAi(
    authToken: string,
    text: string
  ): Promise<string | undefined> {
    try {
      const response = await HubtypeApi.post(
        authToken,
        'flow_builder/ai/generate_content_identifier/',
        'v1',
        { text }
      )
      return response.data.content_identifier || undefined
    } catch (error) {
      console.error('generate_content_identifier', error)
      return undefined
    }
  }
}
