import { memo } from 'react'

import { ElementFields } from '../../../../../domain/models/content-fields'
import { ARIA_LABEL } from '../../../../roles'
import { ELEMENT_IMAGE, ELEMENT_SUBTITLE, ELEMENT_TITLE } from '../../constants'
import { EditorFields } from '../../editor-styles'
import { ContentImageField } from '../../editor-widgets/image-loader-widget'
import { ContentTextField } from '../../editor-widgets/text-input-widgets'
import { EditorProps } from '../../node-editor-panel'
import { ButtonEditor } from '../button-editor/button-editor'

interface ElementEditorProps extends EditorProps {
  element: ElementFields
  index: number
  isRemovable: boolean
  isSelected: boolean
}

export const ElementEditor = memo(
  ({
    element,
    index,
    isRemovable,
    isSelected,
    ...props
  }: ElementEditorProps): JSX.Element => {
    if (!isSelected) return <></>
    return (
      <EditorFields aria-label={`${ARIA_LABEL.ELEMENT_EDITOR}-${index}`}>
        <ContentTextField
          {...props}
          id={element.id}
          field={ELEMENT_TITLE}
          changeProps={{ elementIndex: index }}
          value={element.title}
          hasEmojiPicker={true}
          isCounterEnabled={true}
        />
        <ContentTextField
          {...props}
          id={element.id}
          field={ELEMENT_SUBTITLE}
          changeProps={{ elementIndex: index }}
          value={element.subtitle}
          hasEmojiPicker={true}
          isCounterEnabled={true}
          isOptional={true}
        />
        <ContentImageField
          {...props}
          field={ELEMENT_IMAGE}
          changeProps={{ elementIndex: index }}
          image={element.image?.assetUrl}
        />
        {/* //TODO: support multiple buttons */}
        {element.buttons[0] && (
          <ButtonEditor
            {...props}
            button={element.buttons[0]}
            buttonIndex={0}
            isReply={false}
            showButtonInfo={true}
            elementIndex={index}
          />
        )}
      </EditorFields>
    )
  }
)
