import { cx } from 'class-variance-authority'
import { CSSProperties, useRef } from 'react'
import { DismissButton, Overlay, usePopover } from 'react-aria'
import { OverlayTriggerState } from 'react-stately'

import { Arrow } from './arrow'
import styles from './popover.module.css'

export interface PopoverPosition {
  top: string
  left: string
  position: CSSProperties['position']
}

export interface PopoverProps {
  /** The content of the popover. */
  children: React.ReactNode
  /** The state for the popover. */
  state: OverlayTriggerState
  /** The ref for the element which the popover positions itself with respect to. */
  triggerRef: React.RefObject<HTMLElement>
  /** The width of the trigger element. */
  width?: string
  /** The maximum height of the popover. */
  listMaxHeight?: string
  /** The placement of the element with respect to its anchor element. */
  placement?:
    | 'top'
    | 'top left'
    | 'top right'
    | 'bottom'
    | 'bottom left'
    | 'bottom right'
    | 'left'
    | 'left top'
    | 'left bottom'
    | 'right'
    | 'right top'
    | 'right bottom'
  //** The additional offset applied along the main axis between the element and its anchor element. */
  offset?: number
  /** Whether the overlay should update its position automatically. */
  shouldUpdatePosition?: boolean
  /** The class name of the popover. */
  className?: string
  /** Whether the popover should have an arrow. */
  showArrow?: boolean
  /** When user interacts with the argument element outside of the popover ref, return true if onClose should be called.
   * This gives you a chance to filter out interaction with elements that should not dismiss the popover.
   * By default, onClose will always be called on interaction outside the popover ref. */
  shouldCloseOnInteractOutside?: (element: Element) => boolean

  position?: PopoverPosition
}

export const Popover = ({
  children,
  state,
  width,
  listMaxHeight,
  offset,
  className,
  showArrow,
  ...props
}: PopoverProps) => {
  const popoverRef = useRef<HTMLDivElement>(null)
  const { popoverProps, underlayProps, arrowProps, placement } = usePopover(
    { ...props, offset: offset || (showArrow ? 15 : 8), popoverRef },
    state
  )
  const popoverWidth = { '--popover-width': width }
  const popoverMaxHeight = { '--list-max-height': listMaxHeight }

  return (
    <Overlay>
      <div className={styles.underlay} {...underlayProps} />
      <div
        {...popoverProps}
        className={cx(styles.popover, className)}
        ref={popoverRef}
        style={{
          ...popoverProps.style,
          ...popoverWidth,
          ...popoverMaxHeight,
          ...props.position,
        }}
        data-placement={placement}
      >
        <DismissButton onDismiss={state.close} />
        {showArrow && <Arrow {...arrowProps} placement={placement} />}
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  )
}
