import { Button, Icon } from '@hubtype/ui-react-web'
import { Node } from '@hubtype/ui-react-web/flow-builder'
import { memo, useEffect, useState } from 'react'

import { TrackEventName, useAnalytics } from '../../analytics'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { GoToFlowNodeProps } from '../../types'
import { ViewportAnimator } from '../../utils/viewport-animator'

const GoToFlowNode = ({ data, selected }: GoToFlowNodeProps) => {
  const analytics = useAnalytics()
  const state = useFlowBuilderSelector(ctx => ctx.state)
  const selectFlow = useFlowBuilderSelector(ctx => ctx.selectFlow)
  const [flowName, setFlowName] = useState<string | undefined>(undefined)

  useEffect(() => {
    setFlowName(state.flows.find(flow => flow.id === data.targetFlowId)?.name)
  }, [state.flows, data.targetFlowId])

  const onSelectFlow = () => {
    if (!flowName || !data.targetFlowId) return
    analytics.trackEvent(TrackEventName.FlowOpen, {
      flow_name: flowName,
      origin: 'node',
    })
    selectFlow(data.targetFlowId)
    ViewportAnimator.centerStartNode(state)
  }

  const displayInfo = data.getDisplayInfo()

  return (
    <Node type='rounded' isSelected={selected} nodeContent={data}>
      {({ NodeSection }) => (
        <>
          <Icon icon={displayInfo.icon} size='large' />
          <NodeSection title={displayInfo.name} name={flowName} />
          {data.targetFlowId && (
            <Button
              size='small'
              appearance='outline'
              intent='secondary'
              onPress={onSelectFlow}
            >
              Open
            </Button>
          )}
        </>
      )}
    </Node>
  )
}

export default memo(GoToFlowNode)
