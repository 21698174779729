import { Realtime } from 'ably'
import React, { useEffect, useState } from 'react'

import { HubtypeApi } from '../../repository/hubtype/hubtype-api'
import { RealtimeContext } from './realtime-context'

export const PRESENCE_FLOW_BUILDER_CHANNEL_NAME = 'presence-flow-builder-users'
export const ABLY_AUTH_URL = `${HubtypeApi.getHubtypeApiUrl()}/ably/flow-builder-auth`

interface RealtimeProviderProps {
  authToken: string
  children: React.ReactNode
}

export const RealtimeProvider = ({
  authToken,
  children,
}: RealtimeProviderProps) => {
  const [realtimeClient, setRealtimeClient] = useState<Realtime>()

  useEffect(() => {
    if (!authToken) return
    const realtime = new Realtime({
      authUrl: ABLY_AUTH_URL,
      authMethod: 'POST',
      authHeaders: { Authorization: `Bearer ${authToken}` },
      closeOnUnload: false,
    })
    setRealtimeClient(realtime)

    return () => {
      realtime.close()
    }
  }, [authToken])

  return (
    <RealtimeContext.Provider value={realtimeClient}>
      {children}
    </RealtimeContext.Provider>
  )
}
