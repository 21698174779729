import { useToast } from '@react-aria/toast'
import { QueuedToast } from '@react-stately/toast'
import { cva } from 'class-variance-authority'
import { useRef } from 'react'

import Icon, { IconProps } from '../icon/icon'
import IconButton from '../icon-button/icon-button'
import { useToastFeedback } from './context'
import styles from './toast.module.css'

const toastClasses = cva(styles.toast, {
  variants: {
    type: {
      info: styles.info,
      success: styles.success,
      warning: styles.warning,
      error: styles.error,
      'connection-error': styles.error,
    },
  },
  defaultVariants: { type: 'info' },
})

export interface ToastContentProps {
  /** The title of the toast. */
  title: string
  /** The description of the toast. */
  description?: string
  /** The type of toast. */
  type?: 'info' | 'success' | 'warning' | 'error' | 'connection-error'
}

export interface ToastProps {
  toast: QueuedToast<ToastContentProps>
}

/** Toasts display brief, temporary notifications of actions, errors, or other events in an application. */
export function Toast({ ...props }: ToastProps) {
  const { type, title, description } = props.toast.content
  const ref = useRef<HTMLDivElement>(null)
  const state = useToastFeedback()
  const { toastProps, titleProps, closeButtonProps } = useToast(
    props,
    state,
    ref
  )

  const getIconForType = (type?: ToastContentProps['type']) => {
    if (!type) return 'circle-info'
    const typeIconMapping: Record<string, IconProps['icon']> = {
      success: 'circle-check',
      error: 'circle-xmark',
      warning: 'circle-info',
      info: 'circle-info',
      'connection-error': 'cloud-slash',
    }
    return typeIconMapping[type]
  }

  return (
    <div
      {...toastProps}
      ref={ref}
      className={toastClasses({ type })}
      data-animation={props.toast.animation}
      onAnimationEnd={() => {
        if (props.toast.animation === 'exiting') {
          state.remove(props.toast.key)
        }
      }}
    >
      <Icon
        icon={getIconForType(type)}
        className={styles.toastIcon}
        prefix='far'
        size='large'
      />
      <div className={styles.content}>
        {title && <div {...titleProps}>{title}</div>}
        {description && <div>{description}</div>}
      </div>
      <IconButton {...closeButtonProps} icon='xmark' size='small' />
    </div>
  )
}

export default Toast
