import { HtContentType } from '../cms/hubtype/node-types'
import { ContentType } from './content-types'

export interface ContentId {
  id: string
  type: ContentType | HtContentType
}

export interface TextLocale {
  message: string
  locale: string
}
export interface InputLocale {
  values: string[]
  locale: string
}
export interface QueueLocale {
  id: string
  projectName: string
  name: string
  locale: string
  isInvalid: boolean
}
export interface AssetLocale {
  id: string
  file: string
  locale: string
}
export interface UrlLocale {
  id: string
  locale: string
}

export interface PayloadLocale {
  id: string
  locale: string
}

export interface VideoLocale {
  url: string
  is_embedded: boolean
  locale: string
}

export const CONDITIONAL_FUNCTIONS = {
  CHECK_QUEUE_STATUS: 'check-queue-status',
  CHECK_CHANNEL_TYPE: 'get-channel-type',
  CHECK_COUNTRY: 'check-country',
  CHECK_BOT_VARIABLE: 'check-bot-variable',
}
