import { memo } from 'react'

import { VideoFields } from '../../../../../domain/models/content-fields'
import { HTTP_URL_REGEX } from '../../../../constants'
import { HtInfoPanel, TextBodyBold } from '../../../base'
import { VIDEO } from '../../constants'
import { ContentTextField } from '../../editor-widgets/text-input-widgets'
import { EditorProps } from '../../node-editor-panel'
import { UniqueIdentifierField } from '../../unique-identifier/unique-identifier-widget'

interface VideoEditorProps extends EditorProps {
  nodeContent: VideoFields
}

export const VideoEditor = memo(
  ({ nodeContent, ...props }: VideoEditorProps): JSX.Element => {
    function hasError(): boolean {
      if (!nodeContent.videoUrl) return false
      return !HTTP_URL_REGEX.test(nodeContent.videoUrl)
    }

    return (
      <>
        <TextBodyBold>Video</TextBodyBold>
        <UniqueIdentifierField
          {...props}
          {...nodeContent}
          value={nodeContent.code}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={VIDEO}
          value={nodeContent.videoUrl}
          multiline={true}
          defaultRows={1}
          isInvalid={hasError()}
        />
        <HtInfoPanel
          title='About video component'
          text='Video message will only work with a video URL containing a MP4 or 3GP file. To send Youtube, Vimeo and other video streaming platform links, paste the URL in a regular text message.'
        ></HtInfoPanel>
      </>
    )
  }
)
